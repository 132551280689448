import * as React from "react";
import MaterialTable from 'material-table';
import { ISavedNews, IStatusForm, IPageInfo } from '../../../models/models';
import NewsService from '../service';
import FilesDisplayList from '../utils/FilesDisplayList';
import EditMainNews from './EditMainNewsForm';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import Profile from '../utils/Profile';
import Util from '../../../util';
import InLoading from "../../common/InLoading";
import Loading from "../../common/Loading";
import { URLs } from "../../../constants/constants";
import ShowMoreSP from "../../common/ShowMoreSP";
const ShowMoreText = require('react-show-more-text');

interface IState {
    allNews: ISavedNews[];
    isLoading: boolean;
    isLoading2: boolean;
    selectedItem: any;
    changeStatus: boolean;
    statusForm: IStatusForm;
    isShowCompleteInfo: boolean;
    newsCountInfo: any;
    status: string;
    currentFilter: string;
    skipIndex: number;
    currentRecords: number;
    pageInfo: IPageInfo;
}

interface IProps {

}

class MainNews extends React.PureComponent<IProps, IState> {
    private newsService: NewsService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            allNews: [],
            isLoading: false,
            isLoading2: false,
            selectedItem: null,
            changeStatus: false,
            statusForm: { statusMessage: '', status: '' },
            status: '',
            isShowCompleteInfo: false,
            newsCountInfo: null,
            skipIndex: 0,
            currentRecords: 0,
            currentFilter: 'all',
            pageInfo: { currentPage: 0, totalPages: 0 }
        }

        this.newsService = new NewsService();
        this._onItemSelected = this._onItemSelected.bind(this);
        this._closeDialog = this._closeDialog.bind(this);
        this._afterStatusUpdated = this._afterStatusUpdated.bind(this);
        this._reloadData = this._reloadData.bind(this);
        this._fetchNextOrPrevisousRecords = this._fetchNextOrPrevisousRecords.bind(this);
        this._toggleShowCompleteInfo = this._toggleShowCompleteInfo.bind(this);
        this._updateNews = this._updateNews.bind(this);
    }

    componentDidMount() {
        this.getAllData();
    }

    private getAllData(): Promise<any> {
        this.setState({
            isLoading: true,
            selectedItem: null,
            changeStatus: false,
            currentFilter: 'all',
            pageInfo: { currentPage: 0, totalPages: 0 }
        });
        var promise: Promise<any>[] = [];
        promise.push(this.newsService.getDataOrLogout(URLs.getNewsCountByCategory, true).then((res: any) => {
            if (res.status) {
                let newsCountInfo: any = {};
                newsCountInfo['all'] = 0;
                if (res.data && res.data.length > 0) {
                    res.data.forEach((c: any) => {
                        if (c._id) {
                            c._id.forEach((cat: string) => {
                                newsCountInfo[cat] = newsCountInfo[cat] ? newsCountInfo[cat] + c.count : c.count;
                            });
                        }
                        newsCountInfo['all'] += c.count;
                    });
                    this.setState({
                        newsCountInfo: newsCountInfo,
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            }
        }));
        promise.push(this.newsService.postDataOrLogout(URLs.getMainNewsByFilter2, { skip: 0 }, true).then((res: any) => {
            if (res.status) {
                let data: ISavedNews[] = res.data;
                this.setState({
                    allNews: data,
                    skipIndex: this.state.skipIndex + data.length,
                    pageInfo: Util.getPageInfo(this.state.currentFilter, this.state.newsCountInfo ? this.state.newsCountInfo.all : 0, this.state.skipIndex + data.length),
                    currentRecords: data.length
                });
            } else {
                this.setState({
                    isLoading: false
                });
            }
        }));

        return Promise.all(promise).then(() => {
            this.setState({
                isLoading: false
            });
            return;
        });
    }

    componentDidUpdate() {
        window.scrollTo(0, document.documentElement.scrollHeight);
    }

    private _fetchNextOrPrevisousRecords(where: string) {
        this.setState({
            isLoading: true
        });
        if (where === 'previous') {
            let filter: any = {};
            filter.skip = this.state.skipIndex - 500 - this.state.currentRecords;
            filter.field = "Category";
            filter.value = this.state.currentFilter;
            this.newsService.postDataOrLogout(URLs.getMainNewsByFilter2, filter, true).then((res: any) => {
                if (res.status) {
                    let data: ISavedNews[] = res.data;
                    this.setState({
                        allNews: data,
                        isLoading: false,
                        skipIndex: this.state.skipIndex - 500 - this.state.currentRecords + data.length,
                        pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, this.state.currentFilter, this.state.newsCountInfo.all, this.state.skipIndex - 500),
                        currentRecords: data.length
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        } else {
            let filter: any = {};
            filter.skip = this.state.skipIndex;
            filter.field = "Category";
            filter.value = this.state.currentFilter;
            this.newsService.postDataOrLogout(URLs.getMainNewsByFilter2, filter, true).then((res: any) => {
                if (res.status) {
                    let data: ISavedNews[] = res.data;
                    this.setState({
                        allNews: data,
                        isLoading: false,
                        skipIndex: this.state.skipIndex + data.length,
                        currentRecords: data.length,
                        pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, this.state.currentFilter, this.state.newsCountInfo.all, this.state.skipIndex + data.length)
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        }
    }

    private _onItemSelected(event: any, data: any) {
        this.setState({
            changeStatus: false,
            selectedItem: data
        });
    }

    private _toggleShowCompleteInfo() {
        this.setState((prevState: IState) => {
            return { isShowCompleteInfo: !prevState.isShowCompleteInfo }
        });
    }

    public _updateNews(status: string) {
        this.setState((prevState: IState) => ({
            changeStatus: true,
            status: status,
        }));
    }

    public _closeDialog() {
        this.setState({
            changeStatus: false,
            status: ''
        });
    }

    public _afterStatusUpdated(id: string) {
        this._reloadData();
    }

    private _onStatusFilterChange2(option: string) {
        if (option !== this.state.currentFilter) {
            this.setState({
                isLoading2: true,
                selectedItem: null,
                changeStatus: false,
                pageInfo: { currentPage: 0, totalPages: 0 }
            });
            if (option === 'all') {
                this.newsService.postDataOrLogout(URLs.getMainNewsByFilter2, { skip: 0 }, true).then((res: any) => {
                    if (res.status) {
                        let data: ISavedNews[] = res.data;
                        this.setState({
                            allNews: data,
                            isLoading2: false,
                            skipIndex: data.length,
                            pageInfo: Util.getPageInfo(option, this.state.newsCountInfo.all, data.length),
                            currentRecords: data.length,
                            currentFilter: option,
                            isShowCompleteInfo: true
                        });
                    } else {
                        this.setState({
                            isLoading2: false
                        });
                    }
                });
            } else {
                this.newsService.postDataOrLogout(URLs.getMainNewsByFilter2, { skip: 0, field: 'Category', value: option }, true).then((res: any) => {
                    if (res.status) {
                        let data: ISavedNews[] = res.data;
                        this.setState({
                            allNews: data,
                            isLoading2: false,
                            skipIndex: data.length,
                            pageInfo: Util.getPageInfo(option, this.state.newsCountInfo.news, data.length),
                            currentRecords: data.length,
                            currentFilter: option,
                            isShowCompleteInfo: true
                        });
                    } else {
                        this.setState({
                            isLoading2: false
                        });
                    }
                });
            }
        } else {
            this.setState({
                isShowCompleteInfo: true
            });
        }
    }

    private _reloadData() {
        this.getAllData();
    }

    public render(): JSX.Element {
        const { allNews, isLoading, isLoading2 } = this.state;
        return (
            <div style={{ height: '100% !important', minHeight: '100%' }}>
                {isLoading && <InLoading />}
                {isLoading2 && <Loading />}
                {!isLoading && this.state.newsCountInfo &&
                    <div className="news-dashbord">
                        <span className="Title"> News Information</span>
                        <DefaultButton className="sp-btn-m sp-btn-black sp-float-right" onClick={this._reloadData} text="Reload" />
                        <div className="sp-clearFix">
                        </div>
                        <hr className="sp-hr" />
                        <div className="ms-Grid upload" dir="ltr">
                            {Object.keys(this.state.newsCountInfo).map((key: any, index: any) => {
                                return <div className="item-c1" onClick={() => this._onStatusFilterChange2(key)} >
                                    <p className="item-title"> <b>{key} </b></p>
                                    <i className="ms-Icon ms-Icon--StorageOptical sp-icon1 sp-float-left" aria-hidden="true"></i>
                                    <p className="item-count1">
                                        {this.state.newsCountInfo[key]}
                                    </p>
                                </div>
                            })}
                        </div>
                        <hr className="sp-hr sp-mt30" />
                        <DefaultButton className="sp-btn-m view-all-button" onClick={this._toggleShowCompleteInfo} text={this.state.isShowCompleteInfo ? "Hide Complete Details" : "View Complete Details"} />
                        <div className="sp-clearFix" ></div>
                        {this.state.isShowCompleteInfo && <>
                            <div className="material-table">
                                <div className="pagination">
                                    <span className={`nav-btn ${this.state.pageInfo.currentPage === 1 ? 'sp-hide' : ''}`} onClick={() => this._fetchNextOrPrevisousRecords('previous')} title="Previous Page" >&laquo;</span>
                                    <span title="Current Page"  >{`Page ${this.state.pageInfo.currentPage} of ${this.state.pageInfo.totalPages}`} </span>
                                    <span className={`nav-btn ${this.state.pageInfo.currentPage === this.state.pageInfo.totalPages ? 'sp-hide' : ''}`} onClick={() => this._fetchNextOrPrevisousRecords('next')} title="Next Page" >&raquo;</span>
                                </div>
                                <div className="sp-clearFix"></div>
                                <MaterialTable
                                    style={{ marginTop: "5px", padding: "5px", boxShadow: "none" }}
                                    title="New posts"
                                    columns={[
                                        { title: 'Title', field: 'Title', tooltip: 'Title' },
                                        { title: 'IsHeadlines', field: 'IsHeadlines' },
                                        { title: 'Category', field: 'Category' },
                                        { title: 'Date', field: 'DateTime' }
                                    ]}
                                    data={allNews}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Select news',
                                            onClick: this._onItemSelected
                                        }
                                    ]}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (this.state.selectedItem && this.state.selectedItem._id === rowData._id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                ></MaterialTable>
                            </div>
                            <div>
                                {(this.state.selectedItem &&
                                    <div className="selected-item">
                                        <h4>News Information </h4>
                                        <hr />
                                        <div className="ms-Grid" >
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 msg-details">
                                                    <h6 className="msg-headings">Title <span className="sp-float-right sp-pointer">
                                                        {Util.formatAMPM(this.state.selectedItem.DateTime)}
                                                    </span></h6>
                                                    <div className="sp-clearFix"></div>
                                                    <ShowMoreSP text={this.state.selectedItem.Title} />
                                                    <h6 className="msg-headings sp-mt10">Description</h6>
                                                    <ShowMoreSP text={this.state.selectedItem.Description} />
                                                    {this.state.selectedItem.Analysis1 !== null && this.state.selectedItem.Analysis1 !== undefined && this.state.selectedItem.Analysis1 !== '' &&
                                                        <>
                                                            <p className="an-heading">Analysis 1:</p>
                                                            <div className="an-text">
                                                                <ShowMoreSP text={this.state.selectedItem.Analysis1} />
                                                            </div>
                                                        </>}
                                                    {this.state.selectedItem.Analysis2 !== null && this.state.selectedItem.Analysis2 !== undefined && this.state.selectedItem.Analysis2 !== '' &&
                                                        <>
                                                            <p className="an-heading">Analysis 2:</p>
                                                            <div className="an-text">
                                                                <ShowMoreSP text={this.state.selectedItem.Analysis2} />
                                                            </div>
                                                        </>}
                                                    {this.state.selectedItem.Analysis3 !== null && this.state.selectedItem.Analysis3 !== undefined && this.state.selectedItem.Analysis3 !== '' &&
                                                        <>
                                                            <p className="an-heading">Analysis 3:</p>
                                                            <div className="an-text">
                                                                <ShowMoreSP text={this.state.selectedItem.Analysis3} />
                                                            </div>
                                                        </>}
                                                    <FilesDisplayList files={this.state.selectedItem.Files}></FilesDisplayList>
                                                </div>
                                                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                                    <h6>Submitted by </h6>
                                                    {(this.state.selectedItem.User && this.state.selectedItem.User._id) ?
                                                        <Profile userId={this.state.selectedItem.User._id} /> : <p>Unknown</p>
                                                    }
                                                    <hr />
                                                    <h6>Reviewed by </h6>
                                                    {this.state.selectedItem.ReviewerId ?
                                                        <Profile userId={this.state.selectedItem.ReviewerId} /> : <p>Unknown</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="action-buttons">
                                            <DefaultButton className="sp-btn-m sp-orange" onClick={() => this._updateNews('Approved')} text="Edit" />
                                        </div>
                                    </div>
                                )}
                                <p></p>
                            </div>
                            {(this.state.changeStatus && <EditMainNews _closeDialog={this._closeDialog} newsInfo={this.state.selectedItem} status={this.state.status} afterStatusUpdated={this._afterStatusUpdated}></EditMainNews>)}
                        </>}
                    </div>
                }
            </div>
        );
    }
}

export default MainNews;