import * as React from "react";
import './styles.scss';
import { IUserC } from '../../../models/models';
import TimeStamp from '../TimeStamp';
import { baseUrl } from "../../../services/services";

interface IState {
}

interface IProps {
  User: IUserC;
  DateTime: string;
  onUserClick(id: string): void;
}

class UserInfo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render(): JSX.Element {
    return (<>
      {this.props.User &&
        <div className="row">
          <div className="column">
            <div className="sp-float-left img-c" >
              <img className="profile-pic sp-pointer" src={`${baseUrl}${this.props.User.imagePath}`} alt="" onClick={() => this.props.onUserClick(this.props.User._id)} />
            </div>
          </div>
          <div className="column1" >
            <p className="profile-name" onClick={() => this.props.onUserClick(this.props.User._id)}>
              {`${this.props.User.firstName} ${this.props.User.lastName}`}
            </p>
            <TimeStamp dateTime={this.props.DateTime} />
          </div>
        </div>
      }
    </>
    );
  }
}

export default UserInfo;