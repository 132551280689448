import * as React from "react";
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import { DefaultButton, Dialog, DialogType, TextField } from "office-ui-fabric-react";
import axios from 'axios';
import { IDialogPropss } from "../../../models/models";
interface IOTPForm {
    phoneNumber: string;
    message: string;
}

interface IOTPFormErr {
    phoneNumberErr: string;
    messageErr: string;
}

interface IState {
    otpForm: IOTPForm;
    otpFormErr: IOTPFormErr;
}

interface IProps {
}

class OTPService extends React.PureComponent<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            otpForm: { phoneNumber: '', message: '' },
            otpFormErr: { phoneNumberErr: '', messageErr: '' },
        }

        this._phoneNumberChangeHandle = this._phoneNumberChangeHandle.bind(this);
        this._inputChangeHandle = this._inputChangeHandle.bind(this);
        this._submitForm = this._submitForm.bind(this);
        this.isFormValid = this.isFormValid.bind(this);

    }


    public _phoneNumberChangeHandle = (event: React.ChangeEvent<HTMLInputElement>): void => {
        let erorMessage: string;
        const phoneNumber = (event.target.validity.valid || event.target.value === '') ? event.target.value : this.state.otpForm.phoneNumber;
        if (event.target.value === "") {
            erorMessage = "Required";
        } else if (!event.target.validity.valid) {
            erorMessage = "Only number allowed";
        } else {
            erorMessage = "";
        }
        this.setState({
            otpForm: { ...this.state.otpForm, [event.target.name]: phoneNumber },
            otpFormErr: { ...this.state.otpFormErr, [event.target.name + 'Err']: erorMessage }
        });
    }

    private _inputChangeHandle(event: React.ChangeEvent<HTMLInputElement>): void {
        let erorMessage: string;
        if (event.target.required && event.target.value === "") {
            erorMessage = "Required";
        } else {
            erorMessage = "";
        }
        this.setState({
            otpForm: { ...this.state.otpForm, [event.target.name]: event.target.value },
            otpFormErr: { ...this.state.otpFormErr, [event.target.name + 'Err']: erorMessage }
        });
    }



    private isFormValid(): boolean {
        let otpForm: IOTPForm = this.state.otpForm;
        let errormsgs: IOTPFormErr = this.state.otpFormErr;
        let isFormValid: boolean = true;
        if (otpForm.phoneNumber === "") {
            errormsgs.phoneNumberErr = "Required";
            isFormValid = false;
        } else {
            errormsgs.phoneNumberErr = "";
        }
        if (otpForm.message === "") {
            errormsgs.messageErr = "Required";
            isFormValid = false;
        } else {
            errormsgs.messageErr = "";
        }
        this.setState({
            otpFormErr: errormsgs
        });
        return isFormValid;
    }


    private _submitForm() {
        if (this.isFormValid()) {
            let formData: IOTPForm = this.state.otpForm;
            var lurl = `http://login.adwingssolutions.com/api/v1/sendSMS.php?APIKey=W3LPC2HU2912R6WUFMPD&senderid=MALANA&flashsms=0&number=${formData.phoneNumber}&text=${formData.message}`
            window.open(lurl, "_blank")
        }

    }

    render(): JSX.Element {
        return (
            <div>
                <TextField pattern="[0-9]*" label={"Phone Number"} autoSave="false" autoComplete="false" name="phoneNumber" errorMessage={this.state.otpFormErr.phoneNumberErr} value={this.state.otpForm.phoneNumber} onChange={(event: any) => this._phoneNumberChangeHandle(event)} required minLength={10} prefix="+91" maxLength={10} />
                <TextField label="Message"
                    multiline={true}
                    rows={6}
                    placeholder="Enter message"
                    name="message"
                    errorMessage={this.state.otpFormErr.messageErr}
                    required
                    value={this.state.otpForm.message}
                    onChange={(event: any) => this._inputChangeHandle(event)}
                />

                <DefaultButton onClick={this._submitForm}
                    className="sp-btn-login btn-send"
                    text="Send" />
            </div>
        );
    }
}

export default OTPService;
