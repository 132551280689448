import * as React from "react";
import './styles.scss';
import MyProfileService from './service';

interface typ {
  isShow: boolean;
  value: string;
  errorMessage: string;
}

interface IState {
  username: typ;
  userId: string;
}

interface IProps {
  username: string;
  userId: string;
}

class Username extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      username: {
        isShow: false,
        value: this.props.username,
        errorMessage: ""
      },
      userId: this.props.userId
    }
  }

  public render(): JSX.Element {
    return (
      <div className="ms-Grid" dir="ltr">
        <div className="ms-Grid-row card-item">
          <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" >
            <p className="sp-no-pm sp-m3 title">Username/Phone</p>
          </div>
          <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" >
            <div className="ms-Grid" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm9 ms-md9 ms-lg9" >
                  <p className="sp-no-pm sp-m3"> +91 {this.state.username.value}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Username;