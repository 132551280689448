import * as React from "react";
import { News2, NewsEmpty, ICreateNewsForm2, IDialogPropss, TypeOptions2, EditFile, IAddFile, FileType, IExportTemplate, AddFile, FileT } from '../../../models/models';
import '../styles.scss';
import { IStatusForm, ISavedNews, File, NewsInfo, IStatusFormErr, ISavedFile, IFile } from '../../../models/models';
import Service from '../service';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Dialog, DialogType, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { ChoiceGroup } from 'office-ui-fabric-react/lib/ChoiceGroup';
import { Toggle, Dropdown, IDropdownOption } from "office-ui-fabric-react";
import Loading from "../../common/Loading";
import { CategoryOptions, FileTypes2, URLs } from "../../../constants/constants";
import ProgressBar from "react-bootstrap/ProgressBar";
import FileUpload from "../../common/fileUpload";

interface ICreatenewsFormErr {
  TitleErr: string;
  DescriptionErr: string;
  CategoryErr: string;
  FilesErr: string;
}


interface IState {
  newsForm: ICreateNewsForm2;
  newsFormTelugu: ICreateNewsForm2;
  newsFormErr: ICreatenewsFormErr;
  newsFormTeluguErr: ICreatenewsFormErr;
  statusForm: IStatusForm;
  DialogProps: IDialogPropss;
  statusFormErr: IStatusFormErr;
  isShowModal: boolean;
  isLoading: boolean;
  errorMessage: string;
  showAnalysisTab: boolean;
  Files: any;
  Links: IAddFile[];
  Reset: boolean;
  fileIndex: number;
  exportTemplate: IExportTemplate;
  showExportModal: boolean;
  uploadedFilesInfo: any;
}

interface IProps {
  newsInfo: ISavedNews;
  status: string;
  _closeDialog(): void;
  afterStatusUpdated(id: string, status?: string): void;
}

class EditNews extends React.Component<IProps, IState> {
  private service: Service;
  constructor(props: IProps) {
    super(props);
    this.state = {
      newsForm: new News2(this.props.newsInfo),
      newsFormTelugu: new NewsEmpty(this.props.newsInfo),
      Files: (this.props.newsInfo.Files && this.props.newsInfo.Files.length > 0) ?
        this.props.newsInfo.Files.map((fileInfo: ISavedFile) => {
          return new EditFile(fileInfo);
        }) : [],
      statusForm: { statusMessage: '', status: this.props.status },
      newsFormTeluguErr: { TitleErr: '', DescriptionErr: '', CategoryErr: '', FilesErr: '' },
      statusFormErr: { CommentErr: '' },
      newsFormErr: { TitleErr: '', DescriptionErr: '', CategoryErr: '', FilesErr: '' },
      isShowModal: (this.props.status !== "Approved" ? true : false),
      errorMessage: '',
      DialogProps: { show: false, message: '' },
      isLoading: false,
      showAnalysisTab: false,
      Links: [],
      fileIndex: 1,
      exportTemplate: { path: '', scrollableText: '', fileId: '', mimeType: '', isReExport: false, type: '' },
      showExportModal: false,
      uploadedFilesInfo: [],
      Reset: false
    }
    this.service = new Service();
    this._titleChangeHandle = this._titleChangeHandle.bind(this);
    this._afterFilesUploaded = this._afterFilesUploaded.bind(this);
    this._descriptionChangeHandle = this._descriptionChangeHandle.bind(this);
    this._submitForm = this._submitForm.bind(this);
    this._statusMessageChangeHandle = this._statusMessageChangeHandle.bind(this);
    this._closeDialog = this._closeDialog.bind(this);
    this._hideExportModel = this._hideExportModel.bind(this);
    this._onCategoryChange = this._onCategoryChange.bind(this);
    this._changeStatus = this._changeStatus.bind(this);
    this._onReExportToggleChange = this._onReExportToggleChange.bind(this);
    this._closeAlertDialog = this._closeAlertDialog.bind(this);
    this._removefile = this._removefile.bind(this);
    this._textChangeHandle = this._textChangeHandle.bind(this);
    this._removeF = this._removeF.bind(this);
    this._afterFilesUploaded = this._afterFilesUploaded.bind(this);
    this._onProgress = this._onProgress.bind(this);
    this._onFilsUploadError = this._onFilsUploadError.bind(this);
    this.scrollTextHandle = this.scrollTextHandle.bind(this);
    this.startExport = this.startExport.bind(this);
    this._onOriginalOrExportedToggleChangeUpload = this._onOriginalOrExportedToggleChangeUpload.bind(this);
    this._removeF1 = this._removeF1.bind(this);
    this._onToggleChange = this._onToggleChange.bind(this);
    this._onToggleChange1 = this._onToggleChange1.bind(this);
    this._onTeluguToggleChange = this._onTeluguToggleChange.bind(this);
    this._onTeluguCategoryChange = this._onTeluguCategoryChange.bind(this);
    this._teluguTitleChangeHandle = this._teluguTitleChangeHandle.bind(this);
    this._teluguDescriptionChangeHandle = this._teluguDescriptionChangeHandle.bind(this);
    this._dropDownChange2Handle = this._dropDownChange2Handle.bind(this);
    this.translateText = this.translateText.bind(this);
    this._removeFile = this._removeFile.bind(this);
    this._addFile = this._addFile.bind(this);

    this.service.postDataOrLogout(URLs.getTNewsByERefId, { id: this.props.newsInfo._id }, true).then((res: any) => {
      if (res && res.status && res.data && res.data.length > 0) {
        this.setState({
          newsFormTelugu: new News2(res.data[0])
        });
      }
    });
  }

  componentWillReceiveProps(newProps: IProps) {
    this.setState({
      newsForm: new News2(newProps.newsInfo),
      statusForm: { statusMessage: '', status: newProps.status },
      isShowModal: (newProps.status !== "Approved" ? true : false)
    });
  }


  private startExport() {
    this.setState({
      showExportModal: false,
      isLoading: true
    });

    if (this.state.exportTemplate.type == 'user') {
      this.service.postDataOrLogout(URLs.exportFile, this.state.exportTemplate, true).then((data: any) => {
        if (data && data.status) {
          const fileId = this.state.exportTemplate.fileId;
          if (this.state.exportTemplate.mimeType == FileType.mp4) {
            const myVar = setInterval(() => {
              this.service.postDataOrLogout(URLs.checkIsFileExported, { path: this.state.exportTemplate.path }, true).then((data1: any) => {
                if (data1 && data1.status) {
                  let files = this.state.Files.filter(
                    (file: any) => {
                      if (file.id == fileId) {
                        file.filePath = file.filePath.includes('/exp/') ? file.filePath : file.filePath.slice(0, 8) + "/exp" + file.filePath.slice(8);
                        file.isExported = true;
                      }
                      return file;
                    }
                  );

                  this.setState({
                    Files: files,
                    isLoading: false,
                    exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
                  });

                  clearInterval(myVar);
                }
              }).then((err: any) => {

              });
            }, 5000);
          } else {
            let files = this.state.Files.filter(
              (file: any) => {
                if (file.id == fileId) {
                  file.filePath = file.filePath.includes('/exp/') ? file.filePath : file.filePath.slice(0, 8) + "/exp" + file.filePath.slice(8);
                  file.isExported = true;
                }
                return file;
              }
            );
            this.setState({
              Files: files,
              isLoading: false,
              exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
            });
          }
        }
      });
    } else {
      
      this.service.postDataOrLogout(URLs.exportFile, this.state.exportTemplate, true).then((data: any) => {
        if (data && data.status) {
          const fileId = this.state.exportTemplate.fileId;
          if (this.state.exportTemplate.mimeType == FileType.mp4) {
            const myVar = setInterval(() => {
              this.service.postDataOrLogout(URLs.checkIsFileExported, { path: this.state.exportTemplate.path }, true).then((data1: any) => {
                if (data1 && data1.status) {
                  let files = this.state.uploadedFilesInfo.filter(
                    (file: any) => {
                      if (file.response.fileNewName == fileId) {
                        file.response.filePath = file.response.filePath.includes('/exp/') ? file.response.filePath : file.response.filePath.slice(0, 8) + "/exp" + file.response.filePath.slice(8);
                        file.isExported = true;
                      }
                      return file;
                    }
                  );

                  this.setState({
                    uploadedFilesInfo: files,
                    isLoading: false,
                    exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
                  });

                  clearInterval(myVar);
                }
              }).then((err: any) => {

              });
            }, 5000);
          } else {
            let files = this.state.uploadedFilesInfo.filter(
              (file: any) => {
                if (file.response.fileNewName == fileId) {
                  file.response.filePath = file.response.filePath.includes('/exp/') ? file.response.filePath : file.response.filePath.slice(0, 8) + "/exp" + file.response.filePath.slice(8);
                  file.isExported = true;
                }
                return file;
              }
            );
            this.setState({
              uploadedFilesInfo: files,
              isLoading: false,
              exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
            });
          }
        }
      });
    }
  }


  private _afterFilesUploaded(files: any) {
    for (let i = 0; i < files.length; i++) {
      this.setState((prevState, prevProps) => ({
        uploadedFilesInfo: [...prevState.uploadedFilesInfo, files[i]]
      }));
    }
  }

  private _onReExportToggleChange(isChecked?: boolean) {
    if (isChecked !== undefined) {
      this.setState({
        exportTemplate: { ...this.state.exportTemplate, isReExport: isChecked }
      });
    }
  }

  private _onOriginalOrExportedToggleChange(fileId: string, isChecked?: boolean) {
    if (isChecked !== undefined) {
      if (isChecked) {
        let files = this.state.Files.filter(
          (file: any) => {
            if (file.id == fileId) {
              file.filePath = file.filePath.replace("/exp", "");
            }
            return file;
          }
        );

        this.setState({
          Files: files
        });
      } else {
        let files = this.state.Files.filter(
          (file: any) => {
            if (file.id == fileId) {
              file.filePath = file.filePath.includes('/exp/') ? file.filePath : file.filePath.slice(0, 8) + "/exp" + file.filePath.slice(8);
            }
            return file;
          }
        );
        this.setState({
          Files: files
        });
      }
    }
  }


  private _onOriginalOrExportedToggleChangeUpload(fileId: string, isChecked?: boolean) {
    if (isChecked !== undefined) {
      if (isChecked) {
        let files = this.state.uploadedFilesInfo.filter(
          (file: any) => {
            if (file.response.fileNewName == fileId) {
              file.response.filePath = file.response.filePath.replace("/exp", "");
            }
            return file;
          }
        );

        this.setState({
          uploadedFilesInfo: files
        });
      } else {
        let files = this.state.uploadedFilesInfo.filter(
          (file: any) => {
            if (file.response.fileNewName == fileId) {
              file.response.filePath = file.response.filePath.includes('/exp/') ? file.response.filePath : file.response.filePath.slice(0, 8) + "/exp" + file.response.filePath.slice(8);
            }
            return file;
          }
        );
        this.setState({
          uploadedFilesInfo: files
        });
      }
    }
  }


  componentDidMount() {
    window.scrollTo(0, document.documentElement.scrollHeight);
  }

  private _closeAlertDialog() {
    this.setState({
      DialogProps: { show: false, message: '' },
    });
  }


  private _onToggleChange(field: string, isChecked?: boolean) {
    this.setState({
      newsForm: { ...this.state.newsForm, [field]: isChecked }
    });
  }

  private _onToggleChange1(field: string, isChecked?: boolean) {
    this.setState({
      newsForm: { ...this.state.newsForm, [field]: isChecked }
    });
  }

  private _removefile(fileInfo: ISavedFile) {
    let files = this.state.newsForm.Files.filter(
      (file: ISavedFile) => file._id !== fileInfo._id
    );
    this.setState((prevState: IState) => {
      return {
        newsForm: { ...prevState.newsForm, Files: files },
        newsFormTelugu: { ...prevState.newsFormTelugu, Files: files },
      };
    });
  }

  private _titleChangeHandle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let erorMessage: string;
    if (event.target.value === "") {
      erorMessage = "Title is Required";
    } else {
      erorMessage = "";
    }
    this.setState((prevState: IState) => ({
      newsForm: { ...this.state.newsForm, [event.target.name]: event.target.value },
      newsFormErr: { ...prevState.newsFormErr, TitleErr: erorMessage }
    }));
  }

  private _descriptionChangeHandle = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState((prevState: IState) => ({
      newsForm: { ...this.state.newsForm, [event.target.name]: event.target.value },
    }));
  }


  private _dropDownChange2Handle(id: number, mimeType: string, option?: IDropdownOption) {
    let erorMessage: string;
    if (option) {
      if (option.key === "") {
        erorMessage = `${mimeType} is required`;
      } else {
        erorMessage = "";
      }
      let files = this.state.Links.map((file: IAddFile) => {
        if (file.id === id) {
          return file = { ...file, [mimeType]: option.key, [mimeType + 'Err']: erorMessage };
        } else {
          return file
        }
      });
      this.setState({
        Links: files
      });
    }
  }



  private _statusMessageChangeHandle = (event: any): void => {
    let erorMessage: string;
    let db = event.target.value;
    if (event.target.value) {
      if (event.target.value === "") {
        erorMessage = "Please enter Comment.";
      } else {
        erorMessage = "";
      }
    } else {
      erorMessage = "Please enter Comment.";
    }
    this.setState((prevState: IState) => ({
      statusForm: { ...prevState.statusForm, statusMessage: db },
      statusFormErr: { ...prevState.statusFormErr, CommentErr: erorMessage }
    }));
  }

  private _closeDialog() {
    let isApprove: boolean = true;
    if (this.state.statusForm.status !== 'Approved') {
      isApprove = false;
    }
    this.setState({
      isShowModal: false,
      statusForm: { statusMessage: '', status: isApprove ? 'Approved' : '' }
    });

    if (!isApprove) {
      this.props._closeDialog();
    }
  }

  private _textChangeHandle(event: any, id: number) {
    let erorMessage: string;
    let inputControl = event.target;
    if (event.target.value === "") {
      erorMessage = `${inputControl.name} is required`;
    } else {
      erorMessage = "";
    }
    let files = this.state.Links.map((file: IAddFile) => {
      if (file.id === id) {
        return file = { ...file, [inputControl.name]: inputControl.value, [inputControl.name + 'Err']: erorMessage };
      } else {
        return file
      }
    });
    this.setState((prevState: IState) => {
      return { Links: files };
    });
  }



  private _onCategoryChange(id: number, mimeType: string, option?: IDropdownOption) {
    let erorMessage: string;

    if (option) {
      if (option.key.toString() == "") {
        erorMessage = "Category is Required";
      } else {
        erorMessage = "";
      }
      let catergory = this.state.newsForm.Category;
      if (option.selected) {
        catergory.push(option.key.toString());
      } else {
        let files = this.state.newsForm.Category.filter(
          (file: string) => file !== option.key
        );
        catergory = files;
      }
      this.setState({
        newsForm: { ...this.state.newsForm, Category: catergory },
        newsFormTelugu: { ...this.state.newsFormTelugu, Category: catergory },
        newsFormErr: { ...this.state.newsFormErr, CategoryErr: erorMessage },
        newsFormTeluguErr: { ...this.state.newsFormTeluguErr, CategoryErr: erorMessage }
      });
    }
  }



  private _onProgress(filesInfo: any) {
    let tempFiles = this.state.uploadedFilesInfo;
    let added = false;
    if (tempFiles.length > 0) {
      for (let j = 0; j < tempFiles.length; j++) {
        if (tempFiles[j].name === filesInfo.name) {
          tempFiles = tempFiles.map(
            (file: any) => {
              if (file.name === filesInfo.name) {
                return file;
              } else {
                return file
              }
            });
        } else {
          if (!added) {
            if (tempFiles[j].name === filesInfo.name) {
              tempFiles = tempFiles.map(
                (file: any) => {
                  if (file.name === filesInfo.name) {
                    return file;
                  } else {
                    return file
                  }
                });
            } else {
              tempFiles = [...tempFiles, filesInfo];
            }
          }
          added = true;
        }
      }
    } else {
      tempFiles = [...tempFiles, filesInfo];
    }

    const filesU = this.getUnique(tempFiles);

    this.setState((prevState: IState) => {
      return { uploadedFilesInfo: filesU }
    });
  }


  private getUnique(array: any) {
    var uniqueArray = [];
    if (array.length > 0) {
      for (let value of array) {
        if (uniqueArray.indexOf(value) === -1) {
          uniqueArray.push(value);
        }
      }
    }
    return uniqueArray;
  }



  private isFormValid = (): boolean => {
    let newsForm: ICreateNewsForm2 = this.state.newsForm;
    let newsFormErrInfo: ICreatenewsFormErr = this.state.newsFormErr;
    let newsFormTelugu: ICreateNewsForm2 = this.state.newsFormTelugu;
    let newsFormTeluguErr: ICreatenewsFormErr = this.state.newsFormTeluguErr;
    let uploadedFilesInfo = this.state.uploadedFilesInfo;
    let filesInfo = this.state.Links;
    let isFormValid: boolean = true;
    if (newsForm.Title === "") {
      newsFormErrInfo.TitleErr = "Title is Required";
      isFormValid = false;
    } else {
      newsFormErrInfo.TitleErr = "";
    }

    if (newsForm.Category.length === 0) {
      newsFormErrInfo.CategoryErr = "Category is Required";
      isFormValid = false;
    } else {
      newsFormErrInfo.CategoryErr = "";
    }

    if (newsFormTelugu.Title === "") {
      newsFormTeluguErr.TitleErr = "Title is Required";
      isFormValid = false;
    } else {
      newsFormTeluguErr.TitleErr = "";
    }

    if (newsFormTelugu.Category.length === 0) {
      newsFormTeluguErr.CategoryErr = "Category is Required";
      isFormValid = false;
    } else {
      newsFormTeluguErr.CategoryErr = "";
    }

    if (filesInfo.length > 0) {
      for (let i = 0; i < filesInfo.length; i++) {
        if (filesInfo[i].fileNewName === "") {
          isFormValid = false;
          filesInfo[i].fileNewNameErr = "File name is required."
        }
        if (filesInfo[i].filePath === "") {
          isFormValid = false;
          filesInfo[i].filePathErr = "Url is required."
        }
        if (filesInfo[i].mimeType === "") {
          isFormValid = false;
          filesInfo[i].mimeTypeErr = "File type is required."
        }
      }
    }


    if (uploadedFilesInfo.length > 0) {
      for (let i = 0; i < uploadedFilesInfo.length; i++) {
        if (!uploadedFilesInfo[i].response) {
          newsFormErrInfo.FilesErr = 'Please wait until files are uploaded.'
          isFormValid = false;
        } else {
          newsFormErrInfo.FilesErr = '';
        }
      }
    }

    this.setState({
      newsFormErr: newsFormErrInfo,
      newsFormTeluguErr: newsFormTeluguErr,
      Links: filesInfo,
      errorMessage: isFormValid ? '' : 'Please Fill all the fields.'
    });

    setTimeout(() => {
      this.setState({
        errorMessage: ''
      });
    }, 3000);
    return isFormValid;
  }

  private _submitForm() {
    if (this.isFormValid()) {
      this.setState({
        isShowModal: true
      });
    }
  }

  private _hideExportModel() {

    this.setState({
      exportTemplate: { path: '', scrollableText: '', fileId: '', mimeType: '', isReExport: false, type: '' },
      showExportModal: false
    });
  }

  private _removeF(id: number) {
    let files = this.state.Files.filter(
      (file: IAddFile) => file.id !== id
    );
    this.setState((prevState: IState) => {
      return { Files: files };
    });
  }

  private translateText(translateTo: string, text: string, field: string) {
    if (text != '') {
      this.setState({
        isLoading: true
      });
      const translateToo = translateTo;
      this.service.postDataOrLogout(URLs.translateText, { to: translateTo, text: text }, true).then((data: any) => {
        if (data && data.status) {
          if (translateToo == 'te') {
            this.setState((prevState: IState) => {
              return {
                ...prevState,
                newsFormTelugu: { ...prevState.newsFormTelugu, [field]: data.data },
                newsFormTeluguErr: {
                  ...prevState.newsFormTeluguErr, [field + 'Err']: data.data != '' && ''
                },
                isLoading: false
              };
            });

          } else {
            this.setState((prevState: IState) => {
              return {
                ...prevState,
                newsForm: { ...prevState.newsForm, [field]: data.data },
                newsFormErr: {
                  ...prevState.newsFormErr, [field + 'Err']: data.data != '' && ''
                },
                isLoading: false
              };
            });
          }
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
    }
  }

  private _removeFile(fileInf: any) {
    let uploadedFiles: any[] = [];
    this.state.uploadedFilesInfo.forEach((fileInfo: any) => {
      if (fileInfo.name !== fileInf.name) {
        uploadedFiles = [...uploadedFiles, fileInfo];
      }
    });
    this.setState({
      uploadedFilesInfo: uploadedFiles
    });
  }

  private isStatusFormValid() {
    let statusForm: IStatusForm = this.state.statusForm;
    let statusFormErrInfo: IStatusFormErr = this.state.statusFormErr;
    let isStatusFormValid: boolean = true;
    if (statusForm.statusMessage === "") {
      statusFormErrInfo.CommentErr = "Please comment something.";
      isStatusFormValid = false;
    } else {
      statusFormErrInfo.CommentErr = "";
    }
    this.setState({
      statusFormErr: statusFormErrInfo
    });
    return isStatusFormValid;
  }

  private filesUploadedBindingInfo(filesInfo: any[]) {
    let temp;
    temp = filesInfo.map((fileInfo: any) => {
      return <div key={fileInfo.name} className="sp-mt30">
        <p className="ms-fontSize-14 heading-3">Uploaded files</p>
        <table className="sp-table-u">
          <thead>
            <tr>
              <th>File</th>
              <th>Export/Re Export</th>
              <th>Original/Exported</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {fileInfo.progress != 100 ?
                  <p className="filename">{fileInfo.name}</p>
                  :
                  <>{fileInfo.response &&
                    < a className="sp-ml10" href={`${fileInfo.response.filePath}`} rel="noopener noreferrer" target="_blank">click here</a>
                  }
                  </>
                }
              </td>
              <td>
                {fileInfo.progress != 100 ? <ProgressBar now={fileInfo.progress} label={fileInfo.progress} animated={true} /> :
                  <>{
                    fileInfo.isExported ? <PrimaryButton className="sp-btn-success" onClick={() => this.showExportModel('uploaded', fileInfo.response.fileNewName, fileInfo.response.filePath, fileInfo.type)} text="Re Export" /> :
                      <PrimaryButton className="sp-btn-process" onClick={() => this.showExportModel('uploaded', fileInfo.response.fileNewName, fileInfo.response.filePath, fileInfo.type)} text="Export" />
                  }
                  </>
                }
              </td>
              <td>
                {fileInfo.progress != 100 ? <ProgressBar now={fileInfo.progress} label={fileInfo.progress} animated={true} /> :
                  <>{
                    fileInfo.isExported && <Toggle onText="Original" offText="Exported" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this._onOriginalOrExportedToggleChangeUpload(fileInfo.response.fileNewName, checked)} />
                  }
                  </>
                }
              </td>
              <td>
                <span className="btn-remove-file" onClick={() => this._removeFile(fileInfo)}> &times;</span>
              </td>
            </tr>

          </tbody>
        </table>
      </div>

    });
    return temp;
  }

  private _removeF1(id: number) {
    let files = this.state.Links.filter(
      (file: IAddFile) => file.id !== id
    );
    this.setState((prevState: IState) => {
      return { Links: files };
    });
  }

  private _addFile() {
    this.setState((prevState: IState) => {
      return {
        Links: [...prevState.Links, new AddFile(prevState.fileIndex)],
        fileIndex: prevState.fileIndex + 1
      }
    });
  }

  private _changeStatus() {
    if (this.isStatusFormValid()) {
      this.setState({
        isLoading: true
      });
      let statusChangeModel: any;
      if (this.state.statusForm.status === "Approved") {
        let eFormData: ICreateNewsForm2 = this.state.newsForm;
        let tFormData: ICreateNewsForm2 = this.state.newsFormTelugu;
        let uploadedFilesInfo = this.state.uploadedFilesInfo;
        eFormData.Title = eFormData.Title.trim();
        eFormData.Description = eFormData.Description.trim();
        tFormData.Title = tFormData.Title.trim();
        tFormData.Description = tFormData.Description.trim();

        let files: IFile[] = [];
        if (this.state.Files && this.state.Files.length > 0) {
          for (let i = 0; i < this.state.Files.length; i++) {
            files = [...files, new File(this.state.Files[i])]
          }
        }
        if (this.state.Links && this.state.Links.length > 0) {
          for (let i = 0; i < this.state.Links.length; i++) {
            files = [...files, new File(this.state.Links[i])]
          }
        }
        if (uploadedFilesInfo.length > 0) {
          for (let i = 0; i < uploadedFilesInfo.length; i++) {
            if (uploadedFilesInfo[i].response) {
              files = [...files, new FileT(uploadedFilesInfo[i].response)]
            }
          }
        }

        eFormData = { ...eFormData, Files: files };
        tFormData = { ...tFormData, Files: files };
        let formsData = { English: eFormData, Telugu: tFormData };
        statusChangeModel = new NewsInfo(formsData, this.state.statusForm);
      } else {
        statusChangeModel = { newsInfo: { English: { _id: this.state.newsForm._id } }, status: this.state.statusForm };
      }

      this.service.postDataOrLogout(URLs.updateMainNews, statusChangeModel, true).then((res) => {
        if (res.status) {
          this.setState({
            isLoading: false
          });
          this.props.afterStatusUpdated(statusChangeModel.newsInfo.English._id, this.state.statusForm.status);
        } else {
          this.setState({
            DialogProps: { show: true, message: res.message },
            isLoading: false
          });
        }
      });
    }
  }

  private scrollTextHandle = (event: any): void => {
    let db = event.target.value;
    this.setState((prevState: IState) => ({
      exportTemplate: { ...prevState.exportTemplate, scrollableText: db }
    }));
  }


  private showExportModel(type: string, fileId: number, filepath: string, mimeType: string) {
    
    if (mimeType.includes('image/') || mimeType.includes('video/')) {
      this.setState({
        showExportModal: true,
        exportTemplate: { ...this.state.exportTemplate, path: filepath.replace("/exp", ""), fileId: fileId.toString(), mimeType: mimeType, type: type }
      });
    } else {
      this.setState({
        DialogProps: { show: true, message: "Can't export" }
      });
    }
  }

  private _onTeluguCategoryChange(id: number, mimeType: string, option?: IDropdownOption) {
    if (option) {
      let erorMessage: string;
      if (option.key == "") {
        erorMessage = "Category is Required";
      } else {
        erorMessage = "";
      }
      let catergory = this.state.newsFormTelugu.Category;
      if (option.selected) {
        catergory.push(option.key.toString());
      } else {
        let files = this.state.newsFormTelugu.Category.filter(
          (file: string) => file !== option.key
        );
        catergory = files;
      }
      this.setState({
        newsFormTelugu: { ...this.state.newsFormTelugu, Category: catergory },
        newsFormTeluguErr: { ...this.state.newsFormTeluguErr, CategoryErr: erorMessage }
      });
    }
  }

  private _onTeluguToggleChange(field: string, isChecked?: boolean) {
    this.setState({
      newsFormTelugu: { ...this.state.newsFormTelugu, [field]: isChecked },
    });
  }

  private _teluguTitleChangeHandle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    let erorMessage: string;
    if (event.target.value === "") {
      erorMessage = "Title is Required";
    } else {
      erorMessage = "";
    }
    this.setState({
      newsFormTelugu: { ...this.state.newsFormTelugu, [event.target.name]: event.target.value },
      newsFormTeluguErr: { ...this.state.newsFormTeluguErr, [event.target.name + 'Err']: erorMessage }
    });
  }

  private _teluguDescriptionChangeHandle = (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
    this.setState({
      newsFormTelugu: { ...this.state.newsFormTelugu, [event.target.name]: event.target.value },
    });
  }


  private _onFilsUploadError(errMsg: string) {
    this.setState({
      newsFormErr: { ...this.state.newsFormErr, FilesErr: errMsg }
    });
  }


  render(): JSX.Element {
    return (
      <div className="approve-form" >
        {this.state.isLoading && <Loading />}
        {(this.state.statusForm.status === "Approved" && <div className="sp-pb20">
          <hr />
          <div className="ms-Grid" dir="ltr">
            <div className="ms-Grid-row" >
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" >
                <h4>English</h4>
                <TextField label="Title" placeholder="Enter Title" name="Title" errorMessage={this.state.newsFormErr.TitleErr} value={this.state.newsForm.Title} onChange={(event: any) => this._titleChangeHandle(event)} required />
                <p className="sp-no-pm sp-pointer sp-mt10 sp-center" onClick={() => this.translateText('te', this.state.newsForm.Title, 'Title')}><i className="ms-Icon ms-Icon--Forward" aria-hidden="true"></i></p>
                <TextField className="cs" label="Description" rows={10} multiline={true} placeholder="Enter Description" name="Description" errorMessage={this.state.newsFormErr.DescriptionErr} value={this.state.newsForm.Description} onChange={(event: any) => this._descriptionChangeHandle(event)} />
                <p className="sp-no-pm sp-mt10 sp-center" ><i onClick={() => this.translateText('te', this.state.newsForm.Description, 'Description')} className="ms-Icon ms-Icon--Forward sp-pointer" aria-hidden="true"></i></p>
                {this.state.showAnalysisTab && <>
                  <TextField label="Analysis 1" multiline={true} rows={3} placeholder="Enter Analysis" name="Analysis1" value={this.state.newsForm.Analysis1} onChange={(event: any) => this._descriptionChangeHandle(event)} />
                  <TextField label="Analysis 2" multiline={true} rows={3} placeholder="Enter Analysis" name="Analysis2" value={this.state.newsForm.Analysis2} onChange={(event: any) => this._descriptionChangeHandle(event)} />
                  <TextField label="Analysis 3" multiline={true} rows={3} placeholder="Enter Analysis" name="Analysis3" value={this.state.newsForm.Analysis3} onChange={(event: any) => this._descriptionChangeHandle(event)} />
                </>}
                <Toggle label={this.state.showAnalysisTab ? 'Hide Analysis' : 'Show Analysis'} checked={this.state.showAnalysisTab} onText="Yes" offText="No" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this.setState({ showAnalysisTab: !this.state.showAnalysisTab })} />
                <Dropdown
                  label="Category"
                  required
                  className="dropdown-fileType"
                  placeholder="Select file type"
                  options={CategoryOptions}
                  multiSelect={true}
                  defaultSelectedKeys={this.state.newsForm.Category}
                  errorMessage={this.state.newsFormErr.CategoryErr}
                  onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => this._onCategoryChange(1, "Category", option)}
                />
                <style>
                  {`.ms-ChoiceField {
                                    display: inline-block;
                                    margin-left:10px;
                                } 
                                `}
                </style>
                <Toggle label="Mallanna News" checked={this.state.newsForm.IsTopTen} onText="Yes" offText="No" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this._onToggleChange("IsTopTen", checked)} />
                <Toggle label="Is Headlines" checked={this.state.newsForm.IsHeadlines} onText="Yes" offText="No" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this._onToggleChange("IsHeadlines", checked)} />
                <Toggle label="Is Show" checked={this.state.newsForm.Show} onText="Yes" offText="No" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this._onToggleChange1("Show", checked)} />
                <ChoiceGroup selectedKey={this.state.newsForm.Type.toString()} options={TypeOptions2} label="Type" required={true} />

                <div className="" >
                  <p className="ms-fontSize-14 heading-3">Attachments</p>
                  <div>
                    {
                      this.state.Files && this.state.Files.length > 0 &&
                      < table className="sp-table-u" >
                        <thead>
                          <tr>
                            <th>File URL</th>
                            <th>File Type</th>
                            <th>Export/Re Export</th>
                            <th>Original/Exported</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.Files.map((file: IAddFile) => {
                              return <tr key={file.id}>
                                <td>
                                  {(file.mimeType === FileType.facebook || file.mimeType === FileType.othersImage || file.mimeType === FileType.youtube) ?
                                    < TextField
                                      className="txt-filpath"
                                      placeholder="Enter file Url"
                                      name="filePath"
                                      errorMessage={file.filePathErr}
                                      value={file.filePath}
                                      onChange={(event: any) => this._textChangeHandle(event, file.id)}
                                    />
                                    : <a className="sp-ml10" href={`${file.filePath}`} rel="noopener noreferrer" target="_blank">Click here</a>}
                                </td>
                                <td>
                                  {file.mimeType}
                                </td>
                                <td>
                                  {file.isExported ? <PrimaryButton className="sp-btn-success" onClick={() => this.showExportModel('user', file.id, file.filePath, file.mimeType)} text="Re Export" /> :
                                    <PrimaryButton className="sp-btn-process" onClick={() => this.showExportModel('user', file.id, file.filePath, file.mimeType)} text="Export" />
                                  }
                                </td>
                                <td>
                                  {file.isExported &&
                                    <Toggle onText="Original" offText="Exported" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this._onOriginalOrExportedToggleChange(file.id.toString(), checked)} />
                                  }
                                </td>
                                <td>
                                  <p className="btn-remove-file" title="remove" onClick={() => this._removeF(file.id)}>&times;</p>
                                </td>

                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    }
                    {
                      this.state.Links.length > 0 &&
                      < table className="sp-table-u" >
                        <thead>
                          <tr>
                            <th>File Name</th>
                            <th>File URL</th>
                            <th>File Type</th>
                            <th>Remove</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.state.Links.map((file: IAddFile) => {
                              return <tr key={file.id}>
                                <td>
                                  <TextField
                                    placeholder="Enter file name"
                                    name="fileNewName"
                                    errorMessage={file.fileNewNameErr}
                                    value={file.fileNewName}
                                    onChange={(event: any) => this._textChangeHandle(event, file.id)}
                                  />
                                </td>
                                <td>  <TextField
                                  placeholder="Enter file Url"
                                  name="filePath"
                                  errorMessage={file.filePathErr}
                                  value={file.filePath}
                                  onChange={(event: any) => this._textChangeHandle(event, file.id)}
                                />
                                </td>
                                <td>
                                  <Dropdown
                                    className="dropdown-fileType"
                                    placeholder="Select file type"
                                    options={FileTypes2}
                                    errorMessage={file.mimeTypeErr}
                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => this._dropDownChange2Handle(file.id, "mimeType", option)}
                                  />
                                </td>
                                <td>
                                  <p className="btn-remove-file" onClick={() => this._removeF1(file.id)}>&times;</p>
                                </td>
                              </tr>
                            })
                          }
                        </tbody>
                      </table>
                    }
                  </div>

                  <div className="file-upload-wrapper">
                    {this.filesUploadedBindingInfo(this.state.uploadedFilesInfo)}
                    <p className="sp-danger">{this.state.newsFormErr.FilesErr}</p>
                  </div>
                </div>

              </div>
              <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" >
                <div >
                  <h4>Telugu</h4>
                  <TextField label="Title" placeholder="Enter Title" name="Title" errorMessage={this.state.newsFormTeluguErr.TitleErr} value={this.state.newsFormTelugu.Title} onChange={(event: any) => this._teluguTitleChangeHandle(event)} required />
                  <p className="sp-no-pm sp-center sp-mt10" ><i className="ms-Icon ms-Icon--Back sp-pointer" onClick={() => this.translateText('en', this.state.newsFormTelugu.Title, 'Title')} aria-hidden="true"></i></p>
                  <TextField label="Description" multiline={true} rows={10} placeholder="Enter Description" name="Description" errorMessage={this.state.newsFormTeluguErr.DescriptionErr} value={this.state.newsFormTelugu.Description} onChange={(event: any) => this._teluguDescriptionChangeHandle(event)} />
                  <p className="sp-no-pm sp-center sp-mt10" ><i className="ms-Icon ms-Icon--Back sp-pointer" onClick={() => this.translateText('en', this.state.newsFormTelugu.Description, 'Description')} aria-hidden="true"></i></p>
                  {this.state.showAnalysisTab && <>
                    <TextField label="Analysis 1" multiline={true} rows={3} placeholder="Enter Analysis" name="Analysis1" value={this.state.newsFormTelugu.Analysis1} onChange={(event: any) => this._teluguDescriptionChangeHandle(event)} />
                    <TextField label="Analysis 2" multiline={true} rows={3} placeholder="Enter Analysis" name="Analysis2" value={this.state.newsFormTelugu.Analysis2} onChange={(event: any) => this._teluguDescriptionChangeHandle(event)} />
                    <TextField label="Analysis 3" multiline={true} rows={3} placeholder="Enter Analysis" name="Analysis3" value={this.state.newsFormTelugu.Analysis3} onChange={(event: any) => this._teluguDescriptionChangeHandle(event)} />
                  </>}
                  <Toggle label="Is Show" checked={this.state.newsFormTelugu.Show} onText="Yes" offText="No" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this._onTeluguToggleChange("Show", checked)} />
                  <style>
                    {`.ms-ChoiceField {
                                    display: inline-block;
                                    margin-left:10px;
                                } 
                                `}
                  </style>
                </div>
              </div>
            </div>
          </div>
          <div className="sp-clearFix"></div>
          <p className="sp-danger sp-mt10">{this.state.errorMessage}</p>
          <div className="sp-clearFix"></div>
          <p className="sp-danger sp-mt10">{this.state.errorMessage}</p>
          <PrimaryButton className="" onClick={this._submitForm} text="Post News" />
          <span className="add-icon sp-ml10 sp-mt10" title="Add row" onClick={this._addFile} ><i className="ms-Icon ms-Icon--CirclePlus" aria-hidden="true"></i></span>
          <FileUpload multiple={true}
            id="createNews_sp-ml10"
            onError={this._onFilsUploadError}
            onProgress={this._onProgress} Reset={this.state.Reset} afterFilesUploaded={this._afterFilesUploaded}></FileUpload>


        </div>
        )}
        <Dialog
          hidden={!this.state.isShowModal}
          onDismiss={this._closeDialog}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: this.state.statusForm.status,
          }}
          modalProps={
            { isBlocking: true }
          }
        >
          <TextField label="Comment" multiline={true} placeholder="Enter Description" name="Description" errorMessage={this.state.statusFormErr.CommentErr} value={this.state.statusForm.statusMessage} onChange={this._statusMessageChangeHandle} required />
          <DialogFooter>
            <PrimaryButton onClick={this._changeStatus} text="Send" />
            <DefaultButton onClick={this._closeDialog} text="Cancel" />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!this.state.DialogProps.show}
          onDismiss={this._closeAlertDialog}
          dialogContentProps={{
            type: DialogType.normal,
          }}
          modalProps={{
            styles: { main: { maxWidth: 450, textAlign: "center" } },
            isBlocking: true
          }}
        >
          <p>{this.state.DialogProps.message}</p>
          <DefaultButton className="sp-btn-login" onClick={this._closeAlertDialog} text="Ok" />
        </Dialog>
        <Dialog
          hidden={!this.state.showExportModal}
          onDismiss={this._hideExportModel}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: 'Export Template',
          }}
          modalProps={{
            isBlocking: true,
            containerClassName: "export-template"

          }}
        >
          <div className="">

            <TextField label="Scrollable Text" multiline={true} rows={5} placeholder="Enter Text only for videos" name="scrollableText" value={this.state.exportTemplate.scrollableText} onChange={this.scrollTextHandle} />
            <Toggle label="Re Export" checked={this.state.exportTemplate.isReExport} onText="Yes" offText="No" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this._onReExportToggleChange(checked)} />

          </div>
          <DialogFooter>
            <PrimaryButton onClick={this.startExport} text="Export" />
            <DefaultButton onClick={this._hideExportModel} text="Cancel" />
          </DialogFooter>
        </Dialog>

      </div>
    );
  }
}

export default EditNews;