import * as React from "react";
import './styles.scss';
import { connect } from "react-redux";
import { AppState } from "../../../Redux/app.store";
import { IUserState } from "../../../Redux/models";
import Navbar from "../Navbar";
import { socket } from "../../../app";
import NewsService from "../Service";
import { URLs } from "../../../constants/constants";
import LiveStreamer from "./LiveStreamer";
import Loading from "../../common/Loading";
import { LiveStatus } from "../../../models/models";
import Youtube from "../../common/Templates/Youtube";
import Util from "../../../util";

interface IState {
    liveInfo: any;
    isLive: boolean;
    liveStatus: Number;
    isLoading: boolean;
}

interface IProps {
    User: IUserState;
    liveInfo: any;
    isLive: boolean;
}

class YoutubeLive extends React.Component<IProps, IState> {
    private service: NewsService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            liveInfo: null,
            isLive: false,
            isLoading: false,
            liveStatus: 0
        }
        this.service = new NewsService();
    }

    getData() {
        try {
            this.setState({
                isLoading: true
            });
            this.service.getDataOrError(URLs.getCurrentLiveInfo, false, false).then((res: any) => {
                if (res.status) {
                    let data = res.data;
                    if (data) {
                        this.setState({
                            liveInfo: data,
                            isLive: data.liveStatus == 1 ? true : false,
                            isLoading: false
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    componentDidMount() {
        try {
            this.getData();
            var thisObj = this;
            socket.on("youtubeLiveStateChanged", (data: any) => {
                if (data && data.data) {
                    if (data.data.liveStatus == 2) {
                        this.setState({
                            isLive: false
                        });
                    } else {
                        this.setState({
                            isLive: true
                        });
                    }
                }
            });
        }
        catch (err) {
            console.log(err);
        }
    }

    componentWillUnmount() {
        // this.setState({
        //     liveInfo: null,
        //     isLive: false,
        //     isLoading: false
        // });
    }


    public render(): JSX.Element {
        return (
            <>{this.state.isLoading && <Loading />}
                <Navbar />
                <div className="sp-container main-container">
                    {this.state.liveInfo && <>
                        {this.state.liveInfo && (this.state.liveInfo.liveStatus == 1) ? <>
                            <iframe width="100%" className="youtube-live"
                                src={`https://www.youtube.com/embed/${Util.extractYoutubeVideoID(this.state.liveInfo.youtubeURL)}?autoplay=1`}>
                            </iframe>
                            <h5 className="sp-m5">{this.state.liveInfo.title}</h5>
                        </>
                            : <div>
                                <h4 className="sp-center">No live is running now.</h4>
                            </div>}
                    </>}
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default connect(
    mapStateToProps,
)(YoutubeLive);