import * as React from "react";
import './styles.scss';
import { IFileC } from '../../../models/models';
import { baseUrl } from "../../../services/services";

interface IProps {
  File: IFileC;
}

function Image(props: IProps) {
  return (
    <img className="sp-img" src={`${baseUrl}${props.File.filePath}`} alt="" />
  );
}

export function OthersImage(props: IProps) {
  return (
    <img className="sp-img" src={`${baseUrl}${props.File.filePath}`} alt="" />
  );
}

export default Image;