import * as React from "react";
import { NavLink } from "react-router-dom";
import './../styles.scss';
import Navbar from "../../Navbar";
import { PivotItem, Pivot } from "office-ui-fabric-react";
interface IState {

}

interface IProps {

}

class English extends React.Component<IProps, IState> {

    public render(): JSX.Element {
        return (
            <>
<p>Loading....</p>
            </>
        );
    }

}

export default English;