import * as React from "react";
import './styles.scss';

const ShowMoreText = require('react-show-more-text');

interface IProps {
    text: string;
    more?: string;
    less?: string;
    lines?: number;
}

function ShowMoreSP(props: IProps) {
    return (
        <ShowMoreText
            lines={props.lines ? props.lines : 3}
            more={props.more ? props.more : 'Show more'}
            less={props.less ? props.less : 'Hide'}
            anchorClass='show-more-link'
            expanded={false}
            keepNewLines={true}
        >
            {props.text}
        </ShowMoreText >
    );
}

export default ShowMoreSP;