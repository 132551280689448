import * as React from 'react';
import { TextField, Dropdown, IDropdownOption, NormalPeoplePicker, Checkbox, IPersonaProps, ChoiceGroup, IChoiceGroupOption, Toggle, DatePicker } from 'office-ui-fabric-react';
import { IFormValidityStatus, FieldType, DayPickerStrings, firstDayOfWeek } from './Models';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-datetime/css/react-datetime.css'
import ReactDateTime from 'react-datetime';
export interface IFormFieldProps {
    field: any;
    data: any;
    formValidations: IFormValidityStatus;
    onFieldChange: (field: string, value: any) => void;
    onEmailChange: (field: string, value: any) => void;
    onNumberChange: (field: string, value: any) => void;
    onChoiceChange: (field: string, option?: IChoiceGroupOption | undefined) => void;
    onSelectChange: (field: string, option?: IDropdownOption) => void;
    onMultiSelectChange: (field: string, option?: IDropdownOption) => void;
    onCheckBoxChange: (field: string, checked?: boolean | undefined) => void;
    onToggleChange: (field: string, checked?: boolean | undefined) => void;
    onDateChange: (field: string, date: any) => void;
}

export default class FormField extends React.Component<IFormFieldProps, {}>{
    constructor(props: IFormFieldProps) {
        super(props);
    }


    public render() {
        switch (this.props.field.Type) {
            case (FieldType.Text):
                return <TextField
                    label={this.props.field.Label}
                    required={this.props.field.Required}
                    value={this.props.data[this.props.field.FieldName] || ""}
                    onChange={(event: any) => { this.props.onFieldChange(this.props.field.FieldName, event.target.value); }}
                    errorMessage={this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}
                    disabled={this.props.field.disabled}
                />;
            case (FieldType.Multi):
                return <TextField
                    label={this.props.field.Label}
                    multiline={true}
                    required={this.props.field.Required}
                    rows={this.props.field.Rows}
                    value={this.props.data[this.props.field.FieldName] || ""}
                    onChange={(event: any) => { this.props.onFieldChange(this.props.field.FieldName, event.target.value); }}
                    errorMessage={this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}
                    disabled={this.props.field.disabled}
                />;
            case (FieldType.Email):
                return <TextField
                    label={this.props.field.Label}
                    required={this.props.field.Required}
                    value={this.props.data[this.props.field.FieldName] || ""}
                    onChange={(event: any) => { this.props.onEmailChange(this.props.field.FieldName, event.target.value); }}
                    errorMessage={this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}
                    disabled={this.props.field.disabled}
                />;
            case (FieldType.Number):
                return <TextField
                    label={this.props.field.Label}
                    minLength={this.props.field.MinLength}
                    maxLength={this.props.field.MaxLength}
                    pattern="[0-9]*"
                    errorMessage={this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}
                    value={this.props.data[this.props.field.FieldName] || ""}
                    onChange={(event: any) => { this.props.onNumberChange(this.props.field.FieldName, event) }}
                    required={this.props.field.Required}
                    disabled={this.props.field.disabled}
                />;
            case (FieldType.Choice):
                return <>
                    <ChoiceGroup
                        label={this.props.field.Label}
                        defaultSelectedKey={this.props.data[this.props.field.FieldName] || ""}
                        options={this.props.field.Options}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, option?: IChoiceGroupOption | undefined) => this.props.onChoiceChange(this.props.field.FieldName, option)}
                        required={this.props.field.Required}
                    />
                    <span className="sp-danger">{this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}</span>
                    <style>
                        {`.ms-ChoiceField {
                                    display: inline-block;
                                    margin-left:10px;
                                } 
                                `}
                    </style>
                    <div className="sp-clearFix"></div>
                </>;
            case (FieldType.Select):
                return <Dropdown
                    className="sp-dropdown"
                    label={this.props.field.Label}
                    options={this.props.field.Options}
                    placeholder="--Select--"
                    errorMessage={this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}
                    selectedKey={this.props.data[this.props.field.FieldName] || ""}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => this.props.onSelectChange(this.props.field.FieldName, option)} required
                />;
            case (FieldType.MultiSelect):
                return <Dropdown
                    label={this.props.field.Label}
                    className="sp-dropdown"
                    required={this.props.field.Required}
                    placeholder="--Select--"
                    options={this.props.field.Options}
                    multiSelect={true}
                    defaultSelectedKeys={this.props.data[this.props.field.FieldName] || ""}
                    errorMessage={this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}
                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => this.props.onMultiSelectChange(this.props.field.FieldName, option)}
                />;
            case (FieldType.CheckBox):
                return <>
                    <p className="chkLable">{this.props.field.Label}</p>
                    <Checkbox
                        label={this.props.field.Label1}
                        checked={this.props.data[this.props.field.FieldName] || false}
                        onChange={(ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => { this.props.onCheckBoxChange(this.props.field.FieldName, checked) }}
                        checkmarkIconProps={{
                            className: 'privacy-checkbox-mark'
                        }}
                    />
                    <span className="sp-danger">{this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}</span>
                </>;
            case (FieldType.Toggle):
                return <>
                    <Toggle
                        label={this.props.field.Label}
                        checked={this.props.data[this.props.field.FieldName] || false}
                        onText={this.props.field.OnText}
                        offText={this.props.field.OffText}
                        onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this.props.onToggleChange(this.props.field.FieldName, checked)} />
                </>;
            case (FieldType.DatePicker):
                return <>
                    <DatePicker
                        label={this.props.field.Label}
                        firstDayOfWeek={firstDayOfWeek}
                        strings={DayPickerStrings}
                        placeholder="--Select--"
                        value={this.props.data[this.props.field.FieldName]}
                        onSelectDate={(date: any) => { this.props.onDateChange(this.props.field.FieldName, date) }}
                    />
                    <span className="sp-danger">{this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}</span>
                    <div className="sp-clearFix"></div>
                </>;
            case (FieldType.ReactQuill):
                return <div className="sp-mt10 sp-mb10">
                    <span className="sp-label">{this.props.field.Label} </span> <span className="sp-danger2"> {this.props.field.Required ? '*' : ''}</span>
                    <ReactQuill
                        className="sp-react-quill"
                        modules={{ toolbar: false }}
                        value={this.props.data[this.props.field.FieldName]}
                        onChange={(data: any) => this.props.onFieldChange(this.props.field.FieldName, data)} />
                    <span className="sp-danger">{this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}</span>
                    <div className="sp-clearFix"></div>
                </div>;
            case (FieldType.DateTime):
                return <div className="sp-mt10 sp-mb10">
                    <span className="sp-label">{this.props.field.Label} </span> <span className="sp-danger2"> {this.props.field.Required ? '*' : ''}</span>
                    <ReactDateTime
                        //value={this.props.data[this.props.field.FieldName]}
                        onChange={(data: any) => this.props.onFieldChange(this.props.field.FieldName, data)} />
                    <span className="sp-danger">{this.props.formValidations.fields[this.props.field.FieldName] ? this.props.formValidations.fields[this.props.field.FieldName].errorMsg : ""}</span>
                    <div className="sp-clearFix"></div>
                </div>;
            default:
                return <div></div>;
        }
    }

}