import * as React from "react";
import FormSP from "../../common/FormSP";
import AuthService from "../../../services/authService";
import { BasicUserInfo, IDialogPropss } from "../../../models/models";
import NewsService from "../Service";
import { URLs } from "../../../constants/constants";
import Loading from "../../common/Loading";
import { Dialog, DialogType, DefaultButton } from "office-ui-fabric-react";
import './styles.scss';
var formFields: any = require('./reporterCreateNews.json');

interface IState {
    isLoading: boolean;
    hideForm: boolean;
    reset: boolean;
    DialogProps: IDialogPropss;
}

interface IProps {
}

class ReporterCreateNews extends React.Component<IProps, IState> {
    private authservice: AuthService;
    private service: NewsService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
            hideForm: false,
            reset: false,
            DialogProps: { show: false, message: '' }
        }
        this.onSubmit = this.onSubmit.bind(this);
        this.closeAlertDialog = this.closeAlertDialog.bind(this);
        this.authservice = new AuthService();
        this.service = new NewsService();
    }

    private closeAlertDialog() {
        this.setState({
            DialogProps: { show: false, message: '' },
            hideForm: false
        });
    }

    private onSubmit(data: any) {
        let formData: any = {};
        formData.Title = data.Title.trim();
        formData.Description = data.Description.trim();
        formData.Category = data.Category;
        formData.Files = data.Files;
        data['Urls'].forEach((urlInfo: any) => {
            formData['Files'] = [...formData['Files'], urlInfo]
        });
        let userInfo = this.authservice.isLoggedIn();
        formData['User'] = new BasicUserInfo(userInfo);
        this.setState({ isLoading: true });
        this.service.postDataOrLogout(URLs.createNewsBySelfAdmin, formData, false, true).then((res: any) => {
            if (res.status === true) {
                this.setState({
                    isLoading: false,
                    DialogProps: { show: true, message: res.message },
                    hideForm: true
                });
            } else {
                this.setState({
                    isLoading: false,
                    DialogProps: { show: true, message: res.message },
                });
            }
        });
    }

    render(): JSX.Element {
        return (<>
            {this.state.isLoading && <Loading />}
            <div className="sp-p10">
                {!this.state.hideForm &&
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md6 ms-lg6">
                                {!this.state.hideForm &&
                                    <FormSP
                                        reset={this.state.reset}
                                        formFields={formFields}
                                        onSubmit={this.onSubmit}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Dialog
                hidden={!this.state.DialogProps.show}
                onDismiss={this.closeAlertDialog}
                dialogContentProps={{
                    type: DialogType.normal,

                }}
                modalProps={{
                    styles: { main: { maxWidth: 450, textAlign: "center" } },
                    isBlocking: true
                }}
            >
                <p>{this.state.DialogProps.message}</p>
                <DefaultButton className="sp-btn-login" onClick={this.closeAlertDialog} text="Ok" />
            </Dialog>
        </>
        );
    }
}

export default ReporterCreateNews;