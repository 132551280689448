import * as React from "react";
import { ILive, IPageInfo } from "../../../models/models";
import NewsService from "../service";
import { URLs } from "../../../constants/constants";
import MaterialTable from "material-table";
import Loading from "../../common/Loading";


interface IState {
    epapers: any[]
    pageInfo: IPageInfo;
    selectedItem: any | null;
    isLoading: boolean;
}

interface IProps {
}

class AllEPaper extends React.PureComponent<IProps, IState> {
    private service: NewsService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            epapers: [],
            pageInfo: { currentPage: 0, totalPages: 0 },
            selectedItem: null,
            isLoading: false
        }

        this.service = new NewsService();
        this._onItemSelected = this._onItemSelected.bind(this);
        this.startCompStreams = this.startCompStreams.bind(this);
    }

    //   https://www.qgroupmedia.com/adm/api/deleteEPaperByDate/6205abac5bf17507135ffc16

    private _onItemSelected(event: any, data: any) {
        debugger;
        //   let updateObj = { _id: data._id, updateObj: { liveStatus: 2 } }
        this.setState({ isLoading: true });
        this.service.deleteDataOrError(URLs.deleteEPaperByDate, true).then((res: any) => {
            if (res.status === true) {
                let temp: ILive[] = [];
                let epapers = this.state.epapers.filter((p: any) => p._id != data._id);
                this.setState({
                    epapers,
                    // liveInfo: temp,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        });

    }

    private getData() {
        this.service.getDataOrLogout(URLs.getAllEPapers, true).then((res: any) => {
            if (res.status) {
                let data = res.data;
                if (data.length > 0) {
                    this.setState({
                        epapers: res.data
                    });
                }
            }
        });
    }



    private startCompStreams() {
        this.setState({ isLoading: true });
        this.service.getDataOrError(URLs.getAllLiveInfoForMonitor, true).then((res: any) => {
            if (res.status === true) {
                this.setState({
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        });
    }

    componentDidMount() {
        this.getData();

    }


    render(): JSX.Element {
        return (
            <>
                {this.state.isLoading && <Loading />}
                <div className="material-table">
                    <div className="sp-clearFix"></div>
                    <MaterialTable
                        style={{ marginTop: "5px", padding: "5px", boxShadow: "none" }}
                        title="New posts"
                        columns={[
                            { title: 'date', field: 'date', tooltip: 'Date' }
                        ]}
                        data={this.state.epapers}
                        actions={[
                            {
                                icon: 'highlight_off',
                                tooltip: 'Delete Paper',
                                onClick: this._onItemSelected
                            }
                        ]}
                        options={{
                            rowStyle: rowData => ({
                                backgroundColor: (this.state.selectedItem && this.state.selectedItem._id === rowData._id) ? '#EEE' : '#FFF'
                            })
                        }}
                    ></MaterialTable>
                </div>

            </>
        );
    }
}

export default AllEPaper;