import { debug } from "console";
import { IPageInfo } from "../models/models";

export default class Util {

  public static isValidEmail(email: string): boolean {
    let filter: RegExp = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (filter.test(email)) {
      return true;
    }
    else {
      return false;
    }
  }

  private static isToday(someDate: any) {
    const today = new Date()
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
  }

  public static formatAMPM(date: any) {
    let dateT = new Date(date);
    let hours = dateT.getHours();
    let minutes = dateT.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    let dateTo = '';
    if (this.isToday(dateT)) {
      dateTo = 'Today,';
    } else {
      dateTo = dateT.toDateString();
    }
    let minutess = minutes < 10 ? '0' + minutes : minutes;
    const strTime = dateTo + ' ' + hours + ':' + minutess + ' ' + ampm;
    return strTime;
  }

  public static extractYoutubeVideoID(url: string) {
    let n = url.indexOf("embed");
    let index = 0;
    if (n !== -1) {
      index = n + 6;
    } else if (url.indexOf("watch") !== -1) {
      let temp = url.indexOf("watch");
      index = temp + 8;
    } else {
      let temp = url.indexOf('youtu.be');
      index = temp + 9;
    }
    return url.substring(index);
  }

  public static isInViewport(el: any) {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)

    );
  }

  public static nFormatter(num: number, digits: number) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "k" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  }
  public static getPageInfo(currentFilter: string, newsCount: number, skipIndex: number): IPageInfo {
    let totalPages: number = 0;
    let currentPage: number = 0;
    if (newsCount >= 500) {
      let f: number = newsCount / skipIndex
      totalPages = Math.round(f);
      currentPage = Math.round(skipIndex / 500);
    } else {
      totalPages = 1;
      currentPage = 1;
    }
    return { totalPages: totalPages, currentPage: currentPage };
  }

  public static getPageInfo2(totalPages: number, currentFilter: string, newsCount: number, skipIndex: number): IPageInfo {
    let currentPage: number = 0;
    if (newsCount >= 500) {
      currentPage = Math.round(skipIndex / 500);
    }
    else {
      currentPage = 1;
    }
    return { totalPages: totalPages, currentPage: currentPage };
  }


  public static getRandomNumber(min: number, max: number) {
    return Math.round(Math.random() * (max - min) + min);
  }

}



