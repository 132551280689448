import * as React from "react";
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import SelfAdminRequests from "./ReporterRequests";
import AllRegistration from "./AllRegistrations";
import AllUsers from "./AllUsers";

interface IState {
}

interface IProps {
}

class Registration extends React.PureComponent<IProps, IState> {

  render(): JSX.Element {
    return (
      <Pivot aria-label="Basic Pivot Example" >
        <PivotItem
          headerText="Registrations"
          headerButtonProps={{
            'data-order': 1,
            'data-title': 'My Files Title',
          }}
        >
          <AllRegistration />
        </PivotItem>
        <PivotItem
          headerText="Reporter Requests"
          headerButtonProps={{
            'data-order': 2,
            'data-title': 'Reporter',
          }}
        >

          <SelfAdminRequests />
        </PivotItem>
        <PivotItem
          headerText="All Users"
          headerButtonProps={{
            'data-order': 3,
            'data-title': 'All Users',
          }}
        >
          <AllUsers />
        </PivotItem>
      </Pivot>
    );
  }
}

export default Registration;