import * as React from "react";
import './styles.scss';
import { IFileC } from '../../../models/models';
import Util from "../../../util";

interface IProps {
  File: IFileC;
  isThumbNail?: boolean;
}

interface IState {
  File: IFileC;
}

class LocalVideo extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      File: this.props.File
    }

    this._onScrollEvent = this._onScrollEvent.bind(this);
    this._onScrollEvent2 = this._onScrollEvent2.bind(this);
  }

  _onScrollEvent() {

    try {

      var box: any = document.querySelector(`#${this.props.File._id.substring(1, 10)}`);
      if (box) {
        if (Util.isInViewport(box)) {
          if (box.paused) {
            box.play();
          }
        } else {
          if (!box.paused) {
            box.pause();
          }
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  }


  private _onScrollEvent2() {
    try {


      let thisObj = this;
      var element: any = document.querySelector(`#${thisObj.props.File._id.substring(1, 10)}`);

      if (element) {
        var position = element.getBoundingClientRect();
        if (position.top >= 0 && position.bottom <= window.innerHeight) {
        }
        if (position.top < window.innerHeight && position.bottom >= 0) {
          element.pause();
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  componentDidMount() {
    window.addEventListener('scroll', this._onScrollEvent2);
    //  window.addEventListener('scroll', this._onScrollEvent);
  }

  componentWillUnmount() {
    // window.removeEventListener("scroll", this._onScrollEvent, { capture: false });
    window.removeEventListener("scroll", this._onScrollEvent2, { capture: false });
  }

  render(): JSX.Element {
    return (<>
      <div className={`${this.props.isThumbNail ? 'hover-ef' : 'sp-hide'}`}>
      </div>
      <video id={this.props.File._id.substring(1, 10)} width="100%" height="100%" controls controlsList="nodownload">
        <source src={`${this.props.File.filePath}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </>
    );
  }
}

export default LocalVideo;

