import * as React from "react";
import './styles.scss';
import ProgressBar from "react-bootstrap/ProgressBar";

interface IProps {
    filesInfo: any[];
    removefile: (fileInfo: any) => void;
}

class FileUploadBindingInfo extends React.Component<IProps, {}> {
    public render(): JSX.Element {
        return (
            <>
                {
                    this.props.filesInfo.map((fileInfo: any) => {
                        return <div key={fileInfo.name}>
                            <div className="ms-Grid upload" dir="ltr">
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <p className="filename">{fileInfo.name ? fileInfo.name : fileInfo.response && fileInfo.response.originalName}</p>
                                    </div>
                                    <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6">
                                        <div className="ms-Grid" dir="ltr">
                                            <div className="ms-Grid-row">
                                                <div className="ms-Grid-col ms-sm10 ms-md10 ms-lg11 sp-progress-bar ">
                                                    {fileInfo.progress != 100 &&
                                                        <ProgressBar now={fileInfo.progress}
                                                            label={fileInfo.progress}
                                                            animated={true}
                                                        />
                                                    }
                                                </div>
                                                <div className="ms-Grid-col ms-sm2 ms-md2 ms-lg1">
                                                    <span className="btn-remove-file sp-float-right"
                                                        onClick={() => this.props.removefile(fileInfo)}> &times;</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                }
            </>
        );
    }

}

export default FileUploadBindingInfo;