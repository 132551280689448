import * as React from "react";
import MaterialTable from 'material-table';
import { ISavedNews, IStatusForm, NewsType, IHelpDeskCategoryCount, HelpDeskType } from '../../../models/models';
import NewsService from '../service';
import FilesDisplayList from '../utils/FilesDisplayList';
import Service from '../service';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import Profile from '../utils/Profile';
import Util from '../../../util';
import Loading from "../../common/Loading";
import EditHelpRequest from "./EditHelpRequest";
import { URLs } from "../../../constants/constants";
import ShowMoreSP from "../../common/ShowMoreSP";


interface IPageInfo {
  totalPages: number;
  currentPage: number;
}

interface IState {
  allNews: ISavedNews[];
  isLoading: boolean;
  selectedItem: any;
  changeStatus: boolean;
  statusForm: IStatusForm;
  isShowCompleteInfo: boolean;
  newsCountInfo: IHelpDeskCategoryCount;
  status: string;
  currentFilter: string;
  skipIndex: number;
  currentRecords: number;
  pageInfo: IPageInfo;
}

interface IProps {

}

class MainHelpRequests extends React.PureComponent<IProps, IState> {
  private newsService: NewsService;
  private service: Service;
  constructor(props: IProps) {
    super(props);
    this.state = {
      allNews: [],
      isLoading: false,
      selectedItem: null,
      changeStatus: false,
      statusForm: { statusMessage: '', status: '' },
      status: '',
      isShowCompleteInfo: false,
      newsCountInfo: { personal: 0, public: 0, all: 0 },
      skipIndex: 0,
      currentRecords: 0,
      currentFilter: 'All',
      pageInfo: { currentPage: 0, totalPages: 0 },

    }
    this.newsService = new NewsService();
    this.service = new Service();
    this._onItemSelected = this._onItemSelected.bind(this);
    this._closeDialog = this._closeDialog.bind(this);
    this._afterStatusUpdated = this._afterStatusUpdated.bind(this);
    this._reloadData = this._reloadData.bind(this);
    this._fetchNextOrPrevisousRecords = this._fetchNextOrPrevisousRecords.bind(this);
    this._toggleShowCompleteInfo = this._toggleShowCompleteInfo.bind(this);
    this._updateNews = this._updateNews.bind(this);
    this._onStatusFilterChange = this._onStatusFilterChange.bind(this);
  }


  private getData() {
    this.setState({
      isLoading: true,
      selectedItem: null,
      changeStatus: false
    });
    var promise: Promise<any>[] = [];
    promise.push(this.newsService.getDataOrLogout(URLs.getHelpRequestsCountByCategory, true).then((res: any) => {
      if (res.status) {
        let newsCountInfo: any = {};
        res.data.forEach((c: any) => {
          newsCountInfo[c._id] = c.count;
        });
        if (newsCountInfo.personal === undefined) {
          newsCountInfo.personal = 0;
        }
        if (newsCountInfo.public === undefined) {
          newsCountInfo.public = 0;
        }
        newsCountInfo['all'] = newsCountInfo.personal + newsCountInfo.public;
        this.setState({
          newsCountInfo: newsCountInfo,
        });
      } else {
        this.setState({ isLoading: false });
      }
    }));
    promise.push(this.newsService.postDataOrLogout(URLs.getAllEnHelpReqestsForAdmin, { skip: 0 }, true).then((res: any) => {
      if (res.status) {
        let data: ISavedNews[] = res.data;
        this.setState({
          allNews: data,
          skipIndex: data.length,
          pageInfo: Util.getPageInfo(this.state.currentFilter, this.state.newsCountInfo.all, data.length),
          currentRecords: data.length
        });
      } else {
        this.setState({
          isLoading: false
        });
      }

    }));

    return Promise.all(promise).then(() => {
      this.setState({
        isLoading: false
      });
      return;
    });
  }
  componentDidMount() {
    this.getData();
  }

  private _fetchNextOrPrevisousRecords(where: string) {
    this.setState({
      isLoading: true
    });

    if (where === 'previous') {
      let filter: any = {};
      filter.skip = this.state.skipIndex - 500 - this.state.currentRecords;
      if (this.state.currentFilter === NewsType.New) {
        filter.field = "Category";
        filter.value = NewsType.New;
      } else if (this.state.currentFilter === NewsType.Article) {
        filter.field = "Category";
        filter.value = NewsType.Article;
      } else if (this.state.currentFilter === NewsType.Info) {
        filter.field = "Category";
        filter.value = NewsType.Info;
      }

      this.newsService.postDataOrLogout(URLs.getAllEnHelpReqestsForAdmin, filter, true).then((res: any) => {
        if (res.status) {
          let data: ISavedNews[] = res.data;
          this.setState({
            allNews: data,
            isLoading: false,
            skipIndex: this.state.skipIndex - 500 - this.state.currentRecords + data.length,
            pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, this.state.currentFilter, this.state.newsCountInfo.all, this.state.skipIndex - 500),
            currentRecords: data.length
          });

        } else {
          this.setState({
            isLoading: false
          });
        }
      });

    } else {
      let filter: any = {};
      filter.skip = this.state.skipIndex;
      if (this.state.currentFilter === NewsType.New) {
        filter.field = "Category";
        filter.value = NewsType.New;
      } else if (this.state.currentFilter === NewsType.Article) {
        filter.field = "Category";
        filter.value = NewsType.Article;
      } else if (this.state.currentFilter === NewsType.Info) {
        filter.field = "Category";
        filter.value = NewsType.Info;
      }

      this.newsService.postDataOrLogout(URLs.getAllEnHelpReqestsForAdmin, filter, true).then((res: any) => {
        if (res.status) {
          let data: ISavedNews[] = res.data;
          this.setState({
            allNews: data,
            isLoading: false,
            skipIndex: this.state.skipIndex + data.length,
            currentRecords: data.length,
            pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, this.state.currentFilter, this.state.newsCountInfo.all, this.state.skipIndex + data.length)
          });
        } else {
          this.setState({
            isLoading: false
          });
        }
      });
    }
  }

  private _onItemSelected(event: any, data: any) {
    this.setState({
      changeStatus: false,
      selectedItem: data
    });
  }

  private _toggleShowCompleteInfo() {
    this.setState((prevState: IState) => {
      return { isShowCompleteInfo: !prevState.isShowCompleteInfo }
    });
  }

  private _updateNews(status: string) {
    this.setState((prevState: IState) => ({
      changeStatus: true,
      status: status,
    }));
  }

  private _closeDialog() {
    this.setState({
      changeStatus: false,
      status: ''
    });
  }

  private _afterStatusUpdated(id: string) {
    this._reloadData();
  }

  private _onStatusFilterChange(option: string) {
    if (option !== this.state.currentFilter) {
      this.setState({
        isLoading: true,
        selectedItem: null,
        changeStatus: false,
      });

      if (option === 'All') {
        this.newsService.postDataOrLogout(URLs.getAllEnHelpReqestsForAdmin, { skip: 0 }, true).then((res: any) => {
          if (res.status) {
            let data: ISavedNews[] = res.data;
            this.setState({
              allNews: data,
              isLoading: false,
              skipIndex: data.length,
              pageInfo: Util.getPageInfo(option, this.state.newsCountInfo.all, data.length),
              currentRecords: data.length,
              currentFilter: option,
              isShowCompleteInfo: true
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        });
      } else {
        this.newsService.postDataOrLogout(URLs.getAllEnHelpReqestsForAdmin, { skip: 0, field: 'Category', value: option }, true).then((res: any) => {
          if (res.status) {
            let data: ISavedNews[] = res.data;
            this.setState({
              allNews: data,
              isLoading: false,
              skipIndex: data.length,
              pageInfo: Util.getPageInfo(option, this.state.newsCountInfo.personal, data.length),
              currentRecords: data.length,
              currentFilter: option,
              isShowCompleteInfo: true
            });
          } else {
            this.setState({
              isLoading: false
            });
          }
        });
      }
    }
  }

  private _reloadData() {
    this.getData();
  }

  render(): JSX.Element {
    const { allNews, isLoading } = this.state;
    return (
      <div className="sp-mt10">

        {this.state.newsCountInfo &&
          <div className="news-dashbord">
            <span className="Title">Help Requests</span>
            <DefaultButton className="sp-btn-m sp-btn-black sp-float-right" onClick={this._reloadData} text="Reload" />
            <div className="sp-clearFix">
            </div>
            <hr className="sp-hr" />
            <div className="ms-Grid upload" dir="ltr">
              <div className="ms-Grid-row">
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                  <div className="item-c">
                    <p className="item-title">Total</p>
                    <i className="ms-Icon ms-Icon--Album sp-icon1 sp-blue sp-float-left" onClick={() => this._onStatusFilterChange("All")} aria-hidden="true"></i>
                    <p className="item-count">
                      {this.state.newsCountInfo.all}
                    </p>
                  </div>
                </div>
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                  <div className="item-c">
                    <p className="item-title">Personal</p>
                    <i className="ms-Icon ms-Icon--StatusCircleQuestionMark sp-icon1 sp-yellow sp-float-left" onClick={() => this._onStatusFilterChange(HelpDeskType.Personal)} aria-hidden="true"></i>
                    <p className="item-count">
                      {this.state.newsCountInfo.personal}
                    </p>
                  </div>
                </div>
                <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                  <div className="item-c">
                    <p className="item-title">Public</p>
                    <i className="ms-Icon ms-Icon--Emoji2 sp-icon1 sp-orange sp-float-left" onClick={() => this._onStatusFilterChange(HelpDeskType.Public)} aria-hidden="true"></i>
                    <p className="item-count">
                      {this.state.newsCountInfo.public}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <hr className="sp-hr sp-mt30" />
            <DefaultButton className="sp-btn-m view-all-button" onClick={this._toggleShowCompleteInfo} text={this.state.isShowCompleteInfo ? "Hide Complete Details" : "View Complete Details"} />
            <div className="sp-clearFix" ></div>
            {this.state.isShowCompleteInfo && <>
              <div className="material-table">
                <div className="pagination">
                  <span className={`nav-btn ${this.state.pageInfo.currentPage === 1 ? 'sp-hide' : ''}`} onClick={() => this._fetchNextOrPrevisousRecords('previous')} title="Previous Page" >&laquo;</span>
                  <span title="Current Page"  >{`Page ${this.state.pageInfo.currentPage} of ${this.state.pageInfo.totalPages}`} </span>
                  <span className={`nav-btn ${this.state.pageInfo.currentPage === this.state.pageInfo.totalPages ? 'sp-hide' : ''}`} onClick={() => this._fetchNextOrPrevisousRecords('next')} title="Next Page" >&raquo;</span>
                </div>
                <div className="sp-clearFix"></div>
                <MaterialTable
                  style={{ marginTop: "5px", padding: "5px", boxShadow: "none" }}
                  title="New posts"
                  columns={[
                    { title: 'Title', field: 'Title', tooltip: 'Title' },
                    { title: 'Category', field: 'Category' },
                    { title: 'Date', field: 'DateTime' }
                  ]}
                  data={allNews}
                  actions={[
                    {
                      icon: 'edit',
                      tooltip: 'Select news',
                      onClick: this._onItemSelected
                    }
                  ]}
                  options={{
                    rowStyle: rowData => ({
                      backgroundColor: (this.state.selectedItem && this.state.selectedItem._id === rowData._id) ? '#EEE' : '#FFF'
                    })
                  }}
                ></MaterialTable>
              </div>
              <div>
                {(this.state.selectedItem &&
                  <div className="selected-item">
                    <h4>News Information </h4>
                    <hr />
                    <div className="ms-Grid" >
                      <div className="ms-Grid-row">
                        <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 msg-details">
                          <h6 className="msg-headings">Title <span className="sp-float-right sp-pointer">
                            {Util.formatAMPM(this.state.selectedItem.DateTime)}
                          </span></h6>
                          <div className="sp-clearFix"></div>
                          <ShowMoreSP
                            text={this.state.selectedItem.Title}
                          />
                          <h6 className="msg-headings sp-mt10">Description</h6>
                          <ShowMoreSP
                            text={this.state.selectedItem.Description}
                          />
                          <FilesDisplayList files={this.state.selectedItem.Files}></FilesDisplayList>
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                          <h6>Submitted by </h6>
                          {(this.state.selectedItem.User && this.state.selectedItem.User._id) ?
                            <Profile userId={this.state.selectedItem.User._id} /> : <p>Unknown</p>
                          }
                          <hr />
                          <h6>Reviewed by </h6>
                          {this.state.selectedItem.ReviewerId ?
                            <Profile userId={this.state.selectedItem.ReviewerId} /> : <p>Unknown</p>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="action-buttons">
                      <DefaultButton className="sp-btn-m sp-btn-process" onClick={() => this._updateNews('Approved')} text="Edit" />
                    </div>
                  </div>
                )}
                <p></p>
              </div>
              {(this.state.changeStatus && <EditHelpRequest _closeDialog={this._closeDialog} newsInfo={this.state.selectedItem} status={this.state.status} afterStatusUpdated={this._afterStatusUpdated} />)}
            </>}
          </div>
        }
        {isLoading && <Loading />}
      </div>
    );
  }
}

export default MainHelpRequests;