import { IServices } from './IService';
import Service from '../../services/services';
import { URLs } from '../../constants/constants';
import AuthService from '../../services/authService/index';

export default class NewsService implements IServices {
  private service: Service;
  private authService: AuthService;
  constructor() {
    this.service = new Service();
    this.authService = new AuthService();
  }

  private dataOrLogout(res: any): any {
    if (res.status === 200) {
      return res.data;
    } else if (res.status === false && res.statuscode === 403) {
      this.authService.LogOut();
    } else if (res.status === false && res.statuscode === 401) {
      this.authService.LogOut();
    } else if (res.status === false) {
      return res;
    }
  }

  private dataOrError(res: any): any {
    if (res.status === 200) {
      return res.data;
    } else if (res.status === false) {
      return res;
    }
  }

  public getDataOrLogout(api: string, isLangRequired: boolean, authRequired: boolean): Promise<any> {
    var language = localStorage.getItem('language');
    let url = `/use${isLangRequired ? `/${language}` : ''}${api}`;
    return this.service.get(url, authRequired).then((res: any) => {
      return this.dataOrLogout(res);
    });
  }

  public getDataOrError(api: string, isLangRequired: boolean, authRequired: boolean): Promise<any> {
    var language = localStorage.getItem('language');
    let url = `/use${isLangRequired ? `/${language}` : ''}${api}`;
    return this.service.get(url, authRequired).then((res: any) => {
      return this.dataOrError(res);
    });
  }

  public postDataOrLogout(api: string, data: any, isLangRequired: boolean, authRequired: boolean): Promise<any> {
    var language = localStorage.getItem('language');
    let url = `/use${isLangRequired ? `/${language}` : ''}${api}`;
    return this.service.post(url, data, authRequired).then((res: any) => {
      return this.dataOrLogout(res);
    });
  }

  public postDataOrError(api: string, data: any, isLangRequired: boolean, authRequired: boolean): Promise<any> {
    var language = localStorage.getItem('language');
    let url = `/use${isLangRequired ? `/${language}` : ''}${api}`;
    return this.service.post(url, data, authRequired).then((res: any) => {
      return this.dataOrError(res);
    });
  }

  public checkIsUserIsPolled(newsInfo: any): Promise<any> {
    let url = `/use${URLs.checkIsUserIsPolled}`
    return this.service.post(url, newsInfo, true).then((res: any) => {
      if (res.status === 200) {
        return res.data;
      } else if (res.status === false && res.statuscode === 401) {
        return res;
      } else if (res.status === false) {
        return res;
      }
    });
  }

  public SavePollResults(newsInfo: any): Promise<any> {
    let url = `/use${URLs.SavePollResults}`
    return this.service.post(url, newsInfo, true).then((res: any) => {
      if (res.status === 200) {
        return res.data;
      } else if (res.status === false && res.statuscode === 403) {
        this.authService.LogOut();
      } else if (res.status === false && res.statuscode === 401) {
        return res;
      } else if (res.status === false) {
        return res;
      }
    });
  }
}