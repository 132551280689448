import * as React from "react";
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import AddPaper from "./addPaper";
import LiveMonitor from "./LiveMonitor";
import LivePushNotification from '../adminSection/LivePushNotification';
import AllEPapers from "./allEPapers";

interface IState {
}

interface IProps {
}

class EPaper extends React.PureComponent<IProps, IState> {

    render(): JSX.Element {
        return (
            <div className="sp-mt10">
                <Pivot aria-label="Basic Pivot Example" >
                    <PivotItem
                        headerText="Add Paper"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'My Files Title',
                        }}
                    >
                        <AddPaper />
                    </PivotItem>
                    <PivotItem
                        headerText="All papers"
                        headerButtonProps={{
                            'data-order': 2,
                            'data-title': 'My fge',
                        }}
                    >
                        <AllEPapers />
                    </PivotItem>
                </Pivot>
            </div>

        );
    }
}

export default EPaper;