import * as React from "react";
import './styles.scss';
import { AppState } from "../../../../../Redux/app.store";
import { connect } from "react-redux";
import { IUserState } from "../../../../../Redux/models";
import NewsService from "../../../Service";
import { IUserC, IFileC } from "../../../../../models/models";
import Sources from "../../../../common/Templates/Sources";
import { NavLink } from "react-router-dom";
import { URLs } from "../../../../../constants/constants";
const TextTruncate = require('react-text-truncate');

interface ITopNews {
    Title: string;
    _id: string;
    Description: string;
    DateTime: string;
    User: IUserC;
    Files: IFileC[];
}
interface IState {
    AllInfo: ITopNews[];
    Index: number;
    isLoading: boolean;
    skip: number;
    stopRequests: boolean;
}

interface IProps {
    User: IUserState;
}

class ImageTextCard extends React.Component<IProps, IState> {
    private newsService: NewsService;
    private language: string;
    currentLanguage = localStorage.getItem('language');
    constructor(props: IProps) {
        super(props);
        this.state = {
            AllInfo: [],
            isLoading: false,
            Index: 0,
            skip: 0,
            stopRequests: false
        }
        this.newsService = new NewsService();
        this.language = this.currentLanguage ? this.currentLanguage : 'en';
        this.getNorPR = this.getNorPR.bind(this);
    }

    public getNorPR(type: any) {
        if (type === "prev") {
            if (this.state.Index > 0) {
                this.setState((prevStat: IState) => {
                    return { Index: Number(prevStat.Index) - 1 }
                });
            }
            else {
                this.setState((prevStat: IState) => {
                    return { Index: prevStat.AllInfo.length - 1 }
                });
            }
        }
        else {
            if (this.state.AllInfo.length - 1 > this.state.Index) {
                this.setState((prevStat: IState) => {
                    return { Index: Number(prevStat.Index) + 1 }
                });

                if (!this.state.stopRequests) {
                    if (this.state.Index > this.state.AllInfo.length - 5) {
                        this.newsService.postDataOrError(URLs.getTopNews, { skip: this.state.skip }, true, false).then((res: any) => {
                            if (res.status) {
                                if (res.data.length > 0) {
                                    let data = res.data;
                                    let allre: ITopNews[] = this.state.AllInfo;
                                    data.forEach((d: any) => {
                                        allre = [...allre, d];
                                    });
                                    this.setState((prevState: IState) => {
                                        return {
                                            AllInfo: allre,
                                            isLoading: false,
                                            skip: data.length > 0 ? prevState.skip + data.length : prevState.skip,
                                        }
                                    });
                                } else {
                                    this.setState({ isLoading: false, stopRequests: true });
                                }
                            } else {
                                this.setState({
                                    isLoading: false
                                });
                            }
                        });
                    }
                }
            } else {
                this.setState((prevStat: IState) => {
                    return { Index: 0 }
                });
            }
        }
    }

    private getData() {
        this.setState({
            isLoading: true,
            AllInfo: [],
            Index: 0
        })
        this.newsService.postDataOrError(URLs.getTopNews, { skip: 0 }, true, false).then((res: any) => {
            if (res.status) {
                let data = res.data;
                this.setState({
                    AllInfo: data,
                    isLoading: false,
                    skip: data.length
                });
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });
    }

    componentWillReceiveProps(newProps: IProps) {
        if (this.language !== newProps.User.language) {
            this.language = newProps.User.language;
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();
        let thisObj = this;
        let timeout: any;
        let videoContainer: any = document.getElementById("video-test");
        function startInterval() {
            timeout = setInterval(function () {
                if (!thisObj.state.stopRequests) {
                    if (thisObj.state.AllInfo.length - 1 > thisObj.state.Index) {
                        thisObj.setState((prevStat: IState) => {
                            return { Index: Number(prevStat.Index) + 1 }
                        });
                        if (thisObj.state.Index > thisObj.state.AllInfo.length - 5) {
                            thisObj.newsService.postDataOrError(URLs.getTopNews, { skip: thisObj.state.skip }, true, false).then((res: any) => {
                                if (res.status) {
                                    if (res.data && res.data.length > 0) {
                                        let data = res.data;
                                        let allre: ITopNews[] = thisObj.state.AllInfo;
                                        data.forEach((d: any) => {
                                            allre = [...allre, d];
                                        });
                                        thisObj.setState((prevState: IState) => {
                                            return {
                                                AllInfo: allre,
                                                isLoading: false,
                                                skip: data.length > 0 ? prevState.skip + data.length : prevState.skip,
                                            }
                                        });
                                    } else {
                                        thisObj.setState({ isLoading: false, stopRequests: true });
                                    }
                                } else {
                                    thisObj.setState({
                                        isLoading: false
                                    });
                                }
                            });
                        }
                    } else {
                        thisObj.setState((prevStat: IState) => {
                            return { Index: 0 }
                        });
                    }
                }
            }, 3000);
        }
        startInterval();
        if (videoContainer) {
            videoContainer.addEventListener("mouseover", mouseOver);
            videoContainer.addEventListener("mouseout", mouseOut)
        }

        function mouseOver() {
            clearInterval(timeout);
        }

        function mouseOut() {
            startInterval();
        }
    }

    showSlide(slideNumber: number) {
        this.setState((prevStat: IState) => {
            return { Index: slideNumber }
        });
    }

    public render(): JSX.Element {
        return (<>
            <div id="video-test">
                {this.state.AllInfo.length > 0 &&
                    <div className="sp-img-txt-card sp-card-m">
                        <div className="card sp-card-img">
                            <p className="heading" >{this.props.User.staticConstants.Constants.imageTextCardTitle}</p>
                            <div className="files-c">
                                {this.state.AllInfo[this.state.Index].Files && <Sources Sources={this.state.AllInfo[this.state.Index].Files} isThumbNail={true} onlyOne={true} />}
                            </div>
                            <div className="sp-clearFix"></div>
                            <div className="sp-ml10">
                                <TextTruncate
                                    line={2}
                                    element="h5"
                                    truncateText="…"
                                    text={this.state.AllInfo[this.state.Index].Title}
                                />
                                <TextTruncate
                                    line={3}
                                    element="p"
                                    truncateText="…"
                                    text={this.state.AllInfo[this.state.Index].Description}
                                />
                                <NavLink className="read-more-li" to={`/newsi/${this.state.AllInfo[this.state.Index]._id}`} >{this.props.User.staticConstants.Constants.readMore}</NavLink>
                            </div>
                            <div className="overlay-img1">
                                {(this.state.AllInfo.length - 1 > this.state.Index) && <button className="sp-nav-btn sp-btn-right" onClick={() => this.getNorPR("next")}><i className="ms-Icon ms-Icon--ChevronRight" aria-hidden="true"></i></button>}
                                {(this.state.Index > 0) && <button className="sp-nav-btn sp-btn-left" onClick={() => this.getNorPR("prev")}><i className="ms-Icon ms-Icon--ChevronLeft" aria-hidden="true"></i></button>}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
        );
    }
}

const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default connect(
    mapStateToProps,
)(ImageTextCard);