import * as React from "react";
import { INewsInfoC, MainNewsModel } from '../../../../models/models';
import { match, withRouter } from "react-router-dom";
import Profile from './Profile';
import NewsService from '../../Service';
import { socket } from "../../../../app";
import Navbar from "../../Navbar";
import Secondnavbar from '../../Navbar/Secondnavbar';
import { connect } from "react-redux";
import { AppState } from "../../../../Redux/app.store";
import { IUserState } from "../../../../Redux/models";
import { URLs } from "../../../../constants/constants";
import { Shimmer3 } from "../../../common/Loading/Shimmers";
import Analysis from "../templates/Analysis";
import './styles.scss';
import Util from "../../../../util";
import NewsFeedAd from "../../../common/advertise/NewsFeedAd";

interface IState {
    routeId: string;
    isLoading: boolean;
    selectedUserNews: INewsInfoC[];
    currentScrollPosition: number;
    skip: number;
    stopRequests: boolean;
}

interface IDetailParams {
    id: string;
}
interface IProps {
    match?: match<IDetailParams>;
    User: IUserState;
}

class SelectedUserNewsInfo extends React.Component<IProps, IState> {
    private service: NewsService;
    private language: string;
    currentLanguage = localStorage.getItem('language');
    constructor(props: IProps) {
        super(props);
        let routeInfo: any = this.props.match;
        this.state = {
            routeId: routeInfo.params.id,
            isLoading: false,
            selectedUserNews: [],
            currentScrollPosition: 0,
            skip: 0,
            stopRequests: false
        }

        this.language = this.currentLanguage ? this.currentLanguage : 'en';
        this.service = new NewsService();
        this._onScrollEvent = this._onScrollEvent.bind(this);
    }

    private getData() {
        this.setState({ isLoading: true });
        this.service.postDataOrError(URLs.getAllNewsByUserId, { id: this.state.routeId, skip: 0 }, true, false).then((res: any) => {
            if (res.status) {
                let data = res.data;
                this.setState({
                    selectedUserNews: data,
                    isLoading: false,
                    currentScrollPosition: 0,
                    skip: data.length,
                });
            } else {
                this.setState({ isLoading: false });
            }
        });
    }

    componentWillReceiveProps(newProps: IProps) {
        if (this.language !== newProps.User.language) {
            this.language = newProps.User.language;
            this.getData();
        }
    }

    componentDidMount() {
        this.getData();

        socket.on("NewsUpdated", (data: any) => {
            let newsInfo: any;
            let allNews: INewsInfoC[] = this.state.selectedUserNews;
            let tempNews: INewsInfoC[] = [];
            if (this.language === 'en') {
                newsInfo = data.English;
            } else {
                newsInfo = data.Telugu;
            }

            allNews.forEach((news: INewsInfoC) => {
                if (news._id === newsInfo._id) {
                    if (newsInfo.Show) {
                        tempNews = [...tempNews, this.language == 'en' ? new MainNewsModel(newsInfo) : newsInfo]
                    }
                } else {
                    tempNews = [...tempNews, news];
                }
            });
            this.setState({
                selectedUserNews: tempNews
            });
        });
    }


    _onScrollEvent() {
        const totalHeight = document.documentElement.scrollHeight;
        if (window.pageYOffset > totalHeight - 3000 && !this.state.isLoading && !this.state.stopRequests) {
            this.setState({
                isLoading: true
            });

            this.service.postDataOrError(URLs.getAllNewsByUserId, { id: this.state.routeId, skip: this.state.skip }, true, false).then((res: any) => {
                let allre: any[] = this.state.selectedUserNews;

                if (res.status) {
                    let data: [] = res.data;
                    data.forEach((d: any) => {
                        allre = [...allre, d];
                    });
                    this.setState({
                        selectedUserNews: allre,
                        currentScrollPosition: window.scrollY,
                        skip: this.state.skip + res.data.length,
                        isLoading: false,
                        stopRequests: true
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        stopRequests: true
                    });
                }
            });
        }
    }

    componentDidUpdate() {
        window.addEventListener('scroll', this._onScrollEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._onScrollEvent, { capture: false });
    }

    render(): JSX.Element {
        return (
            <>
                <Navbar />
                <Secondnavbar />
                <div className="sp-container main-container " >
                    <div className="ms-Grid" dir="ltr">
                        <div className="ms-Grid-row selectedUserInfo" >
                            <div className="ms-Grid-col ms-sm12 ms-md4 ms-lg4-5 p-section" >
                                <div style={{ width: '100%', alignContent: 'center' }}>
                                    {this.state.routeId &&
                                        <Profile userId={this.state.routeId} />}
                                </div>
                            </div>
                            <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg7-5" >
                                <div className="main-container-wrapper">
                                    {this.state.selectedUserNews && this.state.selectedUserNews.length > 0 &&
                                        this.state.selectedUserNews.map((news: INewsInfoC, index: number) => {
                                            if (index !== 0 && index % Util.getRandomNumber(5, 7) == 0) {
                                                return <div id={news._id}><div className="news-feed-ad"><NewsFeedAd cid={news._id.substring(1, 8)} /> </div>
                                                    <div id={news._id}><Analysis news={news} hideUserInfo={true} /></div>
                                                </div>
                                            } else {
                                                return <div id={news._id}><Analysis news={news} hideUserInfo={true} /></div>
                                            }
                                        })
                                    }
                                    {this.state.isLoading && <>
                                        <div className="shimmer-main-w"> <Shimmer3 /> </div>
                                        <div className="shimmer-main-w"> <Shimmer3 /> </div>
                                    </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}




const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default withRouter(connect(
    mapStateToProps,
)(SelectedUserNewsInfo));