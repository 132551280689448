import IStatic from "./static";
import { UserType } from "./constants";
export const staticConsants: IStatic = {
  en: {
    Constants: {
      login: "Login",
      logout: "Logout",
      home: "Q GROUP",
      news: "News",
      info: "Info",
      article: "Articles",
      aboutUs: "AboutUs",
      post: "React",
      polls: "Polls",
      admin: "Admin",
      viewMoreQuestions: "View more questions.",
      beFirstOneToAskQuestion: "Be the first one to ask question.",
      pleaseLoginToAskQuestion: "Please login to ask questions.",
      changeTo: "తెలుగు",
      myProfile: "My Profile",
      dashboard: "Dashboard",
      needHelp: "Need Help ?",
      donate: "Donate",
      donateText1:
        "Please donate a small amount to raise Our (Journalist) voice.",
      donateText2: "A small amount can help us to save Journalism and society.",
      postNews: "Post News",
      title: "Title",
      descripiton: "Description",
      enterTitle: "Enter Title",
      enterNewsDescription: "Describe the News.",
      send: "Send",
      required: "Required.",
      min8CharRequired: "Min 8 characters required",
      invalidOTP: "Invalid OTP",
      readOurPolicy: "Read our",
      fileUploadWarning: "Please wait until files are uploaded.",
      newsSent: "News posted successfully.",
      thanks: "Thanks",
      ok: "Ok",
      describeYourProblem: "Describe your problem.",
      whatIsYourProblem: "What is your problem?",
      whatToDo: "What we need to Do?",
      whatToDoPlaceHo: "Please tell us what to do.",
      helpSentMsg:
        "Yours help request submitted successfully. We will contact you shortly.",
      commentsLoginWarning: "Please login to see comments.",
      viewMoreComments: "View more comments.",
      beFirstOneToComment: "Be the first one to comment.",
      comments: "Comments",
      writeAComment: "Write a comment...",
      questions: "Questions",
      askQuestion: "ask questions.",
      noRecords: "No records.",
      readMore: " Read more",
      readLess: "Read less",
      headLines: "Headlines",
      imageTextCardTitle: "Teenmar mallanna",
      onlyNumbersAllowed: "Only numbers are allowed.",
      enterValidNumber: "Enter Valid Phone Number.",
      phoneNumber: "Phone number",
      password: "Password",
      forgetPassword: "Forget Password",
      newToOurSite: "New to our site?",
      createAccount: "Join with us",
      loginTitle: "Login",
      verifyMobile: "Verify phone number",
      resend: "Resend",
      otpSent: "OTP send secussfully.",
      didNotRecieveOtp: "Didn't Recieve Otp",
      invalidPassword: "",
      error: "Error Occured.",
      rejectedError:
        "your account is rejected due to wrong Information entered.",
      suspendedError: "Your account is Suspended.",
      accountNotFoundError: "Couldn't find you Account.",
      uploadPhoto: "Upload photo",
      confirmPassword: "Confirm Password",
      firstName: "First Name",
      lastName: "Last Name",
      email: "Email",
      gender: "Gender",
      state: "State",
      district: "City",
      agreeTerms: "Agree to our Terms & Conditions and Privacy Policy",
      agreeTermsWarning: "Please Agree",
      passwordDoesntMatch: "Password doesn't match.",
      imageUploadWarning: "Please select images only.",
      imageSize5MbWarning: "Max size 5MB",
      invalidEmail: "Invalid email",
      register: "Register",
      alreadyRegisteredWarning:
        "Already Registered with this Phone Number/Email",
      successFullyRegistered: "You have Successfully registered.",
      pleaseEnter6DigiiOtp:
        "Please enter 6 digit OTP sent to your phone number.",
      passwordChanged: "Password changed successfully.",
      pleaseLogin: "Please Login",
      verifyEmail: "Verify EMail",
      emailNotVerified: "Your email is not verified",
      pleaseEnterEmailToVerify: "Please enter your email to verify.",
      accountIsVerified: "Your account is verified.",
      corruption: "Corruption",
      kcr: "KCR",
      telangana: "Telangana",
      india: "India",
      qmusic: "Music",
      infoText:
        "If you are facing any problem in your life. please let us know, we will help to sort it out. \n  Want to fight the problem? Do you want to bring about change in society? \n Do you want to combat these robbers? \n Want to rip these bribes out? \nWant to fight the pirate kingdom and plunder? ",
      infoTitle: "Instructions",
      newsText:
        "Please Send us news that is happening around you with the proofs. We won't approve the news if it doesn't have the proofs. \n If you send us news without Login/Registration, you can't track the status of the news. Please login and check your dashboard for the status of posted news. \n If you don't want to track your news and don't want tell your name, you can send news withour login/registration. In that case, you need send the proofs of things.",
      pollsLoginWarningMsg: "Please login to poll your vote.",
      sessionExpired: "Sessions expired. please login again.",
      analysis1: "Analysis 1",
      analysis2: "Analysis 2",
      analysis3: "Analysis 3",
      movie: "Movies",
      business: "Business",
      trending: "Trending",
      mustwatch: "Mustwatch",
      timepass: "Timepass",
      crime: "Crime",
      jobs: "Jobs",
      politics: "Politics",
      education: "Education",
      international: "International",
      weather: "Weather",
      corona: "Corona",
      health: "Health",
      women: "Women",
      devotional: "Devotional",
      sports: "Sports",
      livewarning:
        "If video shows buffering, please stop playing it for 4 seconds and then play again.",
      electionsTitle: "MLC voter registration process started.",
      electionsDescription:
        "Dear all Graduates ... \n\nThe MLC voter registration process has begun. All eligible voters must register to vote. To register to vote, a Form 18, two passport size photos, a degree provisional certificate, a memo of marks, an Aadhaar card, a voter ID card must beattested by a Gazetted Officer and filed with the local MRO office by November 6th. \n\nRequired for application. \n1. Form 18 \n2.Degree Provisional Certificate \n3. Marks memo \n4.Aadhaar card \n5. Voter ID card \n6.Two passport size photos.",
      clickHereToApply: "Click here to apply.",
      coronaStats: "to know complete Statistics about Corona",
      clickHere: "Click here",
    },
  },
  te: {
    Constants: {
      login: "ప్రవేశించు",
      logout: "విశ్రమించు",
      home: "Q GROUP",
      news: "వార్తలు",
      info: "సమాచారం",
      article: "వ్యాసాలు",
      aboutUs: "మాగురించి",
      post: "స్పందన",
      polls: "పోల్స్",
      admin: "అడ్మిన్",
      viewMoreQuestions: "మరిన్ని ప్రశ్నలను చూడండి.",
      beFirstOneToAskQuestion: "మొదటి ప్రశ్న అడగండి.",
      pleaseLoginToAskQuestion: "ప్రశ్నలు అడగడానికి దయచేసి లాగిన్ అవ్వండి.",
      changeTo: "English",
      myProfile: "నా సమాచారం",
      dashboard: "అన్ని వివరాలు",
      needHelp: "సహాయం కావాలా ?",
      donate: "Donate",
      donateText1:
        "మా జర్నలిస్టులకు దయచేసి కొద్ది మొత్తాన్ని విరాళంగా ఇవ్వండి.",
      donateText2:
        "జర్నలిజం మరియు సమాజాన్ని కాపాడటానికి మీ విరాళాలు సహాయపడతాయి.",
      postNews: "వార్తలను పంపండి",
      title: "శీర్షిక",
      descripiton: "వివరణము",
      enterTitle: "శీర్షికను తెలుపండి.",
      enterNewsDescription: "వార్తను వివరించండి.",
      send: "పంపించు",
      required: "ఖాళీగా ఉంచవద్దు.",
      min8CharRequired: "పాస్వర్డ్ 8 అక్షరాలు ఉండాలి.",
      invalidOTP: "OTP తప్పు",
      readOurPolicy: "చదవండి",
      fileUploadWarning: "రుజువులు అప్‌లోడ్ అయ్యే వరకు వేచి ఉండండి.",
      newsSent: "మీ వార్తను పంపడం జరిగింది.",
      thanks: "ధన్యవాదాలు",
      ok: "Ok",
      describeYourProblem: "మీ సమస్యను వివరించండి.",
      whatIsYourProblem: "మీ సమస్య ఏమిటి?",
      whatToDo: "ఏమి చేయమని అడుగుతున్నారు?",
      whatToDoPlaceHo: "మావంతుగా ఏమి చేయాలో మాకు తెలుపండి.",
      helpSentMsg:
        "మీ సహాయ అభ్యర్థన విజయవంతంగా సమర్పించబడింది. మేము త్వరలో మిమ్మల్ని సంప్రదిస్తాము.",
      commentsLoginWarning: "వ్యాఖ్యలను చూడటానికి దయచేసి లాగిన్ అవ్వండి.",
      viewMoreComments: "మరిన్ని స్పందనలు చూడండి.",
      beFirstOneToComment: "మొదట మీరు స్పందించండి.",
      comments: "స్పందనలు",
      writeAComment: "మీ స్పందనను తెలియజేయండి...",
      questions: "ప్రశ్నలు",
      askQuestion: "ప్రశ్నలు అడగండి...",
      noRecords: "సమాచారం లేదు.",
      readMore: " ఇంకా చదవండి",
      readLess: "తక్కువ చదవండి",
      headLines: "ముఖ్యాంశాలు",
      imageTextCardTitle: "తీన్మార్ మల్లన్న",
      onlyNumbersAllowed: "సంఖ్యలు మాత్రమే అనుమతించబడతాయి.",
      enterValidNumber: "ఫోన్ నంబర్ చెల్లుబాటు కాదు.",
      phoneNumber: "ఫోన్ నంబర్",
      password: "పాస్వర్డ్",
      forgetPassword: "పాస్వర్డ్ మర్చిపోయారా?",
      newToOurSite: "కొత్తగా వచ్చారా?",
      createAccount: "మాతో చేరండి",
      loginTitle: "ప్రవేశించండి",
      verifyMobile: "మీ మొబైల్‌ను ధృవీకరించండి",
      resend: "మరల పంపు",
      otpSent: "OTP విజయవంతంగా పంపబడింది",
      didNotRecieveOtp: "OTP రాలేదా?",
      invalidPassword: "రహస్య పదము సరియినది కాదు.",
      error: "లోపం సంభవించింది",
      rejectedError: "తప్పు సమాచారం నమోదు చేసినందున మీ ఖాతా తిరస్కరించబడింది.",
      suspendedError: "మీ ఖాతా తాత్కాలికంగా నిలిపివేయబడింది.",
      accountNotFoundError: "మీకు ఖాతా కనుగొనబడలేదు.",
      uploadPhoto: "ఫోటోను జత చేయండి",
      confirmPassword: "పాస్వర్డ్ మరల తెలుపండి",
      firstName: "పేరు",
      lastName: "ఇంటి పేరు ",
      email: "ఇ-మెయిల్",
      gender: "Gender",
      state: "రాష్ట్రము",
      district: "జిల్లా",
      agreeTerms:
        "మా నిబంధనలు & షరతులు మరియు గోప్యతా విధానానికి అంగీకరిస్తున్నారు",
      agreeTermsWarning: "దయచేసి అంగీకరించండి.",
      passwordDoesntMatch: "పాస్‌వర్డ్ సరిపోలలేదు.",
      imageUploadWarning: "దయచేసి చిత్రాలను మాత్రమే ఎంచుకోండి.",
      imageSize5MbWarning: "Max size 5MB",
      invalidEmail: "తప్పు ఇ-మెయిల్",
      register: "చేరండి",
      alreadyRegisteredWarning:
        "ఇప్పటికే ఈ ఫోన్ నంబర్ / ఇమెయిల్‌తో నమోదు చేయబడింది.",
      successFullyRegistered: "మీరు విజయవంతంగా నమోదు చేసుకున్నారు.",
      pleaseEnter6DigiiOtp:
        "దయచేసి మీ మొబైల్‌కు పంపిన 6 అంకెల OTP ని నమోదు చేయండి.",
      passwordChanged: "పాస్వర్డ్ విజయవంతంగా మార్చబడింది.",
      pleaseLogin: "దయచేసి లాగిన్ అవ్వండి",
      verifyEmail: "ఇ-మెయిల్ నిర్ధారించండి",
      emailNotVerified: "మీ ఇమెయిల్ ధృవీకరించబడలేదు",
      pleaseEnterEmailToVerify:
        "ధృవీకరించడానికి దయచేసి మీ ఇమెయిల్‌ను నమోదు చేయండి.",
      accountIsVerified: "మీ ఖాతా ధృవీకరించబడింది.",
      corruption: "అవినీతి",
      kcr: "కెసిఆర్",
      telangana: "తెలంగాణ",
      india: "భారతదేశం",
      qmusic: "పాటలు",
      infoText:
        "మీరు మీ జీవితంలో ఏదైనా సమస్యను ఎదుర్కొంటుంటే. దయచేసి మాకు తెలియజేయండి, దాన్ని క్రమబద్ధీకరించడానికి మేము సహాయం చేస్తాము. \n లంచగొండి వేదిస్తున్నాడా? \n అన్యాయాన్ని చూస్తున్నావా? \n  ప్రశ్నిచాలనుందా? \n వెనకడుగు వేస్తున్నావా? సమస్య పై పోరాటాలనుకుంటున్నావా? \nసమాజం లో మార్పు తేవాలనుకుంటాన్నావా? \n ఈ దోపిడీ దొంగలను ఎదిరించాలనుకుంటున్నావా? \nఈ లంచగొండిలను చీల్చి చెండాడాలనుకుంటున్నావా? \nదొంగల రాజ్యం, దోపిడీ రాజ్యం తో పోరాడాలనుందా? \n ఇది ప్రజాస్వామ్యం, తప్పు చేయనంతవరకు ఎవరికీ భయపడాల్సిన పని లేదు? \n మేము మీకు అండగా ఉంటాము. \n మేము సాగించాలనుకుంటున్న పోరాటం లో మీరు చేరండి.",
      infoTitle: "సూచనలు",
      newsText:
        "దయచేసి మీ చుట్టూ జరుగుతున్న వార్తలను రుజువులతో మాకు పంపండి. వార్తలకు రుజువులు లేకపోతే మేము దానిని ఆమోదించము. \n లాగిన్ / రిజిస్ట్రేషన్ లేకుండా మీరు మాకు వార్తలను పంపితే, మీరు వార్తల స్థితిని ట్రాక్ చేయలేరు. దయచేసి లాగిన్ చేసి, పోస్ట్ చేసిన వార్తల స్థితి కోసం మీ డాష్‌బోర్డ్‌ను తనిఖీ చేయండి. \n మీరు మీ వార్తలను ట్రాక్ చేయకూడదనుకుంటే మరియు మీ పేరు చెప్పకూడదనుకుంటే, మీరు లాగిన్ / రిజిస్ట్రేషన్‌ లేకుండా వార్తలను పంపవచ్చు. అలాంటప్పుడు, మీరు విషయాల రుజువులను పంపాలి. \n మీ యొక్క ఆవేదనను ప్రజలకు తెలియాజేయండి.",
      pollsLoginWarningMsg: "దయచేసి మీ ఓటును పోల్ చేయడానికి లాగిన్ అవ్వండి.",
      sessionExpired: "సెషన్ ముగిసిపోయింది. దయచేసి మళ్ళీ లాగిన్ అవ్వండి.",
      analysis1: "విశ్లేషణ 1",
      analysis2: "విశ్లేషణ 2",
      analysis3: "విశ్లేషణ 3",
      movie: "సినిమా",
      business: "వ్యాపారాలు",
      trending: "ట్రేండింగ్",
      mustwatch: "చూడాల్సినవి",
      timepass: "టైంపాస్",
      crime: "నేరాలు",
      jobs: "ఉద్యోగాలు",
      politics: "రాజకీయాలు",
      education: "విద్య",
      international: "అంతర్జాతీయం",
      weather: "వాతావరణం",
      corona: "కరోనా",
      health: "ఆరోగ్యం",
      women: "వుమెన్",
      devotional: "భక్తి",
      sports: "క్రీడలు",
      livewarning:
        "వీడియో బఫరింగ్ చూపిస్తే, దయచేసి 4 సెకన్ల పాటు ప్లే చేయడాన్ని ఆపివేసి, ఆపై మళ్లీ ప్లే చేయండి",
      electionsTitle: "ప్రారంభ‌మైన ఎమ్మెల్సీ ఓట‌రు న‌మోదు ప్ర‌క్రియ‌.",
      electionsDescription:
        "ప్రియ‌మైన ప‌ట్ట‌భ‌ద్రులారా... \n\nఎమ్మెల్సీ ఓట‌రు న‌మోదు ప్ర‌క్రియ ప్రారంభ‌మైంది. అర్హులంద‌రూ త‌మ ఓటును త‌ప్ప‌కుండా న‌మోదు చేసుకోండి. ఓటు న‌మోదు చేసుకునేందుకు ఫామ్18, రెండు పాస్ పోర్టు సైజ్ ఫోటోల‌తో పాటు డిగ్రీ ప్రొవిజ‌న‌ల్ స‌ర్టిఫికేట్, మార్కుల మెమో, ఆధార్ కార్డు, ఓట‌రు ఐడీ కార్డు జిరాక్సుల‌పై గెజిటెడ్ అధికారి అటెస్టేష‌న్ చేయించి న‌వంబ‌రు 6వ తేదీలోగా స్థానిక ఎమ్మార్వో కార్యాల‌యంలో ద‌ర‌ఖాస్తు చేసుకోవాలి. \nద‌ర‌ఖాస్తున‌కు కావాల్సిన‌వి. \n1.ఫామ్ 18 \n2.డిగ్రీ ప్రొవిజ‌న‌ల్ స‌ర్టిఫికేట్ \n3.మార్కుల మెమో 4.ఆధార్ కార్డు \n5.ఓట‌రు ఐడీ కార్డు 6.రెండు పాస్ పోర్టు సైజ్ ఫోటోలు.",
      clickHereToApply: "అప్లై చేయడానికి ఇక్కడ క్లిక్ చేయండి.",
      coronaStats: "చేయండి కరోనా గురించి పూర్తి గణాంకాలు తెలుసుకోవడానికి",
      clickHere: "ఇక్కడ క్లిక్",
    },
  },
};

export const SuperAdminSideNavUrls = [
  {
    name: "Main News",
    url: "/admin/news/all",
  },
  {
    name: "Users",
    url: "/admin/users/news/all",
    links: [
      {
        name: "Registrations",
        url: "/admin/users/newusers",
      },
      {
        name: "Help Desk",
        url: "/admin/users/help/requests",
      },
    ],
  },
  {
    name: "E Paper",
    url: "/admin/epaper",
  },
  {
    name: "Live Push Notification",
    url: "/admin/live",
  },
  {
    name: "OTP Service",
    url: "/admin/otpservice",
  },
];

export const AdminSideNavUrls = [
  {
    name: "Main News",
    url: "/admin/news/all",
  },
  {
    name: "Users News",
    url: "/admin/users/news/all",
  },
  {
    name: "Live",
    url: "/admin/live",
  },
  {
    name: "Registrations",
    url: "/admin/registrations",
  },
  {
    name: "OTP Service",
    url: "/admin/otpservice",
  },
];

export default class StaticConsants {
  public static getURLsByUserType(userTye: number): any {
    if (userTye === UserType.Admin) {
      return AdminSideNavUrls;
    } else if (userTye === UserType.SuperAdmin) {
      return SuperAdminSideNavUrls;
    } else {
      return [];
    }
  }
}
