import * as React from "react";
import ListTemplate from './templates/ListTemplate/index';
import MainNews from './mainNews/index';
import ImageCard from "./templates/ImageCards";
import Privacy from "../Privacy";
import Navbar from "../Navbar/index";
import Secondnavbar from '../Navbar/Secondnavbar';
import ImageTextCard from "./templates/ImageTextCard";
import AddView from "./templates/ImageCards/AddView";
import EPaper from "../epaper";

class Home extends React.Component<{}, {}> {
    constructor(props: {}) {
        super(props);
        this.reRender = this.reRender.bind(this);
    }

    private reRender() {
        this.render();
    }

    public render(): JSX.Element {
        return (<>
            <Navbar afterLanguageChange={this.reRender} />
            {/* <Secondnavbar /> */}
            <div className="sp-container main-container" >
                <div className="ms-Grid" dir="ltr">
                    <div className="ms-Grid-row">
                        <EPaper />
                        {/* <div className="ms-Grid-col ms-sm12 ms-md8 ms-lg7-5 ">
                            <MainNews></MainNews>
                        </div>
                        <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4-5 ms-hiddenSm " >
                            <div className="sticky-right1 sp-scroll-vr" >
                                <AddView ></AddView>
                                <ListTemplate></ListTemplate>
                                <ImageTextCard />
                                <ImageCard></ImageCard>
                                <Privacy />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
        );
    }
}

export default Home;