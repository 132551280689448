import * as React from "react";
import NewsService from '../../../Service';
import './styles.scss';
import { NavLink } from "react-router-dom";
import { AppState } from "../../../../../Redux/app.store";
import { connect } from "react-redux";
import { IUserState } from "../../../../../Redux/models";
import Shimmer1 from "../../../../common/Loading/Shimmers";
import { URLs } from "../../../../../constants/constants";
const TextTruncate = require('react-text-truncate');

interface IHeadLine {
  _id: string;
  Title: string;
  Description: string;
}

interface IState {
  headLines: IHeadLine[];
  isLoading: boolean;
  language: string;
}

interface IProps {
  User: IUserState;
}

class ListTemplate extends React.Component<IProps, IState> {
  private newsService: NewsService;
  private language: string;
  currentLanguage = localStorage.getItem('language');
  constructor(props: IProps) {
    super(props);
    this.state = {
      headLines: [],
      isLoading: false,
      language: this.props.User.language
    }
    this.newsService = new NewsService();
    this.language = this.currentLanguage ? this.currentLanguage : 'en';
  }


  private getData() {
    this.setState({
      isLoading: true
    });
    this.newsService.getDataOrError(URLs.getHeadLines, true, false).then((res: any) => {
      if (res.status) {
        let data = res.data;
        this.setState({
          headLines: data,
          isLoading: false
        });
      } else {
        this.setState({
          isLoading: false
        });
      }
    });
  }

  componentDidMount() {
    this.getData();
  }

  componentWillReceiveProps(newProps: IProps) {
    if (this.language !== newProps.User.language) {
      this.language = newProps.User.language;
      this.getData();
    }
  }

  render(): JSX.Element {
    if (this.state.isLoading) {
      return <div className="sp-scroll-vr1" >
        <div>
          <p className="heading" >{this.props.User.staticConstants.Constants.headLines}</p>
        </div>
        <div className="shimmer-wrapper">
          <Shimmer1 />
          <hr className="sp-hr" />
          <Shimmer1 />
          <hr className="sp-hr" />
          <Shimmer1 />
        </div>
      </div>
    }

    return (<>
      {this.state.headLines && this.state.headLines.length > 0 &&
        <div className="sp-scroll-vr1" >
          <div>
            <h4 className="heading">{this.props.User.staticConstants.Constants.headLines}</h4>
          </div>
          <div className="sp-headline">
            {this.state.headLines.map((headLine: IHeadLine, index: number) => {
              return <div key={headLine._id}>
                <NavLink className="sp-f13" to={`/newsi/${headLine._id}`}>
                  <TextTruncate
                    line={1}
                    element="p"
                    truncateText="…"
                    text={headLine.Title}
                  />
                </NavLink>
                {index !== this.state.headLines.length - 1 && <hr />}
              </div>
            })}
          </div>
        </div>
      }
    </>
    );
  }
}


const mapStateToProps = (state: AppState): AppState => ({
  User: state.User,
});

export default connect(
  mapStateToProps,
)(ListTemplate);