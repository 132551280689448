import { IDatePickerStrings, DayOfWeek } from "office-ui-fabric-react/lib/DatePicker";

export interface IFieldValidityStatus {
    isValid: boolean;
    errorMsg: string;
    isRequired: boolean;
    type: string;
    minLength: number;
    maxLength: number;
    id: string;
    reset: boolean;
}

export interface IFormValidityStatus {
    isFormValid: boolean;
    fields: {
        [key: string]: IFieldValidityStatus;
    };
}

export enum FieldType {
    Text = "Text",
    Multi = "Multi",
    Email = "Email",
    Number = "Number",
    Choice = "Choice",
    Select = "Select",
    MultiSelect = "MultiSelect",
    CheckBox = "CheckBox",
    Toggle = "Toggle",
    DatePicker = "DatePicker",
    ReactQuill = "ReactQuill",
    DateTime = "DateTime",
    FileUpload = "FileUpload",
    FileUploadWithExport = "FileUploadWithExport",
    Urls = "Urls"
}

export const DayPickerStrings: IDatePickerStrings = {
    months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    goToToday: 'Go to today',
    prevMonthAriaLabel: 'Go to previous month',
    nextMonthAriaLabel: 'Go to next month',
    prevYearAriaLabel: 'Go to previous year',
    nextYearAriaLabel: 'Go to next year',
    closeButtonAriaLabel: 'Close date picker',
    isRequiredErrorMessage: 'Field is required.',
    invalidInputErrorMessage: 'Invalid date format.',
};

export const firstDayOfWeek = DayOfWeek.Sunday;