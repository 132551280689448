import * as React from "react";
import { Pivot, PivotItem } from 'office-ui-fabric-react/lib/Pivot';
import LiveStart from "./LiveStart";
import LiveMonitor from "./LiveMonitor";
import LivePushNotification from '../adminSection/LivePushNotification';
import LiveYoutubeMonitor from "./LiveYoutubeMonitor";

interface IState {
}

interface IProps {
}

class LiveSection extends React.PureComponent<IProps, IState> {

    render(): JSX.Element {
        return (
            <div className="sp-mt10">
                <Pivot aria-label="Basic Pivot Example" >
                    <PivotItem
                        headerText="Live Video"
                        headerButtonProps={{
                            'data-order': 1,
                            'data-title': 'My Files Title',
                        }}
                    >
                        <LivePushNotification />
                    </PivotItem>
                    <PivotItem
                        headerText="Monitor Requests"
                        headerButtonProps={{
                            'data-order': 2,
                            'data-title': 'My fge',
                        }}
                    >
                        <LiveYoutubeMonitor />
                    </PivotItem>
                </Pivot>
            </div>

        );
    }
}

export default LiveSection;