import * as React from "react";
import {
    RouteComponentProps, withRouter
} from 'react-router-dom';
import NewsService from '../../Service';
import NewsTD from '../../../common/newsTD';
import { INewsInfoC, Type, UserType } from '../../../../models/models';
import UserInfo from '../../../common/UserInfo';
import Comment from '../../../common/Comment';
import { AppState } from "../../../../Redux/app.store";
import { connect } from "react-redux";
import { IUserState } from "../../../../Redux/models";
import Polls from "../../../common/Polls";
import QA from "../../../common/QA";
import Sources from "../mainNews/Sources";
import Util from "../../../../util";
import { URLs } from "../../../../constants/constants";
import ShowMoreSP from "../../../common/ShowMoreSP";
import { DefaultButton } from "office-ui-fabric-react";
import EditNews from "../../../admin/adminSection/EditNews";

interface IState {
    viewCounted: boolean;
    showEditForm: boolean;
}

interface IProps extends RouteComponentProps {
    User: IUserState;
    news: INewsInfoC;
    hideUserInfo?: boolean;
}

class Analysis extends React.Component<IProps, IState> {
    private newsService: NewsService;
    private language: string;
    currentLanguage = localStorage.getItem('language');
    constructor(props: IProps) {
        super(props);
        this.state = {
            viewCounted: false,
            showEditForm: false
        }
        this.newsService = new NewsService();
        this.language = this.currentLanguage ? this.currentLanguage : 'en';
        this._onUserClick = this._onUserClick.bind(this);
        this._onScrollEvent = this._onScrollEvent.bind(this);
        this._showOrHideEditForm = this._showOrHideEditForm.bind(this);
    }

    private _onUserClick(id: string) {
        if (id !== null) {
            this.props.history.push(`/user/${id}`);
        }
    }

    _onScrollEvent() {
        const box = document.getElementById(this.props.news._id);
        if (box) {
            if (Util.isInViewport(box)) {
                if (!this.state.viewCounted) {
                    console.log(this.props.news._id);
                    this.newsService.postDataOrError(URLs.addView, {
                        RefId: this.props.news._id
                    }, true, false);
                    this.setState({
                        viewCounted: true
                    });
                }
            }
        }
    }


    private _showOrHideEditForm(value: boolean) {
        this.setState({
            showEditForm: value
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this._onScrollEvent);
    }

    componentDidUpdate() {
        window.addEventListener('scroll', this._onScrollEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this._onScrollEvent, { capture: false });
    }

    render(): JSX.Element {
        return (
            <div className="main-contai c-style1">
                {this.props.hideUserInfo === true ? <></> : < UserInfo DateTime={this.props.news.DateTime} User={this.props.news.User} onUserClick={this._onUserClick} ></UserInfo>}

                <div className={`sp-clearFix msg-body ${this.props.hideUserInfo === true ? 'sp-pt10' : ''}`} >
                    <NewsTD Title={this.props.news.Title} Description={this.props.news.Description} Source={this.props.news.Source} />
                    {this.props.news.Files.length > 0 && <Sources Sources={this.props.news.Files} />}
                </div>
                <div className="analaysis">
                    {this.props.news.Analysis1 !== null && this.props.news.Analysis1 !== undefined && this.props.news.Analysis1 !== '' &&
                        <>
                            <p className="an-heading">{this.props.User.staticConstants.Constants.analysis1}:</p>
                            <div className="an-text">
                                <ShowMoreSP
                                    text={this.props.news.Analysis1}
                                    more={this.props.User.staticConstants.Constants.readMore}
                                    less={this.props.User.staticConstants.Constants.readLess}
                                    lines={1}
                                />
                            </div>
                        </>}
                    {this.props.news.Analysis2 !== null && this.props.news.Analysis2 !== undefined && this.props.news.Analysis2 !== '' &&
                        <>
                            <p className="an-heading">{this.props.User.staticConstants.Constants.analysis2}:</p>
                            <div className="an-text">
                                <ShowMoreSP
                                    text={this.props.news.Analysis2}
                                    more={this.props.User.staticConstants.Constants.readMore}
                                    less={this.props.User.staticConstants.Constants.readLess}
                                    lines={1}
                                />
                            </div>
                        </>}
                    {this.props.news.Analysis3 !== null && this.props.news.Analysis3 !== undefined && this.props.news.Analysis3 !== '' &&
                        <>
                            <p className="an-heading">{this.props.User.staticConstants.Constants.analysis3}:</p>
                            <div className="an-text">
                                <ShowMoreSP
                                    text={this.props.news.Analysis3}
                                    more={this.props.User.staticConstants.Constants.readMore}
                                    less={this.props.User.staticConstants.Constants.readLess}
                                    lines={1}
                                />
                            </div>
                        </>}
                </div>
                {this.props.news.Type != Type.None &&
                    <hr className="sp-hr" />
                }
                <div className="sp-clearFix"></div>
                <div className="sp-p5">
                    {this.props.User.User && (this.props.User.User.userType === UserType.Admin || this.props.User.User.userType === UserType.SuperAdmin) &&
                        <span className="sp-views">{this.props.news.Views} Views</span>
                    }
                    {
                        this.props.User.User && (this.props.User.User.userType == UserType.Admin || this.props.User.User.userType == UserType.SuperAdmin) &&
                        <DefaultButton className="" onClick={() => this._showOrHideEditForm(true)} text="Edit" />
                    }
                    {
                        this.state.showEditForm && <EditNews language={this.props.User.language} newsId={this.props.news._id} canceEdit={() => this._showOrHideEditForm(false)} />
                    }
                    {
                        this.props.news.Type === Type.Comments && <>
                            <Comment RefId={this.props.news._id} />
                        </>
                    }
                    {
                        this.props.news.Type === Type.Polls && <>
                            <Polls RefId={this.props.news._id} />
                        </>
                    }
                    {
                        this.props.news.Type === Type.Questions && <>
                            <QA RefId={this.props.news._id} />
                        </>
                    }
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default withRouter(connect(
    mapStateToProps,
)(Analysis));