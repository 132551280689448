import * as React from "react";
import {  ILive, IPageInfo } from "../../../models/models";
import NewsService from "../service";
import { URLs } from "../../../constants/constants";
import MaterialTable from "material-table";
import Loading from "../../common/Loading";


interface IState {
    liveInfo: any[]
    pageInfo: IPageInfo;
    selectedItem: any | null;
    isLoading: boolean;
}

interface IProps {
}

class LiveYoutubeMonitor extends React.PureComponent<IProps, IState> {
    private service: NewsService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            liveInfo: [],
            pageInfo: { currentPage: 0, totalPages: 0 },
            selectedItem: null,
            isLoading: false
        }

        this.service = new NewsService();
        this._onItemSelected = this._onItemSelected.bind(this);
        this.startCompStreams = this.startCompStreams.bind(this);
    }


    private _onItemSelected(event: any, data: any) {
        let updateObj = { _id: data._id, updateObj: { liveStatus: 2 } }
        this.setState({ isLoading: true });
        this.service.postDataOrLogout(URLs.updateLiveForMobile, updateObj, true).then((res: any) => {
            if (res.status === true) {
                let temp: ILive[] = [];
                this.state.liveInfo.forEach((news: ILive) => {
                    if (news._id === data._id) {
                        news.liveStatus = 2;
                        temp = [...temp, news];
                    } else {
                        temp = [...temp, news];
                    }
                });
                this.setState({
                    liveInfo: temp,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        });

    }

    private getData() {
        this.service.getDataOrLogout(URLs.getAllLiveInfoForMonitor, true).then((res: any) => {
            if (res.status) {
                let data = res.data;
                if (data.length > 0) {
                    this.setState({
                        liveInfo: res.data
                    });
                }
            }
        });
    }



    private startCompStreams() {
        this.setState({ isLoading: true });
        this.service.getDataOrError(URLs.getAllLiveInfoForMonitor, true).then((res: any) => {
            if (res.status === true) {
                this.setState({
                    isLoading: false,
                });
            } else {
                this.setState({
                    isLoading: false,
                });
            }
        });
    }

    componentDidMount() {
        this.getData();

    }


    render(): JSX.Element {
        return (
            <>
                {this.state.isLoading && <Loading />}
                <div className="material-table">
                    <div className="sp-clearFix"></div>
                    <MaterialTable
                        style={{ marginTop: "5px", padding: "5px", boxShadow: "none" }}
                        title="New posts"
                        columns={[
                            { title: 'Title', field: 'title', tooltip: 'Title' },
                            { title: 'Status', field: 'liveStatus' }
                        ]}
                        data={this.state.liveInfo}
                        actions={[
                            {
                                icon: 'highlight_off',
                                tooltip: 'Stop Live',
                                onClick: this._onItemSelected
                            }
                        ]}
                        options={{
                            rowStyle: rowData => ({
                                backgroundColor: (this.state.selectedItem && this.state.selectedItem._id === rowData._id) ? '#EEE' : '#FFF'
                            })
                        }}
                    ></MaterialTable>
                </div>

            </>
        );
    }
}

export default LiveYoutubeMonitor;