import * as React from "react";
import MaterialTable from 'material-table';
import { ISavedNews, AccountStatus, UserType, IPageInfo } from '../../../models/models';
import NewsService from '../service';
import Service from '../service';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import Loading from "../../common/Loading";
import { Dropdown, IDropdownOption } from 'office-ui-fabric-react/lib/Dropdown';
import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { TextField } from "office-ui-fabric-react";
import { URLs, commentOptions } from "../../../constants/constants";
import Util from "../../../util";
import { AppState } from "../../../Redux/app.store";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IUserState } from "../../../Redux/models";
import { baseUrl } from "../../../services/services";

export interface IUserStatusCount {
    Normal: number,
    SelftAdmin: number;
    Admin: number;
    SuperAdmin: number;
    All: number;
}

interface IState {
    allNews: any[];
    allNewsTemp: any[];
    isLoading: boolean;
    selectedItem: any;
    showDialog: boolean;
    statusMessage: string;
    updateField: string;
    newsCount: any;
    statusMessageErr: string;
    status: any;
    isShowCompleteInfo: boolean;
    UsersInfo: any;
    currentFilter: number;
    newsCountInfo: IUserStatusCount;
    skipIndex: number;
    pageInfo: IPageInfo;
    searchStatusMessage: string;
    currentRecords: number;
}

interface IProps {
    User: IUserState;
}

class AllUsers extends React.PureComponent<IProps, IState> {
    private newsService: NewsService;
    private service: Service;
    constructor(props: IProps) {
        super(props);
        this.state = {
            allNews: [],
            allNewsTemp: [],
            isLoading: false,
            selectedItem: null,
            showDialog: false,
            statusMessage: '',
            statusMessageErr: '',
            status: null,
            newsCount: null,
            updateField: '',
            isShowCompleteInfo: false,
            UsersInfo: null,
            currentFilter: 0,
            skipIndex: 0,
            newsCountInfo: { Normal: 0, SelftAdmin: 0, Admin: 0, SuperAdmin: 0, All: 0 },
            pageInfo: { currentPage: 0, totalPages: 0 },
            searchStatusMessage: '',
            currentRecords: 0

        }
        this.newsService = new NewsService();
        this.service = new Service();
        this.OnItemSelected = this.OnItemSelected.bind(this);
        this._closeDialog = this._closeDialog.bind(this);
        this.submit = this.submit.bind(this);
        this.updateUser = this.updateUser.bind(this);
        this.toggleShowCompleteInfo = this.toggleShowCompleteInfo.bind(this);
        this.reloadData = this.reloadData.bind(this);
        this.commentChangeHandle = this.commentChangeHandle.bind(this);
    }



    private getData() {
        this.setState({ isLoading: true });
        var promise: Promise<any>[] = [];
        promise.push(
            this.newsService.getDataOrLogout(URLs.getUsersCountByUserType, true).then((res: any) => {
                if (res.status) {
                    let newsCountInfo: any = {};
                    if (res.data && res.data.length > 0) {
                        res.data.forEach((c: any) => {
                            if (c._id === 1) {
                                newsCountInfo['Normal'] = c.count;
                            } else if (c._id === 2) {
                                newsCountInfo['SelftAdmin'] = c.count;
                            } else if (c._id === 3) {
                                newsCountInfo['Admin'] = c.count;
                            } else if (c._id === 4) {
                                newsCountInfo['SuperAdmin'] = c.count;
                            }
                        });
                    }
                    if (newsCountInfo.Normal === undefined) {
                        newsCountInfo.Normal = 0;
                    }
                    if (newsCountInfo.SelftAdmin === undefined) {
                        newsCountInfo.SelftAdmin = 0;
                    }
                    if (newsCountInfo.Admin === undefined) {
                        newsCountInfo.Admin = 0;
                    }
                    if (newsCountInfo.SuperAdmin === undefined) {
                        newsCountInfo.SuperAdmin = 0;
                    }
                    newsCountInfo['All'] = newsCountInfo.Normal + newsCountInfo.SelftAdmin + newsCountInfo.Admin + newsCountInfo.SuperAdmin;

                    this.setState({
                        newsCountInfo: newsCountInfo,
                    });

                } else {
                    this.setState({ isLoading: false });
                }
            })
        );
        promise.push(this.newsService.postDataOrLogout(URLs.getAllUsersByFilter3, { skip: 0 }, true).then((res: any) => {
            if (res.status) {
                let data: ISavedNews[] = res.data;
                this.setState({
                    allNews: data,
                    skipIndex: this.state.skipIndex + data.length,
                    pageInfo: Util.getPageInfo("", this.state.newsCountInfo.All, data.length),
                    currentRecords: data.length
                });
            } else {
                this.setState({
                    isLoading: false
                });
            }
        }));

        return Promise.all(promise).then(() => {
            this.setState({
                isLoading: false
            });
            return;
        });

    }

    componentDidMount() {
        this.getData();
    }

    private toggleShowCompleteInfo() {
        this.setState((prevState: IState) => {
            return { isShowCompleteInfo: !prevState.isShowCompleteInfo }
        });
    }

    private OnItemSelected(event: any, data: any) {
        // this.setState({
        //     selectedItem: data

        // });
        this.setState({
            isLoading: true
        });
        this.newsService.postDataOrLogout(URLs.getUserDetailsByIdAndNewsCount, { id: data._id }, true).then((res: any) => {
            if (res.status) {
                let data = res.data;
                this.setState({
                    selectedItem: data.userInfo,
                    newsCount: data.count,
                    isLoading: false
                });
            } else {
                this.setState({
                    isLoading: false
                });
            }
        });
    }
    public updateUser(field: string, status: number | string) {
        this.setState({
            updateField: field,
            status: status,
            showDialog: true
        });
    }

    private fetchNextOrPrevisousRecords(where: string) {
        this.setState({
            isLoading: true
        });
        if (where === 'previous') {
            let filter: any = {};
            filter.skip = this.state.skipIndex - 500 - this.state.currentRecords;
            filter.field = "userType";
            if (this.state.currentFilter === UserType.Normal) {
                filter.value = UserType.Normal;
            } else if (this.state.currentFilter === UserType.SelftAdmin) {
                filter.value = UserType.SelftAdmin;
            } else if (this.state.currentFilter === UserType.Admin) {
                filter.value = UserType.Admin;
            } else if (this.state.currentFilter === UserType.SuperAdmin) {
                filter.value = UserType.SuperAdmin;
            }


            this.newsService.postDataOrLogout(URLs.getAllUsersByFilter3, filter, true).then((res: any) => {
                if (res.status) {
                    let data: ISavedNews[] = res.data;
                    this.setState({
                        allNews: data,
                        isLoading: false,
                        skipIndex: this.state.skipIndex - 500 - this.state.currentRecords + data.length,
                        pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, "", this.state.newsCountInfo.All, this.state.skipIndex - 500),
                        currentRecords: data.length
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        } else {
            let filter: any = {};
            filter.skip = this.state.skipIndex;
            filter.field = "userType";
            if (this.state.currentFilter === UserType.Normal) {
                filter.value = UserType.Normal;
            } else if (this.state.currentFilter === UserType.SelftAdmin) {
                filter.value = UserType.SelftAdmin;
            } else if (this.state.currentFilter === UserType.Admin) {
                filter.value = UserType.Admin;
            } else if (this.state.currentFilter === UserType.SuperAdmin) {
                filter.value = UserType.SuperAdmin;
            }

            this.newsService.postDataOrLogout(URLs.getAllUsersByFilter3, filter, true).then((res: any) => {
                if (res.status) {
                    let data: ISavedNews[] = res.data;
                    this.setState({
                        allNews: data,
                        isLoading: false,
                        skipIndex: this.state.skipIndex + data.length,
                        currentRecords: data.length,
                        pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, "", this.state.newsCountInfo.All, this.state.skipIndex + data.length)
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        }
    }

    private submit() {
        if (this.state.statusMessage !== '') {
            this.setState({
                isLoading: true
            });
            this.service.postDataOrLogout(URLs.updateProfile, {
                field: this.state.updateField,
                data: { id: this.state.selectedItem._id, status: this.state.status, statusMsg: this.state.statusMessage }
            }, true).then((res: any) => {
                if (res.status) {
                    let d: any[] = [];
                    if (this.state.updateField === "userType") {
                        this.state.allNews.forEach((news: any) => {
                            if (news._id !== this.state.selectedItem._id) {
                                d = [...d, news]
                            }
                        });
                        let newsCountInfo = this.state.newsCountInfo;
                        if (this.state.selectedItem.userType === 1) {
                            if (this.state.status === UserType.SelftAdmin) {
                                newsCountInfo.Normal = this.state.newsCountInfo.Normal - 1;
                                newsCountInfo.SelftAdmin = this.state.newsCountInfo.SelftAdmin + 1;
                            } else if (this.state.status === UserType.Admin) {
                                newsCountInfo.Normal = this.state.newsCountInfo.Normal - 1;
                                newsCountInfo.Admin = this.state.newsCountInfo.Admin + 1;
                            } if (this.state.status === UserType.SuperAdmin) {
                                newsCountInfo.Normal = this.state.newsCountInfo.Normal - 1;
                                newsCountInfo.SuperAdmin = this.state.newsCountInfo.SuperAdmin + 1;
                            }
                        } else if (this.state.selectedItem.userType === 2) {
                            if (this.state.status === UserType.Admin) {
                                newsCountInfo.SelftAdmin = this.state.newsCountInfo.SelftAdmin - 1;
                                newsCountInfo.Admin = this.state.newsCountInfo.Admin + 1;
                            }
                        } else if (this.state.selectedItem.userType === 3) {
                            if (this.state.status === UserType.SelftAdmin) {
                                newsCountInfo.Admin = this.state.newsCountInfo.Admin - 1;
                                newsCountInfo.SelftAdmin = this.state.newsCountInfo.SelftAdmin + 1;
                            }
                        } else if (this.state.selectedItem.userType === 4) {
                            if (this.state.status === UserType.SelftAdmin) {
                                newsCountInfo.SuperAdmin = this.state.newsCountInfo.SuperAdmin - 1;
                                newsCountInfo.SelftAdmin = this.state.newsCountInfo.SelftAdmin + 1;

                            }
                        }
                        this.setState({
                            allNews: d,
                            newsCountInfo: newsCountInfo,
                            selectedItem: null,
                            showDialog: false,
                            status: null,
                            statusMessage: '',
                            updateField: '',
                            isLoading: false
                        });
                    } else {
                        d = this.state.allNews.filter((news: any) => {
                            if (news._id === this.state.selectedItem._id) {
                                news.userType = this.state.status;
                                return news;
                            } else {
                                return news;
                            }
                        });
                        this.setState({
                            allNews: d,
                            selectedItem: null,
                            showDialog: false,
                            status: null,
                            statusMessage: '',
                            updateField: '',
                            isLoading: false
                        });
                    }
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        } else {
            this.setState({
                statusMessageErr: 'Please select Comment.'
            });
        }
    }

    private _closeDialog() {
        this.setState({
            statusMessage: '',
            showDialog: false,
        });
    }

    private commentChangeHandle(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption,) {
        if (option) {
            this.setState({
                statusMessage: option.key as string
            });
        }
    }

    private onStatusFilterChange2(option: number) {
        if (option !== this.state.currentFilter) {
            this.setState({
                isLoading: true,
                selectedItem: null,
            });

            if (option === 0) {
                this.newsService.postDataOrLogout(URLs.getAllUsersByFilter3, { skip: 0 }, true).then((res: any) => {
                    if (res.status) {
                        let data: ISavedNews[] = res.data;
                        this.setState({
                            allNews: data,
                            isLoading: false,
                            skipIndex: data.length,
                            pageInfo: Util.getPageInfo("", this.state.newsCountInfo.All, data.length),
                            currentRecords: data.length,
                            currentFilter: option,
                            isShowCompleteInfo: true
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                });
            } else {
                this.newsService.postDataOrLogout(URLs.getAllUsersByFilter3, { skip: 0, field: 'userType', value: option }, true).then((res: any) => {
                    if (res.status) {
                        let data: ISavedNews[] = res.data;
                        this.setState({
                            allNews: data,
                            isLoading: false,
                            skipIndex: data.length,
                            pageInfo: Util.getPageInfo("option", this.state.newsCountInfo.Normal, data.length),
                            currentRecords: data.length,
                            currentFilter: option,
                            isShowCompleteInfo: true
                        });
                    } else {
                        this.setState({
                            isLoading: false
                        });
                    }
                });
            }
        }
        else {
            this.setState({
                isShowCompleteInfo: true
            });
        }
    }

    private reloadData() {
        this.getData();
    }

    private _onSearch(event: any) {
        this.setState({
            isLoading: true,
        });
        this.newsService.postDataOrLogout(URLs.getAllUsersBySearchForAdmin, { search: event.target.value }, true).then((res: any) => {
            if (res.status) {
                if (res.data.length > 0) {
                    this.setState({
                        allNews: res.data,
                        searchStatusMessage: `${res.data.length} records found.`,
                        isLoading: false
                    });
                }
                else {
                    this.setState({
                        searchStatusMessage: `No records found.`,
                        allNews: [],
                        isLoading: false
                    });
                }
            } else {
                this.setState({
                    searchStatusMessage: `some error occured`,
                    allNews: [],
                    isLoading: false
                });
            }
        });
    }


    render(): JSX.Element {
        const { allNews, isLoading } = this.state;
        return (
            <div>
                {isLoading && <Loading />}
                {this.state.newsCountInfo &&
                    <div className="news-dashbord">
                        <span className="Title"> Users Information</span>
                        <DefaultButton className="sp-btn-m sp-btn-black sp-float-right" onClick={this.reloadData} text="Reload" />
                        <div className="sp-clearFix">
                        </div>
                        <hr className="sp-hr" />
                        <div className="ms-Grid upload" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col sp-sm20 ">
                                    <div className="item-c">
                                        <p className="item-title">Total</p>
                                        <i className="ms-Icon ms-Icon--Album sp-icon1 sp-blue sp-float-left" onClick={() => this.onStatusFilterChange2(0)} aria-hidden="true"></i>

                                        <p className="item-count">
                                            {this.state.newsCountInfo.All}
                                        </p>
                                    </div>
                                </div>
                                <div className="ms-Grid-col sp-sm20 ">
                                    <div className="item-c">
                                        <p className="item-title">Normal</p>
                                        <i className="ms-Icon ms-Icon--StatusCircleQuestionMark sp-icon1 sp-yellow sp-float-left" onClick={() => this.onStatusFilterChange2(1)} aria-hidden="true"></i>
                                        <p className="item-count">
                                            {this.state.newsCountInfo.Normal}
                                        </p>
                                    </div>
                                </div>
                                <div className="ms-Grid-col sp-sm20 ">
                                    <div className="item-c">
                                        <p className="item-title">Reporters</p>
                                        <i className="ms-Icon ms-Icon--Like sp-icon1 sp-green sp-float-left" onClick={() => this.onStatusFilterChange2(2)} aria-hidden="true"></i>
                                        <p className="item-count">
                                            {this.state.newsCountInfo.SelftAdmin}
                                        </p>
                                    </div>
                                </div>
                                <div className="ms-Grid-col sp-sm20 ">
                                    <div className="item-c">
                                        <p className="item-title">Admin</p>
                                        <i className="ms-Icon ms-Icon--StatusCircleBlock sp-icon1 sp-orange sp-float-left" onClick={() => this.onStatusFilterChange2(3)} aria-hidden="true"></i>
                                        <p className="item-count">
                                            {this.state.newsCountInfo.Admin}
                                        </p>
                                    </div>
                                </div>
                                <div className="ms-Grid-col sp-sm20 ">
                                    <div className="item-c">
                                        <p className="item-title">Super Admin</p>
                                        <i className="ms-Icon ms-Icon--Dislike sp-icon1 sp-red sp-float-left" onClick={() => this.onStatusFilterChange2(4)} aria-hidden="true"></i>
                                        <p className="item-count">
                                            {this.state.newsCountInfo.SuperAdmin}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="sp-hr sp-mt30" />
                        <div className="ms-Grid" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3">
                                    <TextField label="Search" placeholder="Type something.." onChange={(event: any) => this._onSearch(event)} />
                                </div>
                                <div className="ms-Grid-col ms-sm3 ms-md3 ms-lg3" style={{ paddingTop: "35px" }}>
                                    <p>{this.state.searchStatusMessage}</p>
                                </div>
                            </div>
                        </div>
                        <hr className="sp-hr sp-mt30" />

                        <DefaultButton className="sp-btn-m view-all-button" onClick={this.toggleShowCompleteInfo} text={this.state.isShowCompleteInfo ? "Hide Complete Details" : "View Complete Details"} />
                        {this.state.isShowCompleteInfo && <>
                            <div className="material-table">
                                <div className="pagination">
                                    <span className={`nav-btn ${this.state.pageInfo.currentPage === 1 ? 'sp-hide' : ''}`} onClick={() => this.fetchNextOrPrevisousRecords('previous')} title="Previous Page" >&laquo;</span>
                                    <span title="Current Page"  >{`Page ${this.state.pageInfo.currentPage} of ${this.state.pageInfo.totalPages}`} </span>
                                    <span className={`nav-btn ${this.state.pageInfo.currentPage === this.state.pageInfo.totalPages ? 'sp-hide' : ''}`} onClick={() => this.fetchNextOrPrevisousRecords('next')} title="Next Page" >&raquo;</span>
                                </div>
                                <div className="sp-clearFix"></div>
                                <MaterialTable
                                    title="All Users"
                                    style={{ marginTop: "5px", padding: "5px", boxShadow: "none" }}

                                    columns={[
                                        { title: 'First Name', field: 'firstName', tooltip: 'F Name' },
                                        { title: 'Last Name', field: 'lastName', tooltip: 'L Name' },
                                        { title: 'Phone Number', field: 'phoneNumber' },
                                        { title: 'City', field: 'city' },
                                        { title: 'State', field: 'state' },
                                        { title: 'Gender', field: 'gender' }
                                    ]}
                                    data={allNews}
                                    actions={[
                                        {
                                            icon: 'save',
                                            tooltip: 'Save User',
                                            onClick: this.OnItemSelected
                                        }
                                    ]}
                                    onFilterChange={() => { alert('d') }}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (this.state.selectedItem && this.state.selectedItem._id === rowData._id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                ></MaterialTable>
                            </div>

                            <div>
                                {this.state.selectedItem &&
                                    <div>
                                        <div className="ms-Grid" >
                                            <div className="ms-Grid-row user-profile3">
                                                <div className="ms-Grid-col ms-sm4">
                                                    <div className="">
                                                        <p className="profile-name sp-no-pm ms-fontSize-28 sp-mt10 capitalize">{` ${this.state.selectedItem.firstName} ${this.state.selectedItem.lastName} `}</p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 sp-mt10" ><i className="ms-Icon ms-Icon--MapPin" aria-hidden="true"></i> {`${this.state.selectedItem.city}, ${this.state.selectedItem.state}`} </p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18" ><i className="ms-Icon ms-Icon--Mail" aria-hidden="true"></i> {`${this.state.selectedItem.email}`} </p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 capitalize" ><i className="ms-Icon ms-Icon--Contact" aria-hidden="true"></i> {`${this.state.selectedItem.gender}`} </p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 capitalize" ><i className="ms-Icon ms-Icon--Contact" aria-hidden="true"></i> {`${this.state.selectedItem.UId}`} </p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 sp-mt10"><i className="ms-Icon ms-Icon--Telemarketer" aria-hidden="true"></i> +91 {this.state.selectedItem.phoneNumber}</p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 sp-mt10"><i className="ms-Icon ms-Icon--Telemarketer" aria-hidden="true"></i>{this.state.selectedItem.userType}</p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 sp-mt10"><i className="ms-Icon ms-Icon--Telemarketer" aria-hidden="true"></i>Total Posts : {this.state.newsCount}</p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 sp-mt10">User Type:   {this.state.selectedItem.userType === UserType.Normal ? 'New User' : this.state.selectedItem.userType === UserType.SelftAdmin ? 'Active User' : this.state.selectedItem.userType === UserType.Admin ? 'Admin User' : 'SuperAdmin User'}</p>
                                                        <p className="sp-no-pm sp-n ms-fontSize-18 sp-mt10">User Privilige:   {this.state.selectedItem.userType === 1 ? 'Normal User' : this.state.selectedItem.userType === 3 ? 'Admin' : this.state.selectedItem.userType === 2 ? 'Reporter' : 'Super Admin'}</p>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-col ms-sm4">
                                                    <img src={`${baseUrl}${this.state.selectedItem.imagePath}`} className="avatar-3" alt="d" />
                                                </div>
                                            </div>
                                        </div>
                                        <hr className="" />
                                        {(this.props.User.User && this.props.User.User.userType == UserType.SuperAdmin) &&
                                            <div className="action-buttons">
                                                {(this.state.selectedItem.accountStatus === AccountStatus.Registered) &&
                                                    <DefaultButton className="sp-btn-m sp-green" onClick={() => this.updateUser('accountStatus', AccountStatus.Verified)} text="Approve" />}
                                                {(this.state.selectedItem.accountStatus === AccountStatus.Registered) &&
                                                    <DefaultButton className="sp-btn-m sp-red sp-ml10" onClick={() => this.updateUser('accountStatus', AccountStatus.Rejected)} text="Reject" />}
                                                {(this.state.selectedItem.accountStatus === AccountStatus.Verified) &&
                                                    <DefaultButton className="sp-btn-m sp-red sp-ml10" onClick={() => this.updateUser('accountStatus', AccountStatus.Blocked)} text="Block" />}
                                                {(this.state.selectedItem.accountStatus === AccountStatus.Blocked) &&
                                                    <DefaultButton className="sp-btn-m sp-green sp-ml10" onClick={() => this.updateUser('accountStatus', AccountStatus.Verified)} text="Unblock" />}
                                                {(this.state.selectedItem.accountStatus === AccountStatus.Rejected) &&
                                                    <DefaultButton className="sp-btn-m sp-green sp-ml10" onClick={() => this.updateUser('accountStatus', AccountStatus.Verified)} text="Approve" />}
                                                {(this.state.selectedItem.accountStatus === AccountStatus.Verified) && <>
                                                    {(this.state.selectedItem.userType !== UserType.Normal) &&
                                                        <DefaultButton className="sp-btn-m sp-black sp-ml10" onClick={() => this.updateUser('userType', UserType.Normal)} text="Make as Normal User" />}
                                                    {(this.state.selectedItem.userType !== UserType.Admin) &&
                                                        <DefaultButton className="sp-btn-m sp-yellow sp-ml10" onClick={() => this.updateUser('userType', UserType.Admin)} text="Make as Admin" />}
                                                    {(this.state.selectedItem.userType !== UserType.SuperAdmin) &&
                                                        <DefaultButton className="sp-btn-m sp-green sp-ml10" onClick={() => this.updateUser('userType', UserType.SuperAdmin)} text="Make as Super Admin" />}
                                                </>
                                                }
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </>}
                    </div>
                }

                <Dialog
                    hidden={!this.state.showDialog}
                    onDismiss={this._closeDialog}
                    modalProps={{
                        styles: { main: { maxWidth: 450 }, },
                        isBlocking: true
                    }}
                >
                    <Dropdown
                        label="Comment"
                        options={commentOptions}
                        errorMessage={this.state.statusMessageErr}
                        selectedKey={this.state.statusMessage}
                        onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => this.commentChangeHandle(event, option)} required
                    />
                    <DialogFooter>
                        <DefaultButton onClick={this.submit} className="sp-main-btn float-right btn-login" text="Ok" />
                        <DefaultButton onClick={this._closeDialog} className="float-right btn-login" text="Cancel" />
                    </DialogFooter>
                </Dialog>
            </div>
        );
    }
}

//export default AllUsers;



const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default withRouter(connect(
    mapStateToProps,
)(AllUsers));