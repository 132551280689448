import * as React from "react";
import './styles.scss';
import FormField from "./FormField";
import { IFormValidityStatus, FieldType } from "./Models";
import { DefaultButton, IChoiceGroupOption, IDropdownOption, PrimaryButton, Toggle, DialogFooter, TextField, Dialog, DialogType, Dropdown } from "office-ui-fabric-react";
import Util from "../../../util";
import FileUpload from "../fileUpload";
import ProgressBar from "react-bootstrap/ProgressBar";
import { IExportTemplate, IDialogPropss, FileType, IAddFile, AddFile, IFileT, FileT } from "../../../models/models";
import NewsService from "../../admin/service";
import { FileTypes2, URLs } from "../../../constants/constants";
import Loading from "../Loading";
import * as _ from 'lodash';
import FileUploadBindingInfo from "../FilesUploadedBindingInfo";
import Common from "..";

interface IState {
    data: any;
    formValidations: IFormValidityStatus;
    formFields: any;
    exportTemplate: IExportTemplate;
    showExportModal: boolean;
    DialogProps: IDialogPropss;
    isLoading: boolean;
    fileIndex: number;
    reset: boolean;

}

interface IProps {
    formFields: any;
    reset: boolean;

    onSubmit: (data: any) => void;
}

class FormSP extends React.Component<IProps, IState> {
    private service: NewsService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: null,
            formValidations: { isFormValid: true, fields: {} },
            formFields: this.props.formFields,
            exportTemplate: { path: '', scrollableText: '', fileId: '', mimeType: '', isReExport: false, type: '' },
            showExportModal: false,
            DialogProps: { show: false, message: '' },
            isLoading: false,
            fileIndex: 1,
            reset: this.props.reset
        }

        this.onFieldChange = this.onFieldChange.bind(this);
        this.onEmailChange = this.onEmailChange.bind(this);
        this.onNumberChange = this.onNumberChange.bind(this);
        this.onChoiceChange = this.onChoiceChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onMultiSelectChange = this.onMultiSelectChange.bind(this);
        this.onCheckBoxChange = this.onCheckBoxChange.bind(this);
        this.onToggleChange = this.onToggleChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onProgress = this.onProgress.bind(this);
        this._onProgress = this._onProgress.bind(this);
        this.onFilsUploadError = this.onFilsUploadError.bind(this);
        this._onFilsUploadError = this._onFilsUploadError.bind(this);
        this.afterFilesUploaded = this.afterFilesUploaded.bind(this);
        this._afterFilesUploaded = this._afterFilesUploaded.bind(this);
        this.removeFile = this.removeFile.bind(this);
        this.onOriginalOrExportedToggleChangeUpload = this.onOriginalOrExportedToggleChangeUpload.bind(this);
        this.showExportModel = this.showExportModel.bind(this);
        this.hideExportModel = this.hideExportModel.bind(this);
        this.scrollTextHandle = this.scrollTextHandle.bind(this);
        this.onReExportToggleChange = this.onReExportToggleChange.bind(this);
        this.startExport = this.startExport.bind(this);
        this.addFile = this.addFile.bind(this);
        this.removeF = this.removeF.bind(this);
        this.textChangeHandle = this.textChangeHandle.bind(this);
        this.dropDownChangeHandle = this.dropDownChangeHandle.bind(this);
        this._removefile = this._removefile.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.service = new NewsService();

    }

    componentDidMount() {
        let data: any = {};
        let fields: any = {};
        this.state.formFields.map((field: any) => {
            if (field.Type === FieldType.Text) {
                data[field.FieldName] = '';
            } else if (field.Type === FieldType.Multi) {
                data[field.FieldName] = '';
            } else if (field.Type === FieldType.Email) {
                data[field.FieldName] = '';
            } else if (field.Type === FieldType.Number) {
                data[field.FieldName] = '';
            } else if (field.Type === FieldType.Choice) {
                data[field.FieldName] = null;
            } else if (field.Type === FieldType.Select) {
                data[field.FieldName] = null;
            } else if (field.Type === FieldType.MultiSelect) {
                data[field.FieldName] = [];
            } else if (field.Type === FieldType.CheckBox) {
                data[field.FieldName] = false;
            } else if (field.Type === FieldType.Toggle) {
                data[field.FieldName] = false;
            } else if (field.Type === FieldType.FileUploadWithExport || field.Type === FieldType.FileUpload || field.Type === FieldType.Urls) {
                data[field.FieldName] = [];
            } else {
                data[field.FieldName] = null;
            }
            fields[field.FieldName] = { isValid: false, errorMsg: '', isRequired: field.Required, type: field.Type, minLength: field.MinLength, maxLength: field.MaxLength, id: field.UniqueId, reset: false };
        });

        this.setState((prevState: IState) => {
            return {
                data,
                formValidations: { ...prevState.formValidations, fields }
            }
        });
    }


    private onFieldChange(field: string, value: any) {
        let data: any = JSON.parse(JSON.stringify(this.state.data));
        let formValidations = this.state.formValidations;
        data[field] = value;
        if (formValidations.fields[field].isRequired) {
            if (data[field] == '' || data[field] == null) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${field} is required.` }
                formValidations.isFormValid = false;
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
        }
        this.setState({
            data,
            formValidations
        });
    }

    private onEmailChange = (field: string, value: any): void => {
        let data: any = JSON.parse(JSON.stringify(this.state.data));
        let formValidations = this.state.formValidations;
        data[field] = value;
        if ((data[field] == '' || data[field] == null) && formValidations.fields[field].isRequired) {
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${field} is required.` }
            formValidations.isFormValid = false;
        } else if ((data[field] != '' && data[field] != null) && !Util.isValidEmail(value)) {
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${field} is invalid.` }
            formValidations.isFormValid = false;
        }
        else {
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
        }
        this.setState({
            data,
            formValidations
        });
    }

    private onNumberChange(field: string, event: React.ChangeEvent<HTMLInputElement>) {
        let data: any = JSON.parse(JSON.stringify(this.state.data));
        let formValidations = this.state.formValidations;

        if (event.target.value === "") {
            data[field] = event.target.value;
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: formValidations.fields[field].isRequired ? false : true, errorMsg: `${formValidations.fields[field].isRequired ? `${field} is required.` : ''}` }
        } else if (event.target.value != "" && event.target.validity.valid) {
            data[field] = event.target.value;
            if (event.target.value.length < formValidations.fields[field].minLength) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `Min 10 characters required` }
            } else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }
            }

        } else if (!event.target.validity.valid) {
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `Only numbers allowed.` }
        }

        this.setState({
            data,
            formValidations
        });
    }

    onChoiceChange(field: string, option?: IChoiceGroupOption | undefined) {
        if (option) {
            let data: any = JSON.parse(JSON.stringify(this.state.data));
            let formValidations = this.state.formValidations;
            data[field] = option.key;
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }
            this.setState({
                data,
                formValidations
            });
        }

    }

    onSelectChange(field: string, option?: IDropdownOption) {
        if (option) {
            let data: any = JSON.parse(JSON.stringify(this.state.data));
            let formValidations = this.state.formValidations;
            data[field] = option.key;
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `` }
            this.setState({
                data,
                formValidations
            });
        }
    }

    onMultiSelectChange(field: string, option?: IDropdownOption) {
        if (option) {
            let data: any = JSON.parse(JSON.stringify(this.state.data));
            let formValidations = this.state.formValidations;
            if (data[field] == null) {
                data[field] = [];
            }
            if (option.selected) {
                data[field].push(option.key.toString());
            } else {
                let options = data[field].filter(
                    (o: string) => o !== option.key
                );
                data[field] = options;
            }
            formValidations.fields[field] = { ...formValidations.fields[field], isValid: formValidations.fields[field].isRequired ? false : true, errorMsg: `${formValidations.fields[field].isRequired ? `${field} is required.` : ''}` }
            if (data[field].length > 0) {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
            }
            else {
                formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `Required.` }
            }
            this.setState({
                data,
                formValidations
            });
        }
    }

    onCheckBoxChange(field: string, isChecked?: boolean | undefined) {
        if (isChecked !== undefined) {
            let data: any = JSON.parse(JSON.stringify(this.state.data));
            data[field] = isChecked;
            this.setState({
                data
            });
        }
    }

    onToggleChange(field: string, isChecked?: boolean | undefined) {
        if (isChecked !== undefined) {
            let data: any = JSON.parse(JSON.stringify(this.state.data));
            data[field] = isChecked;
            this.setState({
                data
            });
        }
    }

    onDateChange(field: string, date: any) {
        if (date) {
            let data: any = JSON.parse(JSON.stringify(this.state.data));
            let formValidations = this.state.formValidations;
            data[field] = date;
            if (formValidations.fields[field].isRequired) {
                if (data[field] == '' || data[field] == null) {
                    formValidations.fields[field] = { ...formValidations.fields[field], isValid: false, errorMsg: `${field} is required.` }
                    formValidations.isFormValid = false;
                } else {
                    formValidations.fields[field] = { ...formValidations.fields[field], isValid: true, errorMsg: `` }
                }
            }
            this.setState({
                data,
                formValidations
            });
        }
    }

    private onProgress(filesInfo: any) {
        let upLoad = Common._onProgress(filesInfo, this.state.data['Files']);
        this.setState((prevState: IState) => {
            return { data: { ...prevState.data, Files: upLoad } }
        });
    }

    private _onProgress(filesInfo: any) {
        let upLoad = Common._onProgress(filesInfo, this.state.data['FilesWithExport']);
        this.setState((prevState: IState) => {
            return { data: { ...prevState.data, FilesWithExport: upLoad } }
        });
    }

    private onFilsUploadError(errorMsg: string) {
        let formValidations = this.state.formValidations;
        formValidations.fields['Files'] = { ...formValidations.fields['Files'], isValid: false, errorMsg: errorMsg }
        this.setState({
            formValidations
        });
    }

    private _onFilsUploadError(errorMsg: string) {
        let formValidations = this.state.formValidations;
        formValidations.fields['FilesWithExport'] = { ...formValidations.fields['FilesWithExport'], isValid: false, errorMsg: errorMsg }
        this.setState({
            formValidations
        });
    }

    private afterFilesUploaded(files: any) {
        let tempFiles = this.state.data['Files'];
        for (let i = 0; i < files.length; i++) {
            tempFiles = [...tempFiles, files[i]]
        }
        this.setState((prevState, prevProps) => (
            {
                data: { ...prevState.data, Files: tempFiles }
            }
        ));
    }

    private _afterFilesUploaded(files: any) {
        let tempFiles = this.state.data['FilesWithExport'];
        for (let i = 0; i < files.length; i++) {
            tempFiles = [...tempFiles, files[i]]
        }
        this.setState((prevState, prevProps) => (
            {
                data: { ...prevState.data, FilesWithExport: tempFiles }
            }
        ));
    }

    private removeFile(fileInf: any) {
        let tempFiles: any[] = [];
        this.state.data['FilesWithExport'].forEach((fileInfo: any) => {
            if (fileInfo.name !== fileInf.name) {
                tempFiles = [...tempFiles, fileInfo];
            }
        });
        this.setState((prevState, prevProps) => (
            {
                data: { ...prevState.data, FilesWithExport: tempFiles }
            }
        ));
    }

    private onOriginalOrExportedToggleChangeUpload(fileId: string, isChecked?: boolean) {
        let tempFiles = this.state.data['FilesWithExport'];
        if (isChecked !== undefined) {
            if (isChecked) {
                let files = tempFiles.filter(
                    (file: any) => {
                        if (file.response.fileNewName == fileId) {
                            file.response.filePath = file.response.filePath.replace("/exp", "");
                        }
                        return file;
                    }
                );

                this.setState((prevState, prevProps) => (
                    {
                        data: { ...prevState.data, FilesWithExport: files }
                    }
                ));
            } else {
                let files = tempFiles.filter(
                    (file: any) => {
                        if (file.response.fileNewName == fileId) {
                            file.response.filePath = file.response.filePath.includes('/exp/') ? file.response.filePath : file.response.filePath.slice(0, 8) + "/exp" + file.response.filePath.slice(8);
                        }
                        return file;
                    }
                );
                this.setState((prevState, prevProps) => (
                    {
                        data: { ...prevState.data, FilesWithExport: files }
                    }
                ));
            }
        }
    }

    private onReExportToggleChange(isChecked?: boolean) {
        if (isChecked !== undefined) {
            this.setState({
                exportTemplate: { ...this.state.exportTemplate, isReExport: isChecked }
            });
        }
    }

    private showExportModel(type: string, fileId: number, filepath: string, mimeType: string) {
        if (mimeType.includes('image/') || mimeType.includes('video/')) {
            this.setState({
                showExportModal: true,
                exportTemplate: { ...this.state.exportTemplate, path: filepath.replace("/exp", ""), fileId: fileId.toString(), mimeType: mimeType, type: type }
            });
        } else {
            this.setState({
                DialogProps: { show: true, message: "Can't export" }
            });
        }
    }

    private addFile() {
        let tempFiles = this.state.data['Urls'];
        tempFiles = [...tempFiles, new AddFile(this.state.fileIndex)];
        this.setState((prevState: IState) => {
            return {
                data: { ...prevState.data, Urls: tempFiles },
                fileIndex: prevState.fileIndex + 1
            }
        });
    }

    private dropDownChangeHandle(id: number, mimeType: string, option?: IDropdownOption) {
        let tempFiles = this.state.data['Urls'];
        let erorMessage: string;
        if (option) {
            if (option.key === "") {
                erorMessage = `${mimeType} is required`;
            } else {
                erorMessage = "";
            }
            let files = tempFiles.map((file: IAddFile) => {
                if (file.id === id) {
                    return file = { ...file, [mimeType]: option.key, [mimeType + 'Err']: erorMessage };
                } else {
                    return file
                }
            });

            this.setState((prevState: IState) => {
                return {
                    data: { ...prevState.data, Urls: files }
                }
            });
        }
    }

    private textChangeHandle(event: any, id: number) {
        let tempFiles = this.state.data['Urls'];
        let erorMessage: string;
        let inputControl = event.target;
        if (event.target.value === "") {
            erorMessage = `${inputControl.name} is required`;
        } else {
            erorMessage = "";
        }
        let files = tempFiles.map((file: IAddFile) => {
            if (file.id === id) {
                return file = { ...file, [inputControl.name]: inputControl.value, [inputControl.name + 'Err']: erorMessage };
            } else {
                return file
            }
        });

        this.setState((prevState: IState) => {
            return {
                data: { ...prevState.data, Urls: files }
            }
        });
    }

    private removeF(id: number) {
        let tempFiles = this.state.data['Urls'];
        let files = tempFiles.filter(
            (file: IAddFile) => file.id !== id
        );
        this.setState((prevState: IState) => {
            return {
                data: { ...prevState.data, Urls: files }
            }
        });
    }

    private filesUploadedBindingInfo(filesInfo: any[]) {
        let temp;
        temp = filesInfo.map((fileInfo: any, index: number) => {
            return <tr key={fileInfo.name}>
                <td>
                    {fileInfo.progress != 100 ?
                        <p className="filename sp-no-pm">File {index}</p>
                        :
                        <>{fileInfo.response &&
                            < a className="sp-ml10" href={`${fileInfo.response.filePath}`} rel="noopener noreferrer" target="_blank">click here</a>
                        }
                        </>
                    }
                </td>
                <td>
                    {fileInfo.progress != 100 ? <ProgressBar now={fileInfo.progress} label={fileInfo.progress} animated={true} /> :
                        <>{
                            fileInfo.isExported ? <PrimaryButton
                                className="sp-btn-success"
                                onClick={() => this.showExportModel('uploaded', fileInfo.response.fileNewName, fileInfo.response.filePath, fileInfo.response.mimeType)}
                                text="Re Export" /> :
                                <PrimaryButton
                                    className="sp-btn-process"
                                    onClick={() => this.showExportModel('uploaded', fileInfo.response.fileNewName, fileInfo.response.filePath, fileInfo.response.mimeType)}
                                    text="Export" />
                        }
                        </>
                    }
                </td>
                <td>
                    {fileInfo.progress != 100 &&
                        <>{
                            fileInfo.isExported && <Toggle
                                onText="Original"
                                offText="Exported"
                                onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this.onOriginalOrExportedToggleChangeUpload(fileInfo.response.fileNewName, checked)}
                            />
                        }
                        </>
                    }
                </td>
                <td>
                    <span className="btn-remove-file"
                        onClick={() => this.removeFile(fileInfo)}
                    > &times;</span>
                </td>
            </tr>
        });
        return temp;
    }

    private scrollTextHandle = (event: any): void => {
        this.setState((prevState: IState) => ({
            exportTemplate: { ...prevState.exportTemplate, scrollableText: event.target.value }
        }));
    }

    private startExport() {
        this.setState({
            showExportModal: false,
            isLoading: true
        });

        if (this.state.exportTemplate.type == 'user') {
            // this.service.exportFile(this.state.exportTemplate).then((data: any) => {
            //     if (data && data.status) {
            //         const fileId = this.state.exportTemplate.fileId;
            //         if (this.state.exportTemplate.mimeType == FileType.mp4) {
            //             const myVar = setInterval(() => {
            //                 this.service.checkIsFileExported({ path: this.state.exportTemplate.path }).then((data1: any) => {
            //                     if (data1 && data1.status) {
            //                         let files = this.state.Files.filter(
            //                             (file: any) => {
            //                                 if (file.id == fileId) {
            //                                     file.filePath = file.filePath.includes('/exp/') ? file.filePath : file.filePath.slice(0, 8) + "/exp" + file.filePath.slice(8);
            //                                     file.isExported = true;
            //                                 }
            //                                 return file;
            //                             }
            //                         );

            //                         this.setState({
            //                             Files: files,
            //                             isLoading: false,
            //                             exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
            //                         });

            //                         clearInterval(myVar);
            //                     }
            //                 }).then((err: any) => {

            //                 });
            //             }, 5000);
            //         } else {
            //             let files = this.state.Files.filter(
            //                 (file: any) => {
            //                     if (file.id == fileId) {
            //                         file.filePath = file.filePath.includes('/exp/') ? file.filePath : file.filePath.slice(0, 8) + "/exp" + file.filePath.slice(8);
            //                         file.isExported = true;
            //                     }
            //                     return file;
            //                 }
            //             );
            //             this.setState({
            //                 Files: files,
            //                 isLoading: false,
            //                 exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
            //             });
            //         }
            //     }
            // });
        } else {
            this.service.postDataOrLogout(URLs.exportFile, this.state.exportTemplate, true).then((data: any) => {
                if (data && data.status) {
                    const fileId = this.state.exportTemplate.fileId;
                    if (this.state.exportTemplate.mimeType == FileType.mp4) {
                        const myVar = setInterval(() => {
                            this.service.postDataOrLogout(URLs.checkIsFileExported, { path: this.state.exportTemplate.path }, true).then((data1: any) => {
                                if (data1 && data1.status) {
                                    let files = this.state.data['FilesWithExport'].filter(
                                        (file: any) => {
                                            if (file.response.fileNewName == fileId) {
                                                file.response.filePath = file.response.filePath.includes('/exp/') ? file.response.filePath : file.response.filePath.slice(0, 8) + "/exp" + file.response.filePath.slice(8);
                                                file.isExported = true;
                                            }
                                            return file;
                                        }
                                    );

                                    this.setState((prevState, prevProps) => (
                                        {
                                            data: { ...prevState.data, FilesWithExport: files },
                                            isLoading: false,
                                            exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
                                        }
                                    ));
                                    clearInterval(myVar);
                                }
                            }).then((err: any) => {

                            });
                        }, 5000);
                    } else {
                        let files = this.state.data['FilesWithExport'].filter(
                            (file: any) => {
                                if (file.response.fileNewName == fileId) {
                                    file.response.filePath = file.response.filePath.includes('/exp/') ? file.response.filePath : file.response.filePath.slice(0, 8) + "/exp" + file.response.filePath.slice(8);
                                    file.isExported = true;
                                }
                                return file;
                            }
                        );
                        this.setState((prevState, prevProps) => (
                            {
                                data: { ...prevState.data, FilesWithExport: files },
                                isLoading: false,
                                exportTemplate: { path: '', fileId: '', scrollableText: '', mimeType: '', isReExport: false, type: '' }
                            }
                        ));
                    }
                }
            });
        }
    }

    private hideExportModel() {
        this.setState({
            exportTemplate: { path: '', scrollableText: '', fileId: '', mimeType: '', isReExport: false, type: '' },
            showExportModal: false
        });
    }

    private _removefile(fileInf: any) {
        let tempFiles: any[] = [];
        this.state.data['Files'].forEach((fileInfo: any) => {
            if (fileInfo.name !== fileInf.name) {
                tempFiles = [...tempFiles, fileInfo];
            }
        });
        this.setState((prevState, prevProps) => (
            {
                data: { ...prevState.data, Files: tempFiles }
            }
        ));
    }

    private isFormValid() {
        let data: any = this.state.data;
        let formValidations = this.state.formValidations;
        let isFormValid = true;
        this.state.formFields.map((field: any) => {
            if (formValidations.fields[field.FieldName].type == FieldType.Text || formValidations.fields[field.FieldName].type == FieldType.Multi || formValidations.fields[field.FieldName].type == FieldType.DatePicker) {
                if (field.Required) {
                    if (data[field.FieldName] == '' || data[field.FieldName] == null) {
                        formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: false, errorMsg: `${field.FieldName} is required.` }
                        isFormValid = false;
                        formValidations.isFormValid = false;
                    } else {
                        formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: true, errorMsg: `` }
                    }
                }
            } else if (formValidations.fields[field.FieldName].type == FieldType.Email) {
                if ((data[field.FieldName] == '' || data[field.FieldName] == null) && formValidations.fields[field.FieldName].isRequired) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: false, errorMsg: `${field.FieldName} is required.` }
                    formValidations.isFormValid = false;
                    isFormValid = false;
                } else if ((data[field.FieldName] != '' && data[field.FieldName] != null) && !Util.isValidEmail(data[field.FieldName])) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: false, errorMsg: `${field.FieldName} is invalid.` }
                    formValidations.isFormValid = false;
                    isFormValid = false;
                }
                else {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: true, errorMsg: `` }
                }
            } else if (formValidations.fields[field.FieldName].type == FieldType.Number) {
                if (data[field.FieldName] === "" || data[field.FieldName] == null) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: formValidations.fields[field.FieldName].isRequired ? false : true, errorMsg: `${formValidations.fields[field.FieldName].isRequired ? `${field.Label} is required.` : ''}` }
                    if (formValidations.fields[field.FieldName].isRequired) {
                        isFormValid = false;
                    }
                } else if (data[field.FieldName].length < formValidations.fields[field.FieldName].maxLength) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: false, errorMsg: `${formValidations.fields[field.FieldName].maxLength} characters required` }
                    if (formValidations.fields[field.FieldName].isRequired) {
                        isFormValid = false;
                    }
                } else {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: false, errorMsg: `` }
                }
            } else if (formValidations.fields[field.FieldName].type == FieldType.Choice || formValidations.fields[field.FieldName].type == FieldType.Select) {
                if (data[field.FieldName] === "" || data[field.FieldName] == null) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: formValidations.fields[field.FieldName].isRequired ? false : true, errorMsg: `${formValidations.fields[field.FieldName].isRequired ? `${field.Label} is required.` : ''}` }
                    if (formValidations.fields[field.FieldName].isRequired) {
                        isFormValid = false;
                    }
                }
            } else if (formValidations.fields[field.FieldName].type == FieldType.MultiSelect) {
                if (data[field.FieldName] == null || (data[field.FieldName] && data[field.FieldName].length == 0)) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: formValidations.fields[field.FieldName].isRequired ? false : true, errorMsg: `${formValidations.fields[field.FieldName].isRequired ? `${field.Label} is required.` : ''}` }
                    if (formValidations.fields[field.FieldName].isRequired) {
                        isFormValid = false;
                    }
                }
            } else if (formValidations.fields[field.FieldName].type == FieldType.FileUpload || formValidations.fields[field.FieldName].type == FieldType.FileUploadWithExport) {
                if (data[field.FieldName] == null || (data[field.FieldName] && data[field.FieldName].length == 0)) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: formValidations.fields[field.FieldName].isRequired ? false : true, errorMsg: `${formValidations.fields[field.FieldName].isRequired ? `Atleast one file is required.` : ''}` }
                    if (formValidations.fields[field.FieldName].isRequired) {
                        isFormValid = false;
                    }
                } else if (data[field.FieldName] && data[field.FieldName].length > 0) {
                    let filesInfo = data[field.FieldName];
                    for (let i = 0; i < filesInfo.length; i++) {
                        if (!filesInfo[i].response) {
                            formValidations.fields[field.FieldName] = {
                                ...formValidations.fields[field.FieldName], isValid: false, errorMsg: 'Please wait until files are uploaded.'
                            }
                            isFormValid = false;
                        } else {
                            formValidations.fields[field.FieldName] = {
                                ...formValidations.fields[field.FieldName], isValid: true, errorMsg: ''
                            }
                        }
                    }
                }
            } else if (formValidations.fields[field.FieldName].type == FieldType.Urls) {
                if (data[field.FieldName] == null || (data[field.FieldName] && data[field.FieldName].length == 0)) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: formValidations.fields[field.FieldName].isRequired ? false : true, errorMsg: `${formValidations.fields[field.FieldName].isRequired ? `Atleast one url info is required.` : ''}` }
                    if (formValidations.fields[field.FieldName].isRequired) {
                        isFormValid = false;
                    }
                } else if (data[field.FieldName] && data[field.FieldName].length > 0) {
                    let filesInfo = data[field.FieldName];
                    let isValid = true;
                    for (let i = 0; i < filesInfo.length; i++) {
                        if (filesInfo[i].fileNewName === "") {
                            isFormValid = false;
                            isValid = false;
                            filesInfo[i].fileNewNameErr = "File name is required."
                        }
                        if (filesInfo[i].filePath === "") {
                            isFormValid = false;
                            isValid = false;
                            filesInfo[i].filePathErr = "Url is required."
                        }
                        if (filesInfo[i].mimeType === "") {
                            isFormValid = false;
                            isValid = false;
                            filesInfo[i].mimeTypeErr = "File type is required."
                        }
                    }
                    formValidations.fields[field.FieldName] = {
                        ...formValidations.fields[field.FieldName], isValid: false, errorMsg: `${isValid ? '' : 'Please fill all the fields.'}`
                    }
                    data[field.FieldName] = filesInfo;
                }
            } else if (formValidations.fields[field.FieldName].type == FieldType.ReactQuill) {
                if ((data[field.FieldName] == '<p><br></p>' || data[field.FieldName] == null) && formValidations.fields[field.FieldName].isRequired) {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: false, errorMsg: `${field.FieldName} is required.` }
                    formValidations.isFormValid = false;
                    isFormValid = false;
                } else {
                    formValidations.fields[field.FieldName] = { ...formValidations.fields[field.FieldName], isValid: true, errorMsg: `` }
                }
            }
        });

        this.setState({
            formValidations,
            data
        });
        return isFormValid;
    }

    private onSubmit() {
        if (this.isFormValid()) {
            let data = _.cloneDeep(this.state.data);
            if (data['Files'] && data['Files'].length > 0) {
                let filesInfo: IFileT[] = [];
                for (let i = 0; i < data['Files'].length; i++) {
                    if (data['Files'][i].response) {
                        filesInfo = [...filesInfo, new FileT(data['Files'][i].response)]
                    }
                }
                data['Files'] = filesInfo;
            }
            if (data['FilesWithExport'] && data['FilesWithExport'].length > 0) {
                let filesInfo: IFileT[] = [];
                for (let i = 0; i < data['FilesWithExport'].length; i++) {
                    if (data['FilesWithExport'][i].response) {
                        filesInfo = [...filesInfo, new FileT(data['FilesWithExport'][i].response)]
                    }
                }
                data['FilesWithExport'] = filesInfo;
            }
            if (data['Urls'] && data['Urls'].length > 0) {
                let filesInfo: IFileT[] = [];
                for (let i = 0; i < data['Urls'].length; i++) {
                    filesInfo = [...filesInfo, new FileT(data['Urls'][i])]
                }
                data['Urls'] = filesInfo;
            }
            this.props.onSubmit(data);
        }
    }

    public render(): JSX.Element {
        return (<>
            {this.state.isLoading && <Loading />}
            {this.state.formFields && this.state.data && this.state.formFields.map((field: any) => {
                return <FormField
                    field={field}
                    data={this.state.data}
                    formValidations={this.state.formValidations}
                    onFieldChange={this.onFieldChange}
                    onEmailChange={this.onEmailChange}
                    onNumberChange={this.onNumberChange}
                    onChoiceChange={this.onChoiceChange}
                    onSelectChange={this.onSelectChange}
                    onMultiSelectChange={this.onMultiSelectChange}
                    onCheckBoxChange={this.onCheckBoxChange}
                    onToggleChange={this.onToggleChange}
                    onDateChange={this.onDateChange}
                />
            })
            }
            {this.state.formFields && this.state.data && <>
                <div className="file-upload-wrapper">
                    {this.state.data['Files'] && this.state.data['Files'].length > 0 &&
                        <FileUploadBindingInfo filesInfo={this.state.data['Files']} removefile={this._removefile} />
                    }
                    {
                        this.state.formValidations.fields['Files'] &&
                        <p className="sp-danger">{this.state.formValidations.fields['Files'].errorMsg}</p>
                    }
                </div>
                <div className="file-upload-wrapper">
                    {this.state.data['FilesWithExport'] && this.state.data['FilesWithExport'].length > 0 &&
                        <>
                            <div className="sp-mt30">
                                <p className="ms-fontSize-14 heading-3">Uploaded files</p>
                                <table className="sp-table-u">
                                    <thead>
                                        <tr>
                                            <th>File</th>
                                            <th>Export/Re Export</th>
                                            <th>Original/Exported</th>
                                            <th>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.filesUploadedBindingInfo(this.state.data['FilesWithExport'])}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    }
                    {
                        this.state.formValidations.fields['FilesWithExport'] &&
                        <p className="sp-danger">{this.state.formValidations.fields['FilesWithExport'].errorMsg}</p>
                    }
                </div>
                {
                    this.state.data['Urls'] && this.state.data['Urls'].length > 0 && <>
                        <table className="sp-table-u">
                            <thead>
                                <tr>
                                    <th>File Name</th>
                                    <th>File URL</th>
                                    <th>File Type</th>
                                    <th>Remove</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.data['Urls'].map((file: IAddFile) => {
                                        return <tr key={file.id}>
                                            <td>
                                                <TextField
                                                    placeholder="Enter file name"
                                                    name="fileNewName"
                                                    errorMessage={file.fileNewNameErr}
                                                    value={file.fileNewName}
                                                    onChange={(event: any) => this.textChangeHandle(event, file.id)}
                                                />
                                            </td>
                                            <td>  <TextField
                                                placeholder="Enter file Url"
                                                name="filePath"
                                                errorMessage={file.filePathErr}
                                                value={file.filePath}
                                                onChange={(event: any) => this.textChangeHandle(event, file.id)}
                                            />
                                            </td>
                                            <td>
                                                <Dropdown
                                                    className="sp-dropdown"
                                                    placeholder="Select file type"
                                                    options={FileTypes2}
                                                    errorMessage={file.mimeTypeErr}
                                                    onChange={(event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption, index?: number) => this.dropDownChangeHandle(file.id, "mimeType", option)}
                                                />
                                            </td>
                                            <td>
                                                <p className="btn-remove-file" onClick={() => this.removeF(file.id)}>&times;</p>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                    </>
                }
                {
                    this.state.formValidations.fields['Urls'] &&
                    <p className="sp-danger">{this.state.formValidations.fields['Urls'].errorMsg}</p>
                }
            </>
            }
            <div className="sp-float-left sp-mt30">
                <PrimaryButton className="sp-main-btn sp-mr10" onClick={this.onSubmit} text="Submit" />
                {this.state.formFields && this.state.data && this.state.data['Files'] !== undefined &&
                    <FileUpload multiple={true}
                        id={this.state.formValidations.fields['Files'].id.toString()}
                        onError={this.onFilsUploadError}
                        onProgress={this.onProgress}
                        Reset={this.state.formValidations.fields['Files'].reset}
                        afterFilesUploaded={this.afterFilesUploaded}></FileUpload>
                }
                {this.state.formFields && this.state.data && this.state.data['FilesWithExport'] !== undefined &&
                    <FileUpload multiple={true}
                        id={this.state.formValidations.fields['FilesWithExport'].id.toString()}
                        onError={this._onFilsUploadError}
                        onProgress={this._onProgress}
                        Reset={this.state.formValidations.fields['FilesWithExport'].reset}
                        afterFilesUploaded={this._afterFilesUploaded}></FileUpload>
                }
                {this.state.formFields && this.state.data && this.state.data['Urls'] !== undefined &&
                    <span className="add-icon sp-ml10 sp-mt10" title="Add row"
                        onClick={this.addFile}
                    ><i className="ms-Icon ms-Icon--CirclePlus" aria-hidden="true"></i></span>
                }
            </div>
            <Dialog
                hidden={!this.state.showExportModal}
                onDismiss={this.hideExportModel}
                dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Export Template',
                }}
                modalProps={{
                    isBlocking: true,
                    containerClassName: "export-template"

                }}
            >
                <div className="">
                    <TextField label="Scrollable Text" multiline={true} rows={5} placeholder="Enter Text only for videos" name="scrollableText" value={this.state.exportTemplate.scrollableText} onChange={this.scrollTextHandle} />
                    <Toggle label="Re Export" checked={this.state.exportTemplate.isReExport} onText="Yes" offText="No" onChange={(event: React.MouseEvent<HTMLElement>, checked?: boolean) => this.onReExportToggleChange(checked)} />
                </div>
                <DialogFooter>
                    <PrimaryButton onClick={this.startExport} text="Export" />
                    <DefaultButton onClick={this.hideExportModel} text="Cancel" />
                </DialogFooter>
            </Dialog>
        </>);
    }
}

export default FormSP;