import { IServices } from "./IService";
import Service from "../../services/services";
import { URLs } from "../../constants/constants";
import AuthService from "../../services/authService/index";
import Axios from "axios";
import axios from "axios";

export default class NewsService implements IServices {
  private service: Service;
  private authService: AuthService;
  constructor() {
    this.service = new Service();
    this.authService = new AuthService();
  }

  private dataOrLogout(res: any): any {
    if (res.status === 200) {
      return res.data;
    } else if (res.status === false && res.statuscode === 403) {
      this.authService.LogOut();
    } else if (res.status === false && res.statuscode === 401) {
      this.authService.LogOut();
    } else if (res.status === false) {
      return res;
    }
  }

  private dataOrError(res: any): any {
    if (res.status === 200) {
      return res.data;
    } else if (res.status === false) {
      return res;
    }
  }

  public getDataOrLogout(api: string, authRequired: boolean): Promise<any> {
    let url = `/adm${api}`;
    return this.service.get(url, authRequired).then((res: any) => {
      return this.dataOrLogout(res);
    });
  }

  public getDataOrError(api: string, authRequired: boolean): Promise<any> {
    let url = `/adm${api}`;
    return this.service.get(url, authRequired).then((res: any) => {
      return this.dataOrError(res);
    });
  }

  public deleteDataOrError(api: string, authRequired: boolean): Promise<any> {
    let url = `/adm${api}`;
    return this.service.delete(url, authRequired).then((res: any) => {
      return this.dataOrError(res);
    });
  }

  public postDataOrLogout(
    api: string,
    data: any,
    authRequired: boolean
  ): Promise<any> {
    let url = `/adm${api}`;
    return this.service.post(url, data, authRequired).then((res: any) => {
      return this.dataOrLogout(res);
    });
  }

  public postDataOrError(
    api: string,
    data: any,
    authRequired: boolean
  ): Promise<any> {
    let url = `/adm${api}`;
    return this.service.post(url, data, authRequired).then((res: any) => {
      return this.dataOrError(res);
    });
  }

  public downloadFile(data: any): any {
    let url = `${data.path}`;
    Axios({
      url: url,
      method: "GET",
      responseType: "blob",
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.pdf");
      document.body.appendChild(link);
      link.click();
    });
  }
}
