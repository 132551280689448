import * as React from "react";
import './styles.scss';
import { connect } from "react-redux";
import { IUserState } from "../../Redux/models";
import { AppState } from "../../Redux/app.store";
import ShowMoreSP from "./ShowMoreSP";
const TextTruncate = require('react-text-truncate');

interface IProps {
  Title: string;
  Description: string;
  User: IUserState;
  Source?: string;
}

function NewsTD(props: IProps) {
  return (<div className="news-td">
    <TextTruncate
      line={2}
      element="h6"
      truncateText="…"
      text={props.Title}
    />
    <ShowMoreSP text={props.Description}
      more={props.User.staticConstants.Constants.readMore}
      less={props.User.staticConstants.Constants.readLess}
    />
    {props.Source && <p className="original-source"><a target="_blank" href={props.Source}>Original Source</a></p>}
  </div>
  );
}

const mapStateToProps = (state: AppState): AppState => ({
  User: state.User,
});

export default connect(
  mapStateToProps,
)(NewsTD);