import * as React from "react";
import AuthService from "../../../../services/authService";
import { IDialogPropss, LiveStatus } from "../../../../models/models";
import { URLs, AppConstants } from "../../../../constants/constants";
import Loading from "../../../common/Loading";
import { Dialog, DialogType, DefaultButton, DialogFooter, PrimaryButton } from "office-ui-fabric-react";
import NewsService from "../../service";
import LiveStreamer from "../../../user/live/LiveStreamer";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import FormSP from "../../../common/FormSP";

var formFields: any = require('./LivePushNotification.json');

interface IState {
    isLoading: boolean;
    reset: boolean;
    DialogProps: IDialogPropss;
}

interface IProps {
}

class LivePushNotification extends React.Component<IProps, IState> {
    private service: NewsService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            isLoading: false,
            reset: false,
            DialogProps: { show: false, message: '' },
        }

        this.onSubmit = this.onSubmit.bind(this);
        this.closeAlertDialog = this.closeAlertDialog.bind(this);
        this.service = new NewsService();
    }

    private closeAlertDialog() {
        this.setState({
            DialogProps: { show: false, message: '' },
            reset: false
        });
    }

    private onSubmit(data: any) {
        let formData: any = {};
        formData.title = data.title;
        formData.youtubeURL = data.youtubeURL;
        formData.sendPushNot = data.sendPushNot;
        formData.thumbnail = [];

        if (data.FilesWithExport && data.FilesWithExport.length > 0) {
            data.FilesWithExport.forEach((a: any) => {
                formData.thumbnail.push(a);
            });
        }

        this.setState({ isLoading: true });
        this.service.postDataOrLogout(URLs.addYoutubeLive, formData, true).then((res: any) => {
            if (res.status === true) {
                this.setState({
                    isLoading: false,
                    reset: true,
                    DialogProps: { show: true, message: res.message },
                });
            } else {
                this.setState({
                    isLoading: false,
                    DialogProps: { show: true, message: res.message },
                });
            }
        });
    }

    render(): JSX.Element {
        return (<>
            {this.state.isLoading && <Loading />}
            <div className="ms-Grid-row" >
                <div className="ms-Grid-col ms-sm6 ms-md6 ms-lg6" >
                    <div>
                        {!this.state.reset &&
                            <FormSP
                                reset={this.state.reset}
                                formFields={formFields}
                                onSubmit={this.onSubmit}
                            />
                        }
                    </div>
                </div>
            </div>

            <Dialog
                hidden={!this.state.DialogProps.show}
                onDismiss={this.closeAlertDialog}
                dialogContentProps={{
                    type: DialogType.normal,
                }}
                modalProps={{
                    styles: { main: { maxWidth: 450, textAlign: "center" } },
                    isBlocking: true
                }}
            >
                <p>{this.state.DialogProps.message}</p>
                <DefaultButton className="sp-btn-login" onClick={this.closeAlertDialog} text="Ok" />
            </Dialog>
        </>
        );
    }
}

export default LivePushNotification;