import React, { Component } from 'react';
import { baseUrl } from '../../../services/services';

var adsbygoogle: any;

interface IProps {
    cid: string;

}

interface IState {

}

class NewsFeedAd extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    render() {
        return (<>
            <div id={this.props.cid}>
            </div>
            <div className="main-contai c-style1">
                {/* <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-format="fluid"
                    data-ad-layout-key="-6t+ed+2i-1n-4w"
                    data-ad-client="ca-pub-2688053927930971"
                    data-ad-slot="5685541604"></ins> */}
                <ins className="adsbygoogle"
                    style={{ display: "block" }}
                    data-ad-client="ca-pub-2688053927930971"
                    data-ad-slot="5475812130"
                    data-ad-format="auto"
                    data-full-width-responsive="true"></ins>
                <img src={`${baseUrl}/uploads/static_files/adsstrip.PNG`} style={{ width: '100%' }} />
            </div>
        </>
        );
    }

    componentDidMount() {
        var objProps = this.props;
        try {
            var loadScript = function () {
                var tag: any = document.createElement('script');
                tag.async = false;
                tag.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
                tag['data-ad-client'] = 'ca-pub-2688053927930971';
                var body = document.getElementById(objProps.cid);
                if (body) {
                    body.appendChild(tag);
                }
            }

            loadScript();
            (adsbygoogle = (window as any).adsbygoogle || []).push({});
        } catch (e) {
            console.log(e);
        }
    }

}

export default NewsFeedAd;