import * as React from "react";
import { baseUrl } from "../../../services/services";
import './styles.scss';


interface IProps {
  url: string;
}

function ThumbNail(props: IProps) {
  return (
    <img className="card-img-top sp-img" src={`${baseUrl}${props.url}`} alt="Card cap" />
  );
}
export default ThumbNail;