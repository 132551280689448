import * as React from "react";
import './styles.scss';
import { IFileC } from '../../../models/models';
import Util from "../../../util";
const YTPlayer = require('yt-player');

interface IProps {
  File: IFileC;
}

interface IState {
  playerRef: any;
  currentId: string;
  File: IFileC;
}

class Youtube extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      playerRef: null,
      currentId: '',
      File: this.props.File
    }
  }

  // componentDidMount() {
  //   try {


  //     let thisObj = this;
  //     let link = this.state.File.filePath;
  //     let ddd = Util.extractYoutubeVideoID(this.state.File.filePath);
  //     const player = new YTPlayer(`#${this.state.File._id.substring(1, 10)}`, { width: '100%', height: '280', related: false });
  //     player.load(ddd);
  //     window.addEventListener('scroll', function () {
  //       var element = document.querySelector(`#${thisObj.props.File._id.substring(1, 10)}`);
  //       if (element) {
  //         var position = element.getBoundingClientRect();
  //         if (position.top >= 0 && position.bottom <= window.innerHeight) {
  //         }
  //         if (position.top < window.innerHeight && position.bottom >= 0) {
  //           if (player.getState() == 'playing') {
  //             player.pause();
  //           }
  //         }
  //       }
  //     });

  //     this.setState({
  //       playerRef: player,
  //       currentId: this.state.File._id.substring(1, 10)
  //     });
  //   } catch (e) {
  //     console.log(e);
  //   }

  // }

  // componentWillReceiveProps(newProps: IProps) {
  //   if (newProps.File) {
  //     if (this.state.File._id !== newProps.File._id) {
  //       this.setState({
  //         File: newProps.File
  //       });
  //     }
  //   }
  // }
  // componentWillUnmount() {
  //   if (this.state.playerRef) {
  //     this.state.playerRef.destroy();
  //   }
  // }

  render(): JSX.Element {
    return (
      <>
        {console.log(this.state)}
        {(this.state.File !== null && this.state.File !== undefined) &&
          // <div id={this.state.File._id.substring(1, 10)}></div>}
          <iframe width="100%" height="100%"
            src={`https://www.youtube.com/embed/${Util.extractYoutubeVideoID(this.state.File.filePath)}`}>
          </iframe>
        }
      </>
    );
  }
}

export default Youtube;