import * as React from "react";
import './../styles.scss';
import Navbar from "../../Navbar";
import { PivotItem, Pivot } from "office-ui-fabric-react";
import Telugu from "./Telugu";
import English from "./English";

class AboutUs extends React.Component {
    public render(): JSX.Element {
        return (
            <><Navbar />
                <div className="sp-container sp-mt50 sp-p10" >
                    <div className="about-us">
                        <h5 className="title">Who we are</h5>
                        <hr />
                        <Pivot aria-label="Basic Pivot Example" >
                            <PivotItem
                                headerText="తెలుగు"
                                headerButtonProps={{
                                    'data-order': 1
                                }}
                            >
                                <Telugu />
                            </PivotItem>
                            <PivotItem
                                headerText="English"
                                headerButtonProps={{
                                    'data-order': 2
                                }}
                            >
                                <English />
                            </PivotItem>
                        </Pivot>
                    </div>
                </div>
            </>
        );
    }
}

export default AboutUs;