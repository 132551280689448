import * as React from "react";
import { NewsStatus } from '../../../models/models';
import Util from '../../../util';
import './styles.scss';
import { connect } from "react-redux";
import { AppState } from "../../../Redux/app.store";
import { IUserState } from "../../../Redux/models";
import FilesDisplayList1 from "../../admin/utils/FileDisplayList1";
import ShowMoreSP from "../../common/ShowMoreSP";

interface INewsInfo {
    _id: string;
    Title: string
    Description: string;
    WhatToDo: string;
    DateTime: string;
    Status: string;
    StatusMessage: string;
    Files: []
}
interface IState {
    Item: INewsInfo;
}

interface IProps {
    Item: INewsInfo;
    User: IUserState;
}

class HelpRequestDetails extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            Item: this.props.Item
        }
    }
    componentWillReceiveProps(newProps: IProps) {
        if (this.state.Item._id !== newProps.Item._id) {
            this.setState({
                Item: newProps.Item
            });
        }
    }

    render(): JSX.Element {
        return (
            <div>
                {(this.state.Item &&
                    <div className="selected-item">
                        <h4>News Information </h4>
                        <hr />
                        <div className="ms-Grid" >
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md7 ms-lg7 msg-details">
                                    <h6 className="msg-headings">Title <span className="sp-float-right sp-pointer">
                                        {Util.formatAMPM(this.state.Item.DateTime)}
                                    </span></h6>
                                    <div className="sp-clearFix"></div>
                                    <ShowMoreSP
                                        text={this.state.Item.Title}
                                        more={this.props.User.staticConstants.Constants.readMore}
                                        less={this.props.User.staticConstants.Constants.readLess}
                                    />

                                    <h6 className="msg-headings sp-mt10">Description</h6>
                                    <ShowMoreSP
                                        text={this.state.Item.Description}
                                        more={this.props.User.staticConstants.Constants.readMore}
                                        less={this.props.User.staticConstants.Constants.readLess}
                                    />
                                    <h6 className="msg-headings sp-mt10">What To Do</h6>
                                    <ShowMoreSP
                                        text={this.state.Item.WhatToDo}
                                        more={this.props.User.staticConstants.Constants.readMore}
                                        less={this.props.User.staticConstants.Constants.readLess}
                                    />
                                    <FilesDisplayList1 files={this.state.Item.Files}></FilesDisplayList1>
                                </div>
                                <div className="ms-Grid-col ms-sm12 ms-md5 ms-lg5">
                                    <h6 className="msg-headings sp-mt10">Status</h6>
                                    {this.state.Item.Status === NewsStatus.Approved &&
                                        <span className="sp-red sp-br5 sp-white sp-p5">Approved</span>
                                    }
                                    {(this.state.Item.Status === NewsStatus.Process || this.state.Item.Status === NewsStatus.Submitted) &&
                                        <span className="sp-yellow sp-br5 sp-white sp-p5">Not approved</span>
                                    }
                                    {this.state.Item.Status === NewsStatus.Rejected &&
                                        <span className="sp-red sp-br5 sp-white sp-p5">Not approved</span>
                                    }
                                    {this.state.Item.StatusMessage && <div>
                                        <h6 className="msg-headings sp-mt10">Comment</h6>
                                        <p> {this.state.Item.StatusMessage}</p>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: AppState): AppState => ({
    User: state.User,
});

export default connect(
    mapStateToProps,
)(HelpRequestDetails);