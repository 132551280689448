import * as React from "react";
import MaterialTable from 'material-table';
import {
    ISavedNews,
    NewsStatus, IHelpRequestCountByStatus, BasicUserInfo, IPageInfo
} from '../../../models/models';
import NewsService from '../service';
import FilesDisplayList from '../utils/FilesDisplayList';
import ApproveForm from './ApproveForm';
import { DefaultButton } from 'office-ui-fabric-react/lib/Button';
import Profile from '../utils/Profile';
import Util from '../../../util';
import Loading from "../../common/Loading";
import AuthService from "../../../services/authService";
import { URLs } from "../../../constants/constants";
import ShowMoreSP from "../../common/ShowMoreSP";

interface IFilter {
    UserId: string;
    SelfAdminPosted: boolean;
}

interface IState {
    allNews: ISavedNews[];
    allNewsTemp: ISavedNews[];
    NewsInfo: any;
    isLoading: boolean;
    selectedItem: any;
    changeStatus: boolean;
    status: string;
    isShowCompleteInfo: boolean;
    currentFilter: string;
    showSelfAdminPosted: boolean;
    skipIndex: number;
    filter: IFilter;
    pageInfo: IPageInfo;
    newsCountInfo: IHelpRequestCountByStatus;
    currentRecords: number;
}

interface IProps {

}

class AllUserNews1 extends React.PureComponent<IProps, IState> {
    private newsService: NewsService;
    private authservice: AuthService;
    constructor(props: IProps) {
        super(props);
        this.state = {
            allNews: [],
            allNewsTemp: [],
            isLoading: false,
            selectedItem: null,
            changeStatus: false,
            status: '',
            isShowCompleteInfo: true,
            NewsInfo: null,
            currentFilter: 'Submitted',
            showSelfAdminPosted: false,
            filter: { UserId: '', SelfAdminPosted: false },
            pageInfo: { currentPage: 0, totalPages: 0 },
            skipIndex: 0,
            currentRecords: 0,
            newsCountInfo: { Rejected: 0, Approved: 0, Process: 0, Submitted: 0, All: 0 },

        }
        this.authservice = new AuthService();
        this.newsService = new NewsService();
        this._onItemSelected = this._onItemSelected.bind(this);
        this._closeDialog = this._closeDialog.bind(this);
        this._afterStatusUpdated = this._afterStatusUpdated.bind(this);
        this._reloadData = this._reloadData.bind(this);
        this._fetchNextOrPrevisousRecords = this._fetchNextOrPrevisousRecords.bind(this);
        this._updateNews = this._updateNews.bind(this);
    }

    componentDidMount() {
        this.getAllData();
    }

    private getAllData(): Promise<any> {
        var promise: Promise<any>[] = [];
        this.setState({
            isLoading: true,
            selectedItem: null,
            changeStatus: false
        });
        promise.push(
            this.newsService.getDataOrLogout(URLs.getNewsCountByStatus, true).then((res: any) => {
                if (res.status) {
                    let newsCountInfo: any = {};
                    res.data.forEach((c: any) => {
                        newsCountInfo[c._id] = c.count;
                    });
                    if (newsCountInfo.Process === undefined) {
                        newsCountInfo.Process = 0;
                    }
                    if (newsCountInfo.Submitted === undefined) {
                        newsCountInfo.Submitted = 0;
                    }
                    if (newsCountInfo.Approved === undefined) {
                        newsCountInfo.Approved = 0;
                    }
                    if (newsCountInfo.Rejected === undefined) {
                        newsCountInfo.Rejected = 0;
                    }
                    newsCountInfo['All'] = newsCountInfo.Approved + newsCountInfo.Rejected + newsCountInfo.Submitted + newsCountInfo.Process;
                    this.setState({
                        newsCountInfo: newsCountInfo,
                    });
                } else {
                    this.setState({ isLoading: false });
                }
            }));
        promise.push(this.newsService.postDataOrLogout(URLs.getAllUserNewsForAdmin, { skip: 0, field: 'Status', value: NewsStatus.Submitted, field1: 'IsLocked', value1: true }, true).then((res: any) => {
            if (res.status) {
                let data: ISavedNews[] = res.data;
                this.setState({
                    allNews: data,
                    skipIndex: data.length,
                    currentRecords: data.length,
                    pageInfo: Util.getPageInfo(this.state.currentFilter, this.state.newsCountInfo.All, data.length),
                });
            } else {
                this.setState({
                    isLoading: false
                });
            }
        }));
        return Promise.all(promise).then(() => {
            this.setState({
                isLoading: false
            });
            return;
        });
    }

    private _onItemSelected(event: any, data: any) {
        this.setState({
            changeStatus: false,
            selectedItem: data

        });
    }
    private _fetchNextOrPrevisousRecords(where: string) {
        this.setState({
            isLoading: true
        });
        if (where === 'previous') {
            let filter: any = {};
            filter.skip = this.state.skipIndex - 500 - this.state.currentRecords;
            filter.field = "Status";
            if (this.state.currentFilter === NewsStatus.Submitted) {
                filter.value = NewsStatus.Submitted;
            } else if (this.state.currentFilter === NewsStatus.Process) {
                filter.value = NewsStatus.Process;
            } else if (this.state.currentFilter === NewsStatus.Approved) {
                filter.value = NewsStatus.Approved;
            } else if (this.state.currentFilter === NewsStatus.Rejected) {
                filter.value = NewsStatus.Rejected;
            }

            this.newsService.postDataOrLogout(URLs.getAllUserNewsForAdmin, filter, true).then((res: any) => {
                if (res.status) {
                    let data: ISavedNews[] = res.data;
                    this.setState({
                        allNews: data,
                        isLoading: false,
                        skipIndex: this.state.skipIndex - 500 - this.state.currentRecords + data.length,
                        pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, this.state.currentFilter, this.state.newsCountInfo.All, this.state.skipIndex - 500),
                        currentRecords: data.length
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });

        } else {
            let filter: any = {};
            filter.skip = this.state.skipIndex;
            filter.field = "Status";
            if (this.state.currentFilter === NewsStatus.Submitted) {
                filter.value = NewsStatus.Submitted;
            } else if (this.state.currentFilter === NewsStatus.Process) {
                filter.value = NewsStatus.Process;
            } else if (this.state.currentFilter === NewsStatus.Approved) {
                filter.value = NewsStatus.Approved;
            } else if (this.state.currentFilter === NewsStatus.Rejected) {
                filter.value = NewsStatus.Rejected;
            }

            this.newsService.postDataOrLogout(URLs.getAllUserNewsForAdmin, filter, true).then((res: any) => {
                if (res.status) {
                    let data: ISavedNews[] = res.data;
                    this.setState({
                        allNews: data,
                        isLoading: false,
                        skipIndex: this.state.skipIndex + data.length,
                        currentRecords: data.length,
                        pageInfo: Util.getPageInfo2(this.state.pageInfo.totalPages, this.state.currentFilter, this.state.newsCountInfo.All, this.state.skipIndex + data.length)
                    });
                } else {
                    this.setState({
                        isLoading: false
                    });
                }
            });
        }
    }

    public _updateNews(status: string) {
        this.setState((prevState: IState) => ({
            changeStatus: true,
            status: status,
        }));

        if (status === NewsStatus.Approved) {
            this.newsService.postDataOrLogout(URLs.lockOrUnlock, {
                id: this.state.selectedItem._id,
                updateObj: { IsLocked: true, LockedBy: new BasicUserInfo(this.authservice.isLoggedIn()) }
            }, true).then((data: any) => {

            });
        }
    }

    public _closeDialog() {
        this.setState({
            changeStatus: false,
            status: ''
        });
    }

    public _afterStatusUpdated(id: string, status?: string) {
        this.getAllData();
    }

    private _reloadData() {
        this.getAllData();
    }

    render(): JSX.Element {
        const { allNews, isLoading } = this.state;
        return (
            <>
                {isLoading && <Loading />}
                {this.state.newsCountInfo &&
                    <div className="news-dashbord">
                        <span className="Title"> News Information</span>
                        <DefaultButton className="sp-btn-m sp-btn-black sp-float-right" onClick={this._reloadData} text="Reload" />
                        <div className="sp-clearFix">
                        </div>
                        <hr className="sp-hr" />
                        <div className="ms-Grid upload" dir="ltr">
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col sp-sm20 ">
                                    <div className="item-c">
                                        <p className="item-title">Pending</p>
                                        <i className="ms-Icon ms-Icon--StatusCircleQuestionMark sp-icon1 sp-yellow sp-float-left" aria-hidden="true"></i>
                                        <p className="item-count">
                                            {this.state.newsCountInfo.Submitted}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr className="sp-hr sp-mt30" />

                        {this.state.isShowCompleteInfo && <>
                            <div className="material-table">
                                <div className="pagination">
                                    <span className={`nav-btn ${this.state.pageInfo.currentPage === 1 ? 'sp-hide' : ''}`} onClick={() => this._fetchNextOrPrevisousRecords('previous')} title="Previous Page" >&laquo;</span>
                                    <span title="Current Page"  >{`Page ${this.state.pageInfo.currentPage} of ${this.state.pageInfo.totalPages}`} </span>
                                    <span className={`nav-btn ${this.state.pageInfo.currentPage === this.state.pageInfo.totalPages ? 'sp-hide' : ''}`} onClick={() => this._fetchNextOrPrevisousRecords('next')} title="Next Page" >&raquo;</span>
                                </div>
                                <div className="sp-clearFix"></div>
                                <MaterialTable
                                    style={{ marginTop: "5px", padding: "5px", boxShadow: "none" }}
                                    title="New posts"
                                    columns={[
                                        { title: 'Title', field: 'Title', tooltip: 'Title' },
                                        { title: 'Status', field: 'Status', tooltip: 'Status' },
                                        { title: 'Date', field: 'DateTime' }
                                    ]}
                                    data={allNews}
                                    actions={[
                                        {
                                            icon: 'edit',
                                            tooltip: 'Select news',
                                            onClick: this._onItemSelected
                                        }
                                    ]}
                                    options={{
                                        rowStyle: rowData => ({
                                            backgroundColor: (this.state.selectedItem && this.state.selectedItem._id === rowData._id) ? '#EEE' : '#FFF'
                                        })
                                    }}
                                ></MaterialTable>
                                <div>
                                    {(this.state.selectedItem &&
                                        <div className="selected-item">
                                            <h4>News Information </h4>
                                            <hr />
                                            <div className="ms-Grid" >
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm8 ms-md8 ms-lg8 msg-details">
                                                        <h6 className="msg-headings">Title <span className="sp-float-right sp-pointer">
                                                            {Util.formatAMPM(this.state.selectedItem.DateTime)}
                                                        </span></h6>
                                                        <div className="sp-clearFix"></div>
                                                        <ShowMoreSP
                                                            text={this.state.selectedItem.Title}
                                                        />
                                                        <h6 className="msg-headings sp-mt10">Description</h6>
                                                        <ShowMoreSP
                                                            text={this.state.selectedItem.Description}
                                                        />
                                                        <FilesDisplayList files={this.state.selectedItem.Files}></FilesDisplayList>
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm4 ms-md4 ms-lg4">
                                                        <h6>Submitted by </h6>
                                                        {this.state.selectedItem.User ?
                                                            <Profile userId={this.state.selectedItem.User._id} /> : <p>Unknown</p>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="action-buttons">
                                                {this.state.selectedItem.Status !== "Approved" && <DefaultButton className="sp-btn-m sp-btn-success" onClick={() => this._updateNews(NewsStatus.Approved)} text="Approve" />}
                                                {this.state.selectedItem.Status !== "Approved" && this.state.selectedItem.Status !== "Process" && <DefaultButton className="sp-btn-m sp-btn-process sp-ml10" onClick={() => this._updateNews(NewsStatus.Process)} text="Process" />}
                                                {this.state.selectedItem.Status !== "Approved" && this.state.selectedItem.Status !== "Rejected" && <DefaultButton className="sp-btn-m sp-btn-danger sp-ml10" onClick={() => this._updateNews(NewsStatus.Rejected)} text="Reject" />}
                                            </div>
                                        </div>
                                    )}
                                    <p></p>
                                </div>
                                {(this.state.changeStatus && <ApproveForm _closeDialog={this._closeDialog} newsInfo={this.state.selectedItem} status={this.state.status} afterStatusUpdated={this._afterStatusUpdated}></ApproveForm>)}
                            </div>
                        </>
                        }
                    </div>
                }
            </>
        );
    }
}

export default AllUserNews1;